.bannerSection{
    width: 100%;
    height: 480px;
    position: relative;
    display: inline-block;
    @include md{
        height: 265px;
    }
    @include xs {
        height: 250px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .bannerInner{
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0;
        bottom: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card{
        background-color: #fff;
        padding: 45px 55px; 
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.09);
        border-radius: 25px;
        min-width: 600px;
        margin: 0px;
        @include md{
            padding: 20px; 
            width: 85%;
            min-width: auto;
        }
        @include xs {
            padding: 15px 10px; 
            width: 95%;
        }
        .mainHeading{  
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: var(--greenshade);
            margin: 0px 0px 5px 0px;
            padding-left: 10px;
            @include md{
                font-size: 16px;
                line-height: 24px;
                margin:  0px;
                padding-left: 5px;
            }
        }
        .smallHeading{
            font-weight: 500; 
            font-size: 18px;
            line-height: 27px;
            color: var(--blacktone);
            margin: 0px;
            padding-left: 10px;
            @include md{ 
                font-size: 11px;
                line-height: 16px; 
                padding-left: 5px;
            } 
        }
        .fillterLabel{
            padding-left: 10px;
            @include md{  
                padding-left: 5px;
            } 
        }
        .formInput{
            margin: 30px 0px 15px 0px;
            @include md{
                margin:15px 0px 0px 0px;
            }
            @include xs{ 
                margin:5px 0px 0px 0px;
            }
            .themeLink {
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;
                @include md{
                    font-size: 9px;
                    line-height: 14px;
                }
            }
            .item-content{
                margin-bottom: 8px; 
                @include md{
                    margin-bottom: 10px; 
                }
                .item-label{
                    font-size: 18px;
                    line-height: 27px; 
                    font-weight: 600;
                    color: #5E5E5E;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    display: block;
                    width: 100%;
                    @include md{
                        font-size: 14px;
                        line-height: 21px;
                        padding-left: 5px;
                        margin-bottom: 5px; 
                    }
                }
                .item-input-wrap {
                    position: relative;
                    input {
                        background: #fff;
                        border-radius: 50px;
                        padding: 5px 8rem 5px 20px;
                        height: 55px;
                        border: solid 2px #D5D4D4;
                        transition: 0.5s; 
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12); 
                        &:focus{
                            border-color: var(--green);
                        }
                        @include md{
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 16px;
                            height: 35px;
                            padding: 5px 7rem 5px 20px;
                        } 
                        
                    }
                    .button{
                        border-radius: 50px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        height: 45px;
                        line-height: 45px;
                        @include md{
                            font-size: 12px; 
                            height: 28px;
                            line-height: 28px;
                        } 
                        position: absolute;
                        top: 0;
                        right: 5px;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        padding: 0px 12px;
                        i{
                            margin-left: 5px; 
                            @include md {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            
        }
    }
    
 }
 .InnerBanner{
    width: 100%;
    padding: 8px 0px;
    position: relative;
    background: var(--bannerShadow);   
    min-height: 16px;
    .tittle{
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color:#fff;
        padding: 0px 20px;
        @include md{ 
            font-size: 10px;
            line-height: 14px;
        }
        .link{
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color:#fff;
            text-decoration: underline;
            @include md{ 
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
    .bannerInner{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        .headingMain{
            margin: 0px;
            font-size: 36px;
            color: #fff;
            text-align: center;
            font-weight: 600;
            @include md{
                font-size: 28px;
                width: 70%; 
            }
        } 
    }
}
.enterpiceBanner{
    display: block;
    background: var(--bannerSecShadow); 
    padding: 40px 0px;
    height: auto;
    @include md{
        padding: 25px 0px;
    }
    .bannerInner{
        position: inherit;
        height: auto;
    }
     .card{
        padding: 50px 40px;
        text-align: center;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.09);
        @include md{
            width: 80%;
            padding: 25px 10px;
        }
        .mainHeading{
            padding: 0px;
            font-size: 34px;
            line-height: 51px;
            color: #1C653B;
            @include md{
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 6px; 
            }
            
        }
        .smallHeading{
            padding-left: 0px;
            font-size: 22px;
            line-height: 33px;
            @include md{
            font-size: 11px;
            line-height: 15px;
            font-weight: 500; 
        }
        }
    }
    
}
.parkBanner{
    display: inline-block;
    background: var(--bannerOpacity);
    width: 100%;
    text-align: center;
    padding: 15px 20px;
    .mainHeading {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin: 0;

        span {
            display: inline-block;
            width: 100%;
            font-style: italic;
            font-weight: 400;
        }

        @include md {
            font-size: 16px;
            line-height: 22px;
        }
    }
}