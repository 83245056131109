.themeCard{
    margin-bottom: 25px;
    height: 100%;
    @include md{
        margin-bottom: 15px;
    }
    @include xs{
        --f7-cols-per-row: 1 !important;
    } 
    .status{
        position: absolute;
        top: 10px;
        right: 10px;
    } 
    .cardOverlay{ 
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .cameraInfo{
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
}
.cardImg{
    border-radius: 10px;
    width: 100%;
    height: 280px; 
    overflow: hidden;
    position: relative;
    transition: 0.5s;
    &:hover{
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.35);
        -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.35);
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include md{
        height: 170px; 
    }
}
.cardInfo{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top:10px; 
    @include md{
        padding-top:5px; 
    }
}
.cardHeading{ 
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: var(--blacktone);
    margin: 4px 0px 0px 0px;
    @include md{
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        margin:  0px;
    }
}
.actionIcon{
    display: flex; 
    i{
        font-size: 24px;
        color: var(--black);
        font-weight: 600;
        @include md{
            font-size: 18px;
        }
    }
    &.active{
        i{
            color: var(--yellowshade);
        }
    }
}
.cameraInfo{
    display: flex;
    align-items: center;
    i{  
        font-size: 26px; 
        color: #fff;
    }
    span{
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #fff; 
        margin-left: 5px;
        display: inline-block;
    }
    @include md{
        i{  
            font-size: 20px;  
        }
        span{
            font-size: 11px; 
        }
    }
} 