 .popup {

     &.themeModel,
     &.message-alert-Model {
         width: var(--f7-popup-tablet-width);
         height: var(--f7-popup-tablet-height);
         left: 50%;
         margin-top: auto;
         margin-bottom: auto;
         margin-left: calc(-1 * var(--f7-popup-tablet-width) / 2);
         box-shadow: var(--f7-popup-box-shadow);
         border-radius: 22px;
         overflow-y: auto;
         overflow-x: hidden;
         top: 0px;
         bottom: 0px;
         height: 650px;



         @include md {
            width: 90%;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            height: 580px;
            &.loginModel {
                width: 100%;
                right: 0;
                left: 0;
                border-radius: 0px;
                margin-left: auto;
                margin-right: auto;
                height: 100%;
               }
         }
     @media only screen and (min-width: 200px) and (max-width: 375px){
           
            .display-flex{
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 5;
            }
            &.loginscreenMain .cardfooter{
                padding-bottom: 0px;
            }
         }
         @media only screen and (min-width: 1300px) and (max-width: 1500px) {
             height: 600px;
         }

         @media only screen and (min-width: 375px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
             top: 2%;
             height: 95vh;
             overflow: auto;
             left: 0;
             right: 0;
             margin: 0 auto;

             .SwiperSlider {
                 height: auto;
             }
         }
     }
     &.commanModal.pageReloadModel {
        --f7-popup-tablet-width: 400px;
       
        .card-body {
            text-align: center;
            height: auto; 
            min-height: auto;
            max-width: 100%;
            margin: 0px; 
            padding: 50px 30px; 
            .cardHeader {
                padding:  0px;
                .heading {
                    font-weight: 600;
                    font-size: 26px; 
                }
            } 
            .pageReload{
                margin: 20px 0px;
            }
            .smallHeading{
                font-size: 18px;
                color: var(--black);
                margin: 0px 0px 5px 0px;
            }
            .smallTitle{
                margin: 0px;
                font-size: 16px;
                color: var(--black);
                max-width: 70%;
            }
        }
     }
     &.UploadDocuments {        
        .documentList{
            &.licencePlateLabel{
                width: 100%;
                background-color: var(--yellowopaceshadetwentypercent);
                padding: 10px 14px;
                border-radius: 5px;
                @media screen and (max-width:479px) {
                    padding: 7px 14px;
                }
                span{
                    @media screen and (max-width:479px) {
                        font-size: 14px;
                    }
                }
            }
        }
        .error-message{
            color: var(--danger);
            text-align: left;
            align-items: flex-start;
            margin-bottom: 30px;
            @media screen and (max-width:479px) {
                font-size: 14px;
                line-height: 18px;
            }
            i{
                margin-right: 9px;
                position: relative;
                top: 3px;
            }
        }
    }
 }

 .message-alert-Model,
 .newEmail,
 .loginscreenMain,
 .commanModal {
     .linkMain {
         display: flex;
         align-items: center;
         margin: 10px 10px 5px 10px;
         font-size: 14px;
         color: var(--blacktone);
         text-transform: uppercase;
         font-weight: 500;
         padding: 10px;

         &:last-child {
             margin: 10px 10px 5px auto;
         }

         &:hover,
         &:focus {
             color: var(--green);
         }

         i {
             font-size: 16px;
             margin-left: 3px;
             font-weight: 600;
         }

         @include md {
             font-size: 13px;
             margin: 7px 10px 5px 7px;
             font-weight: 600;

             &:last-child {
                 margin: 7px 10px 5px auto;
             }

             i {
                 font-size: 15px;
             }
         } 

         @media only screen and (min-width: 1300px) and (max-width: 1500px) {
             margin: 7px 10px 0px 10px;
         }
     }
     .disabled {
        color: #868686;
        cursor: not-allowed;
        pointer-events: none;
     }

     .SwiperSlider {
         height: calc(100% - 60px);

         @media only screen and (min-width: 375px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
             height: auto;
         }

         .swiper-button-prev,
         .swiper-button-next {
             display: none;
         }
     }

     .cardHeader {
         padding: 0px 25px;

         @include sm {
             padding: 5px 10px;
         }

         @media only screen and (min-width: 1300px) and (max-width: 1500px) {
             padding: 5px 10px;
         }

         .heading {
             font-weight: 600;
             font-size: 26px;
             line-height: 38px;
             color: var(--themeColor);
             margin: 0px;
             text-align: center;
             overflow: hidden;
             text-overflow: ellipsis;
             display: -webkit-box;
             -webkit-line-clamp: 2;
             line-clamp: 2;
             -webkit-box-orient: vertical;
             @include md {
                font-size: 18px;
                line-height: 22px;
             } 
         }

         .smallHeading {
             display: inline-block;
             width: 100%;
             text-align: center;
             font-weight: 600;
             font-size: 20px;
             line-height: 30px;
             color: var(--blacktone);

             @include md {
                font-size: 12px;
                line-height: 18px;
             }
          @media only screen and (min-width: 200px) and (max-width: 375px){
                font-size: 14px;
                 line-height: 18px;
            }
         }
     }

     .card-body {
         max-width: 80%;
         margin: 0 auto;
         height: 370px;
         display: flex;
         flex-flow: column;
         align-items: center;
         justify-content: center;

         @include md {
             padding: 20px 0px;
             max-width: 90%;
             height: auto;
         }
  

         @media only screen and (min-width: 1300px) and (max-width: 1500px) {
             height: 360px;
         }

         .tittle {
             font-size: 18px;
             color: var(--black);
             margin: 0px;
          @media only screen and (min-width: 200px) and (max-width: 375px){
                font-size: 16px;
             }
         }

         .item-inner {
             padding-right: 0px;
         }

         .info {
             font-size: 14px;
             line-height: 17px;
             margin: 0px 0px 0px 0px;
             color: var(--black);

             @include md {
                 font-size: 10px;
                 margin: 0px;
             }
         }

         .margin-top {
             margin-top: 30px !important;
             margin-bottom: 8px;

             @include md {
                 margin-top: 20px !important;
             }
         }

         .resend {
             margin-top: 10px;

             .link {
                 font-weight: 600;
                 color: var(--black);
                 padding: 10px 5px;
             }
         }

         .SmallText {
             text-align: center;
             margin-top: 0px;
             color: var(--black);
             font-size: 15px;
             line-height: 136%;
             font-weight: 500;

             @include md {
                 margin: 0px 0px 10px 0px; 
             }
          @media only screen and (min-width: 200px) and (max-width: 375px){
                font-size: 12px;
                line-height: 16px;
             }
         }

     }

     .resend {
         @include md {
             font-size: 14px;
         }
     }

     .cardfooter {
         max-width: 80%;
         margin: 0 auto;
         padding: 5px 0px 15px 0px;
         display: flex;
         flex-flow: column;
         align-items: center;
         justify-content: center;

         @include md {
             max-width: 90%;
             padding: 0;
         }

         .button {
             display: inline-flex;
             justify-content: center;
             align-items: center;
             width: 200px;
             border-radius: 50px;
             font-size: 16px;
             height: 40px;
             line-height: 40px;
             margin: 10px 0px;
             min-width: 200px;

             @media only screen and (min-width: 1300px) and (max-width: 1500px) {
                 margin: 5px 0px;
             }

             @include md {
                 font-size: 14px;
                 height: 36px;
                 line-height: 36px;
                 margin: 5px 0px;
                 width: 180px;
             }

         }

         .col-50 {
             .button {
                 @include md {
                     width: 100%;
                     min-width: auto;
                 }
             }
         }
     }

     .otpModel {
         .card-body {
             height: 400px;

             @include md {
                 height: auto;
             }
         
             @media only screen and (min-width: 1300px) and (max-width: 1500px) {
                 height: 400px;
             }
         }

         .otpViewIn {
             --f7-grid-gap: 25px;
             width: 80%;
             margin: 0 auto;

             @include md {
                 width: 100%;
                 --f7-grid-gap: 15px;
             }

             .otpViewInner {
                 margin: 0 auto;

                 .otpInner {
                     flex-wrap: wrap;

                     .formInput {
                         border-radius: 0;
                         font-size: 20px;
                         width: 65px !important;
                         border: solid 2px #868686; 
                         height: 60px;
                         padding: 5px;
                         text-align: center;
                         font-weight: 500;
                         transition: 0.5s;

                         @include md {
                             width: 60px !important;
                             height: 55px;
                         }
                      @media only screen and (min-width: 200px) and (max-width: 375px){
                            width: 55px !important;
                            height: 50px;
                         }
                         &:focus,
                         &.input-focused,
                         &.active-state {
                             border-color: var(--yellow);
                         }

                         &.errorInput {
                             border-color: var(--danger);
                         }
                     }
                 }

                 .otpInner>div {
                     margin: 0 10px;

                     @include md {
                         margin: 0 5px;
                     }
                 }

                 .error-message {
                     margin: 5px 0px 0px 5px;
                 }
             }
         }
     }
     &.commanModal { 
//    @media only screen and (min-width: 200px) and (max-width: 375px){
//             height:430px !important; 
//         }
    }
     &.message-alert-Model,
     &.commanModal {
         height: max-content; 
         .card-body {
             height: auto;
             margin: 1.5rem auto;
             min-height: 150px;
            &.cardHeader{
                min-height: auto;
                margin: 0 auto;
                @include md{
                    padding: 0;
                }
            }

             @include md {
                 margin: 1em auto;
             }

             @media only screen and (min-width: 375px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                 margin: 1rem auto;
             }

         }

         .cardfooter {
             padding: 5px 0px 30px 0px;
         }

         .themeBtn {
             margin: 0;
         }
     }
 }

 .Documents {

     .Information {
         display: inline-block;
         width: 100%;
         background: #F5F4F7;
         padding: 15px;
         max-height: 150px;
         overflow: auto;

         p {
             margin-top: 0px;
             font-weight: 400;
             font-size: 14px;
             line-height: 18px;
             color: #231F20;

             @include md {
                 font-size: 11px;
                 line-height: 16px;
             }
         }

         ul {
             display: inline-block;
             width: 100%;

             li {
                 font-weight: 400;
                 font-size: 14px;
                 line-height: 18px;
                 color: #231F20;
                 padding-left: 15px;
                 position: relative;

                 &:not(:last-child) {
                     margin-bottom: 10px;
                 }

                 &::after {
                     content: '';
                     position: absolute;
                     left: 0px;
                     top: 5px;
                     width: 5px;
                     height: 5px;
                     border-radius: 50%;
                     background-color: #231F20;
                 }

                 @include md {
                     font-size: 11px;
                     line-height: 16px;
                 }
             }
         }
     }

     .documentList {
         display: inline-block;
         width: 100%;
         margin: 30px 0px 0px;
         max-height: 250px;
         overflow: auto;
         padding-right: 10px;
         @include xxl {
            margin: 20px 0px 0px;
            max-height: 180px;
         }
         @include md {
             margin: 20px 0px 0px;
             max-height: 250px;
         }

         .listing {
             display: inline-flex;
             width: 100%;
             align-items: center;

             &:not(:last-child) {
                 margin-bottom: 15px;
             }

             .documentInfo {
                 display: inline-block;
                 width: 70%;
                 padding: 0px 15px;

                 @include md {
                     width: 65%;
                 }

                 .name {
                     font-weight: 600;
                     font-size: 16px;
                     line-height: 20px;
                     color: #000000;
                     margin-top: 0px;
                     margin-bottom: 2px;

                     @include md {
                         font-size: 13px;
                         line-height: 18px;
                         margin-bottom: 5px;
                     }
                 }

                 .infoText {
                     font-weight: 400;
                     font-size: 13px;
                     line-height: 18px;
                     color: #000000;
                     margin: 0px;

                     @include md {
                         font-size: 11px;
                         line-height: 14px;
                     }
                 }
             }

             .documentButton {
                 display: inline-block;
                 width: 30%;

                 @include md {
                     width: 35%;
                 }

                 .button {
                     margin: 0;
                     height: 33px;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 33px;
                     padding: 0px 20px;
                     margin-bottom: 8px;

                    //  &:last-child {
                    //      margin-bottom: 0px;
                    //  }

                     .icon {
                         display: inline-flex;
                         width: 20px;
                         height: 20px;
                         margin-right: 8px;
                     }

                     @include md {
                         font-size: 12px;
                         line-height: 12px;
                         height: 30px;
                         margin-bottom: 7px;
                         padding: 0px 5px;

                         .icon {
                             width: 18px;
                             height: 18px;
                             margin-right: 5px;
                         }
                     }
                 }
             }

         }

     }

 
         &.commanModal {
             &.commanModal {
                 .card-body {
                     padding: 0px;

                     @include xxl {
                         margin: 1rem auto;
                     }
                 }
             }
         } 

     &.commanModal {
         &.commanModal {
             .cardfooter {
                 padding: 5px 0px 30px 0px;
             }
         }
     }

     .DocumentIfreme {
         display: inline-block;
         width: 100%;
         background: #F5F4F7;
        
         height: 400px;
         overflow-y: auto;
          /* scrollbar */
          &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                -webkit-border-radius: 10px;
                border-radius: 10px;
            }
            
            &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.3);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            }
            
           &:-webkit-scrollbar-thumb:window-inactive {
                background: rgba(255, 255, 255, 0.3);
            }
            
         img{
            max-width: 100%;
            object-fit: contain;
            min-width: 100%;
            min-height: 98%;
            padding: 15px;
         }

         @include xxl {
             height: 350px;
         }

         @include md {
             height: 350px;
         }

         iframe {
             display: inline-block;
             width: 100%;
             height: 98%; 
             border: 0;
             padding: 15px;
         }
           .mobile__pdf__container{
            width: 100%;
            height: 100%; 
            padding: 15px;
            .mobile__pdf__container__header,
            footer,
            #loadingBar,
            #errorWrapper{
                display: none;
            }
             #viewerContainer{
                position: inherit;
                height: 100%;
                .pdfViewer { 
                    overflow: inherit;
                    .page{
                        border-image: inherit;
                        background-clip: inherit;
                        border: 0;
                        margin: 0;
                        width: 100% !important;
                        height: 100% !important;
                        .canvasWrapper,
                        canvas{
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
             }
         } 
       
     }

     .messageInfo {
         padding: 20px 20px 0px;

         @include xxl {
             padding: 10px 20px 0px;
         }
         @include md {
            padding: 20px 20px 10px;
        }

         .heading {
             font-weight: 600;
             font-size: 28px;
             line-height: 38px;
             color: var(--themeColor);
             margin: 0px 0px 15px;
             text-align: center;

             @include xxl {
                font-size: 26px;
                line-height: 34px;
                 margin: 0px 0px 5px;
             }

             @include md {
                 font-size: 24px;
                 line-height: 28px;
             }
         }

         .SmallText {
             text-align: center;
             margin: 0px;
             color: var(--black);
             font-size: 14px;
             line-height: 22px;
             font-weight: 500;

             @include md {
                 line-height: 18px;
                 font-size: 13px;
             }
         }
     }
 }
 .body-Scroll{
    max-height: 200px;
    overflow: auto;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding-right: 10px;
 }
 
    /* scrollbar */
    .body-Scroll::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    
    .body-Scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    
    .body-Scroll::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    
    .body-Scroll:-webkit-scrollbar-thumb:window-inactive {
      background: rgba(255, 255, 255, 0.3);
    }
    
.transferErrorModal{
    .card-body{
    .link{
        word-wrap: break-word;
        display: inline-block;
        width: 100%;
        font-size: 15px;
        line-height: 136%;
        font-weight: 500;
        color: var(--blacktone);  
        &:hover,
        &:focus{
            color: var(--themeColor);
        }

    }
}
}
.loginscreenMain{
    .card-body{
        .resend{
            .link{
                border: 0;
                background: unset;
                width: max-content;
                font-family: Poppins, sans-serif;
                line-height: 1.5;
                padding: 10px 5px 10px 9px;
            }
        }
    }
}
@media only screen and (max-width: 1023px) {
    .loginscreenMain .otpModel .card-body {
        height: auto;
    }
}

.loginnote {
    font-size: 16px;
    color: var(--black);
    margin: 20px 0px 0px 0px;
    text-align: center;
}


// information modal desgin start here
.importantmainHeading {
    padding: 12px 30px 12px 15px;
    border: none;
    background: #ffffff;
    box-shadow: 0 0 7px rgba(0, 0, 0, .25);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
        color: #000;
        content: var(--f7-accordion-chevron-icon-down);
        font-family: framework7-core-icons;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        transition: .5s;
    }

    .title {
        margin: 0px;
        text-align: left;
        color: #231f20;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        font-family: Poppins, sans-serif;
    }
}

.popup{
&.commanModal {
    &.informationPopup {
        //display: block;
        left: 0;
        right: 0; 
        top: inherit;
        bottom: 0;
        margin: 0 auto;
        height: calc(100vh - 218px);
        border-radius: 22px 22px 0px 0px; 
        width: 100%;
        overflow: visible;
        @media (min-width: 1025px) {
            height: calc(100vh - 261px);
        } 
        @media (min-width: 1200px) {
            max-width: 1140px;
        } 
        .cardHeader {
            padding: 25px 10px 10px 10px; 
            .heading {
                color: #008453;
                font-size: 20px;
                font-weight: 600;
            }
            .popupCloseCenter{
                position: absolute;
                top: -52px;
                left: 0px;
                right: 0px;
                margin: 0 auto;
                width: 100%;
                text-align: center;
            .popup-close { 
                background: #ffffff;
                border-radius: 30px;
                padding: 10px 20px;
                color: #000; 
                font-weight: 700;
                font-size: 14px;
    
                .f7-icons {
                    font-size: 14px;
                    display: block;
                    margin-left: 8px;
                    background: #4A4A4A;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    text-align: center;
                    color: #ffffff;
                    padding-top: 3px;
                    font-weight: 600;
                }
            }
        }
        }
        .card-body { 
            overflow: auto;
            display: block;
            padding: 1px 70px 25px 70px;
            width: 100%;
            text-align: left;
            margin: 0;
            height: calc(100% - 147px);
            max-width: 100%;
            @media (min-width: 769px) {
                height: calc(100% - 163px);
            } 
            @media (max-width: 769px) {
                padding: 1px 15px 25px 15px;
            } 
            p,
            div
             {
                margin: 0px;
                font-size: 12px;
                font-weight: 400;
                @media (max-width: 769px) {
                    font-size: 11.5px;
                } 
            }
    
            .informationmorebtn {
                font-size: 12px;
                font-weight: 600;
                color: #008157;
                text-decoration-line: underline;
            }
    
            .mb-0 {
                margin-bottom: 0px;
            }

            .sectionpdfhtml{
                box-shadow: 0 0 7px rgba(0, 0, 0, .25);
                margin-top: 30px;

                .header{
                    background: #ffffff;
                    padding: 25px;
                    text-align: center;


                    .typotitle{
                        font-size: 22px;
                        color: #835E2A;
                        margin: 0px;
                    }

                    .typosubtitle{
                        font-size: 22px;
                        color: #253F5A;
                        margin-top: 7px;
                    }
                }

                .gradiantorange{
                    background: linear-gradient(257.23deg, #E0A047 -13.17%, #7A5727 185.12%);
                
                .pdfbodysection{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    gap: 35px;
                    @media (max-width: 610px) {
                        justify-content: unset;
                        gap: 10px;
                        width: 100%;
                        margin: auto;
                    }
                    @media (max-width: 330px) {
                        gap: 2px;
                    }

                    .middlearrowsection{
                        @media (max-width: 610px) {
                            width: 100%;
                            text-align: center;
                        }
                    }

                    .image-width{
                        width: 100%;
                    }
                 
                    .pdfleftsidesection{
                        color: #ffffff;
                        font-size: 18px;
                        width: 300px;
                        @media (max-width: 610px) {
                            width: 100px;
                            font-size: 14px;
                        } 
                        .pdflinktext{
                            color: #253F5A;
                            font-weight: 600;
                            font-size: 18px;
                            display: block;
                            margin-top: 10px;
                            word-wrap: break-word;
                            line-height: 23px;
                            @media (max-width: 610px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                            u{
                                @media (max-width: 610px) {
                                    width: 100px;
                                } 
                            }
                        }
                    }

                }
            }

            .gradiantwhite{
                background-color: #ffffff;

                .whiteheadersection{
                    padding: 15px;
                    text-align: center;
                    position: relative;

                        &:after{
                            border-left: 60px solid transparent;
                            border-right: 60px solid transparent;
                            border-top: 60px solid #fff;
                            content: "";
                            height: 0;
                            left: 50%;
                            position: absolute;
                            top: 100%;
                            transform: translate(-50%, -50%);
                            width: 0;
                        }

                    .gradiantwhitetypotitle{
                        color: #204973;
                        font-weight: 700;
                        font-size: 22px;
                    }
                }
                
            }

            .gradiantblue{
                background: linear-gradient(18.48deg, #253F5A -1.02%, #1E4B7B 100.45%);
                padding-top: 50px;
                .pdfbodysection{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    gap: 35px;
                    @media (max-width: 610px) {
                        justify-content: unset;
                        gap: 10px;
                        width: 100%;
                        margin: auto;
                    }
                    @media (max-width: 330px) {
                        gap: 2px;
                    }
                    .middlearrowsection{
                        @media (max-width: 610px) {
                            width: 100%;
                            text-align: center;
                        }
                    }
                 
                    .pdfleftsidesection{
                        color: #ffffff;
                        font-size: 18px;
                        width: 300px;
                        @media (max-width: 610px) {
                            width: 100px;
                            font-size: 14px;
                        } 
                        .pdflinktext{
                            color: #E0A047;
                            font-weight: 500;
                            font-size: 18px;
                            display: block;
                            margin-top: 10px;
                            word-wrap: break-word;
                            line-height: 23px;
                            @media (max-width: 610px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                            u{
                                @media (max-width: 610px) {
                                    width: 100px;
                                } 
                            }
                        }
                    }
                    .image-width{
                        width: 100%;
                    }
                }
                .blusesectiondoublepasses{
                    margin-bottom: 10px;
                    display: block;
                }
            }
            }

            .sectionpdfhtml{
                box-shadow: 0 0 7px rgba(0, 0, 0, .25);
                margin-top: 30px;

                .header{
                    background: #ffffff;
                    padding: 25px;
                    text-align: center;


                    .typotitle{
                        font-size: 22px;
                        color: #835E2A;
                        margin: 0px;
                    }

                    .typosubtitle{
                        font-size: 22px;
                        color: #253F5A;
                        margin-top: 7px;
                    }
                }

                .gradiantorange{
                    background: linear-gradient(257.23deg, #E0A047 -13.17%, #7A5727 185.12%);
                
                .pdfbodysection{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    gap: 35px;
                    @media (max-width: 610px) {
                        justify-content: unset;
                        gap: 10px;
                        width: 100%;
                        margin: auto;
                    }
                    @media (max-width: 330px) {
                        gap: 2px;
                    }

                    .middlearrowsection{
                        @media (max-width: 610px) {
                            width: 100%;
                            text-align: center;
                        }
                    }

                    .image-width{
                        width: 100%;
                    }
                 
                    .pdfleftsidesection{
                        color: #ffffff;
                        font-size: 18px;
                        width: 300px;
                        @media (max-width: 610px) {
                            width: 100px;
                            font-size: 14px;
                        } 
                        .pdflinktext{
                            color: #253F5A;
                            font-weight: 600;
                            font-size: 18px;
                            display: block;
                            margin-top: 10px;
                            word-wrap: break-word;
                            line-height: 23px;
                            @media (max-width: 610px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                            u{
                                @media (max-width: 610px) {
                                    width: 100px;
                                } 
                            }
                        }
                    }

                }
            }

            .gradiantwhite{
                background-color: #ffffff;

                .whiteheadersection{
                    padding: 15px;
                    text-align: center;
                    position: relative;

                        &:after{
                            border-left: 60px solid transparent;
                            border-right: 60px solid transparent;
                            border-top: 60px solid #fff;
                            content: "";
                            height: 0;
                            left: 50%;
                            position: absolute;
                            top: 100%;
                            transform: translate(-50%, -50%);
                            width: 0;
                        }

                    .gradiantwhitetypotitle{
                        color: #204973;
                        font-weight: 700;
                        font-size: 22px;
                    }
                }
                
            }

            .gradiantblue{
                background: linear-gradient(18.48deg, #253F5A -1.02%, #1E4B7B 100.45%);
                padding-top: 50px;
                .pdfbodysection{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    gap: 35px;
                    @media (max-width: 610px) {
                        justify-content: unset;
                        gap: 10px;
                        width: 100%;
                        margin: auto;
                    }
                    @media (max-width: 330px) {
                        gap: 2px;
                    }
                    .middlearrowsection{
                        @media (max-width: 610px) {
                            width: 100%;
                            text-align: center;
                        }
                    }
                 
                    .pdfleftsidesection{
                        color: #ffffff;
                        font-size: 18px;
                        width: 300px;
                        @media (max-width: 610px) {
                            width: 100px;
                            font-size: 14px;
                        } 
                        .pdflinktext{
                            color: #E0A047;
                            font-weight: 500;
                            font-size: 18px;
                            display: block;
                            margin-top: 10px;
                            word-wrap: break-word;
                            line-height: 23px;
                            @media (max-width: 610px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                            u{
                                @media (max-width: 610px) {
                                    width: 100px;
                                } 
                            }
                        }
                    }
                    .image-width{
                        width: 100%;
                    }
                }
                .blusesectiondoublepasses{
                    margin-bottom: 10px;
                    display: block;
                }
            }
    
        }
    
        }
    
        .cardfooter {
            padding: 15px;
            box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.15);
            margin-bottom: 0px;
            background-color: #ffffff;
            max-width: 100%;
            height: 90px;
             .button{
                height: 40px;
                min-width: 200px;
                width: max-content;
                padding: 0 12px;
             }
        }
    }
}
}