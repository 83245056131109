 
.paymentMain {
    &.screenCenter{
        padding-top: 25px;
        padding-bottom: 20px;
        @include md{
            padding-bottom: 0px;
        }
    }
    &.disbled{
        position: relative;
        &::after{
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%; 
            background: rgba(0, 0, 0, 0.5);
            z-index: 1;           
        }
    }
   
    .card {
        border-radius: 14px;
        margin: 0px 0px 30px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        --f7-grid-gap: 10px;   
        &.active{ 
            border: 2px solid transparent; 
            background: 
            linear-gradient(to right, white, white), 
           var(--paymentCard);  
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
        }
        .card-body {
            padding: 14px;

            .parkName {
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #231F20;
                margin: 0px 0px 8px;
                @include md{
                    font-size: 14px; 
                    line-height: 20px;
                }
            }

            .amountText {
                font-weight: 700;
                font-size: 16px;
                line-height: 17px;
                color: var(--green);
                margin: 0; 
                cursor: pointer;
            }

            .qtyText {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                margin: 0px 0px 5px;
                @include sm-x{
                    text-align: left;
                }
            }
            
            .qtyInput{
                @include sm-x{
                    justify-content: flex-start;
                }
            }

            .stepper {
                height: 20px;

                .stepper-button-minus {
                    background-color: #868686;
                }

                .stepper-button-plus {
                    background-color: #000000;
                }

                .stepper-button-minus,
                .stepper-button-plus {
                    width: 20px;
                    height: 20px;
                    border-radius: 50px;
                    padding: 0px;
                    color: #fff;
                }
                .stepper-button-plus{
                   &:before{
                       height: 13px;
                       width: 3px;
                   }
                   &:after {
                       width: 13px;
                       height: 3px;
                   }
                }
                .stepper-button-minus{ 
                   &:after {
                       width: 13px;
                       height: 3px;
                   }
                }
                .stepper-button-minus,
                .stepper-button-plus {

                    &::after,
                    &::before {
                       left: 50%;
                       top: 48%;
                        background-color: #fff;
                    } 
                }

                .stepper-input-wrap input {
                    width: 30px;
                    font-weight: 700;
                    font-size: 19px;
                    line-height: 23px;
                    color: #000000;
                }

                .stepper-button-minus,
                .stepper-button-plus,
                .stepper-input-wrap {
                    border: 0;
                }
            }
        }

        .card-header{
            border-bottom: 1px solid #eceaea;
            padding: 0px;
            min-height: auto;
            &::after{
                z-index: inherit;
            }
           .accordion-item{
               width: 100%;
           }
            .accordion-item-toggle {
                display: flex;
                padding: 15px 14px;

                .mainHeading {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    position: relative;

                   
                    .icon {
                        position: absolute;
                        right: -22px;
                        top: 0;
                        transition: 0.5s;
                        &::after{
                            display: block;
                            content: var(--f7-accordion-chevron-icon-down);
                            font-family: framework7-core-icons;
                            font-weight: 400;
                            font-style: normal; 
                            font-size: 20px;
                            color: #000;
                          
                        }
                    }

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        background-color: #fff;
                        padding: 2px;
                        border-radius: 50%;
                        overflow: hidden;
                        box-shadow: 2px 2px 4px #1E2926;
                        -webkit-box-shadow: 2px 2px 4px #1E2926;
                        -moz-box-shadow: 2px 2px 4px #1E2926;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .title { 
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #231F20;
                    margin: 0px 0px 0px 8px;
                    position: relative;
                }

            }

            .accordion-item-opened {
                .accordion-item-toggle{
                    padding-bottom: 7px;
                }
                .mainHeading { 
                    .title { 
                        color: var(--green);
                    } 
                    .icon { 
                        transform: rotate(-180deg);
                        &::after{
                        
                        color: var(--green);
                    }
                    }
                }
            }

            .accordion-item-content {
                .accordionBody {
                    padding:  0px 0px 19px 42px; 
                    .instructionInfo{
                        max-height: 80px;
                        overflow: auto;
                        padding-right: 15px;
                        p {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            color: #231F20;
                            margin: 0px;
                            @include md{
                                font-size: 10px;
                                line-height: 12.85px;
                            }
                          a{
                            z-index: inherit;
                          }
                        }
                    }
                }

            }
        }
    }

    .listing {
        margin-top: 15px;
        margin-bottom: 10px;
       &:not(:last-child){
        margin-bottom: 0px;
       }
        .smallHeading {
            display: inline-block;
            font-weight: 700;
            font-size: 13px;
            line-height: 12px;
            color: #231F20;
            width: 100%;
            margin-bottom: 5px;
        }

        .selectCustomSearch {
            padding: 0px;
        } 
    }
    @include md{
    .select-search-container:not(.select-search-is-multiple) .select-search-select{
       top: 30px;
       border: 0;
   } 
}
   .totalAmount{
       border-top: 1px solid #D7D7D7;
       .title{
           display: flex;
           align-items: center;
           justify-content: flex-end;
           margin: 0px;
           font-weight: 600;
           font-size: 18px;
           line-height: 19px;
           color: var(--themeColor);
           padding: 15px 0px;
           span{
               font-weight: 500;
               font-size: 12px;
               line-height: 13px;
               color: #231F20;
               margin-right: 5px;
           }

       }
   }
   .info{ 
       font-size: 12px;
       line-height: 16px;
       margin: 5px 0px 0px 0px; 
   }
   .listing {
   .formInput {
       .item-inner {
          padding: 0px;
           }
       }
   }

   .customBtn{
       display: inline-block;
       width: 200px;  
       margin:25px 0px 5px;
           @include md{
               font-size: 14px;
               height: 36px;
               line-height: 36px;
             
               width: 180px;
           } 
     
   } 
   .payButton{
       text-align: center;
       margin-top: 5px;
       .button{
           display: flex;
           align-items: center;
           padding: 6px 8px;
           height: auto;
           font-size: 16px;
           font-weight: 600;
           margin: 15px auto 0px;
           width: 250px;
           border-radius: 8px;
           svg{
               margin: 0px 5px;
           }
       }
   }
  

}
.relative{
    position: relative;
   }
   .infoIcon{
    position: absolute;
    bottom: 0px;
    right: 8px;
    top: 0;
    i{
        font-size: 14px;
        background: #D9D9D9;
        font-weight: 800;
        color: #515151;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
   }
.addCart{ 
    .heading{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--themeColor);
    }
    .list {
        .item-inner{
            padding-right: 0px;
        }
    }
   }
.cvvInfo {
    &.popover{
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: #fff;
        width: 230px; 
        transform-origin: left top !important;
        .popover-angle{
            display: none;
        }
        .cvvInformation{
            display: flex;
            padding: 15px;
            .valueText{
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;  
                color: #4A4A4A;   
                padding-left: 10px;         
            }
        }
      
    }
   }
 
.addVehicle {
    &.themeModel{
        @include md { 
            height: 570px;
        }
   @media only screen and (min-width: 200px) and (max-width: 375px){
            height: 470px; 
        }
    }
    
    .modelLogin { 
        transform: inherit;
        position: inherit;
        visibility: inherit;
        .card-body{ 
            height:430px; 
            @include md{  
                height: 420px;
                padding-bottom: 0;
            }
          
        }
    }
}
.instructionMain{
   background: #FFFFFF;
   box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25); 
   -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
   -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
   margin-top:10px;
   .accordion-item{
       width: 100%;
   }
    .accordion-item-toggle {
        display: flex;
        padding: 12px 30px;
        @include md{  
            padding: 12px 30px 12px 15px;
        } 
        .mainHeading {
            display: inline-flex;
            align-items: center;
            width: 100%;
            position: relative;

            &::after {
                content: var(--f7-accordion-chevron-icon-down);
                font-family: framework7-core-icons;
                font-weight: 400;
                font-style: normal;
                position: absolute;
                right: 0px;
                font-size: 20px;
                color: #000;
                transition: 0.5s;
            } 
        }

        .title {
            font-weight: 500; 
            font-size: 16px;
            line-height: 24px;
            color: #231F20;
            margin: 0;
        }

    }

    .accordion-item-opened {
        .mainHeading {
            .title{
                color: var(--themeColor);
            }
            &::after {
                transform: rotate(-180deg);
                color: var(--themeColor);
            }
        }
    }
    .agreeBtn{
        margin-top: 10px;
        text-align: right;
        padding-right: 20px;
        .themeBtn {
            display: inline-block;
            font-weight: 500;
            font-size: 12px;
            padding: 0px 15px;
            height: 28px;
            line-height: 28px;
            width: 115px;
        }
    }
    .accordion-item-content {
        .accordionBody {
            padding:  18px 10px 18px 32px;
            background-color: #F5F4F7;
            .instructionInfo{
                max-height: 140px;
                overflow: auto;
                padding-right: 22px;
                &.instructionInfoOpen{
                    max-height: inherit;
                }
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #231F20;
                    margin: 0px;
                    &:last-child{
                        margin-top: 10px;
                    }
                    @include md{  
                        font-size: 11px;
                        line-height: 13.31px;
                    }
                }
            }
          .customCheck{
            margin-top: 10px;
            ul{
                background-color: transparent;
                &::after,
                &::before{
                    display: none;
                }
            }
            .item-content{
                background-color: transparent;
                padding-left: 0px;
                min-height: auto;
                .icon-checkbox{ 
                    margin-right: 8px;
                    border-radius: 2px;
                    border-color: #868686;
                    width: 13px;
                    height: 13px;
                &:after {  
                        left: -3px;
                        top: -7px;
                        color: #000;
                        font-weight: 600;
                    }
                }
                .item-inner{
                    padding-top: 0px;
                    padding-bottom: 0px;
                    min-height: auto;
                }
                .item-title{
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 10px;
                    color: #000000;
                }
            }
            label.item-checkbox input[type=checkbox]:checked~.icon-checkbox{
                background: rgba(74, 74, 74, 0.13);
            }
            &.errorCheck{
                .item-content{ 
                    .icon-checkbox{  
                        border-color: #C1272D; 
                    } 
                    .item-title{ 
                        color: #C1272D;
                    }
                }
            }
          }
        }
        .agreedInfo{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #4A4A4A;
            padding: 20px;
        }
    }
}
.donebtn{
    display: inline-block;
    width: 100%;
    padding: 25px 15px;
    background-color: #F5F4F6;
    text-align: center;
    .button {
        display: inline-flex;
        padding: 5px 20px;
        font-size: 16px; 
        line-height: 28px;
        font-weight: 600; 
        color: #000;
        min-width: 180px;
        display: inline-flex;
        height: auto;
        @include md { 
            font-size: 14px;
        }
    }
}
.CloseIdBtn{
    position: sticky;
    bottom: 0px;
    z-index: 2; 
    background: #FFF;
    box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.15);
}
.subPortalMain {
    .donebtn{ 
        background-color: #fff; 
    } 
}