.tabbingHeader{
    width: 80%;
    margin: 0 auto;
    background-color: transparent !important;
    height: 70px;
    z-index: 0;
    backdrop-filter: inherit !important;
    display: flex;
    justify-content: space-around;

    @include lg {
        width: 100%;
        height: 60px;
    } 
    .toolbar-inner{
        align-items: flex-start;
        @include lg {
            padding: 0px; 
        }
    }
    li{
        width: 100%;
        margin: 0px 5px;
        @include lg {
            margin: 0px 2px; 
        }
        @include sm {
            margin: 0px 1px; 
        }
    }
    .tab-link {
       
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        text-transform: inherit;
        color: #000;
        transition: 0.5s;
        overflow: inherit !important;
        height: 60px;
        width: 100%;
        text-align: center;
        @include lg {   
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            height: 50px;
        }
        @include sm { 
            font-size: 12px;
            line-height: 14px;
            text-align: center;
        }
        &.tab-link-active{
            color: var(--green);
        }
        &::before{
            display: none;
        }
    }
    .tab-link-highlight{
        display: none;
    } 
 }
 .tabbingBody{
        padding: 30px 0px;
        @include lg {
            padding: 15px 0px;
        }
        .backBtn {
            .button{
                padding: 10px 10px 10px 0px;
            }
        }
        .selectCustomSearch,
        .selectCustomSearch,
        .formInput {
            padding: 10px 0px;
            @include md {
                padding: 5px 0px;
            }
        }
        .formInput,
        .selectCustomSearch{            
        .item-inner{
            padding: 0px;
        }
        }
        .smallHeading{
            display: inline-block;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            margin-bottom: 10px;
            width: 100%; 
        }
        .themeLink {
            display: inline-flex;
            align-items: center;
            text-decoration: inherit;
            justify-content: flex-end;
            font-size: 14px;
             font-weight: 500; 
             &.link{
                padding: 10px 0px 10px;
             }
            i{
                font-size: 20px;
                font-weight: 800;
                margin-right: 5px; 
            }
        }
        .cardAccpetInfo{
            margin-top: 3rem;
            ul{
                display: flex;
                li{
                    margin-right: 10px; 
                    width:  50px;
                    img{
                        width: 100%;
                    }
                }
            }
           }
}
.vehicleCard {
    display: inline-block;
    width: 100%;
    border: 2px solid #BCBCBC;
    border-radius: 12px;
    padding: 10px;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 15px;
    @include md{
        padding: 8px;
    }
   
    &:hover,
    &:focus,
    &.active{
        border-color: var(--green);   
    }
    .row{
        --f7-grid-gap: 0px;
    }
    .vehicleInfo{
        display: flex;
        align-items: center;
        .image{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            padding: 0px 6px;
            height: 50px;
            background: #D9D9D9;
            border-radius: 50%;
            box-shadow: 0px 0px 4px rgb(30 41 38 / 50%);
            -webkit-box-shadow: 0px 0px 4px rgb(30 41 38 / 50%);
            -moz-box-shadow: 0px 0px 4px rgb(30 41 38 / 50%);
            img{
                width: 100%;
            }
            svg{
                width: 32px;
                height: 32px;
            }
        }
        .vehicleName{
            display: flex;
            flex-flow: column;
            width: 80%;
            padding:0 10px;            
            @include md{
                width: 65%;
            }
            span{
                display: inline-flex;
                width: 100%;
                margin: 0px;
            }
            .label{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #000000; 
                @include md{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            .Visa{ 
                font-size: 16px;
                line-height: 20px; 
                color: #000000;
                @include md{
                    font-size: 14px;
                    line-height: 18px;
                }
                @include sm{
                    font-size: 13px;
                    line-height: 18px;
                }
            }
            .parkName{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px; 
                color: #000000;
                overflow: hidden;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // line-clamp: 2;
                // -webkit-box-orient: vertical;
                display: block;
                margin: 0;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                @include md{
                    font-size: 14px;
                    line-height: 18px;
                }
                @include sm{
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
       
    }
    .vehicleaction{
        @include bp-350{
            display: flex;
            flex-direction: column;
        }
        justify-content: space-around;
        align-items: center;
        .label{
            font-weight: 700;
            font-size: 18px;
            line-height: 20px; 
            color: var(--black); 
            width: 100%;
            @include md{
                font-size: 16px;
                line-height: 20px;
            }
            @include sm{
                font-size: 13px;
                line-height: 18px;
            }
        }
        .statelabel{
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #000000; 
            width: 100%;
            @include md{
                font-size: 16px;
                line-height: 21px;
                display: flex;
                flex-direction: column;
            }
        }
        .actionBtn{
            // width: 80px;
            // @include md{
            //     width: 70px;
            // }
            .button{
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                font-weight: 600; 
                width: max-content;
                margin-left: auto;
                &:first-child{
                    margin-bottom: 5px;
                }
                @include sm{
                    font-size: 11px;
                    padding: 0 5px;
                }
            }
        }
        .vehicleRow{
            @include bp-350{
                flex-wrap: wrap;
                justify-content: flex-end;
            }
            .vehicleCol {
                @include bp-350{
                    display: flex;
                    width: 100%;
                    text-align: right;
                    white-space: nowrap;
                    margin-bottom: 5px;
                }
                .label{
                    @include xs{
                        font-size: 12px;
                    }
                }
                .statelabel{
                    @include xs{
                        font-size: 13px; 
                    }
                    @include bp-350{
                        padding-left: 10px;
                        font-size: 12px;
                        width: max-content;
                    }
                }
            }
        }
    }
 
}
.corporate-account{
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.30);
    padding:25px 15px 20px;
    border-radius: 10px;
    margin: 30px 0px;
    position: relative;
    @include md { 
        padding:15px 15px;
        margin: 20px 0px;
    }
    &:first-child{
        margin-top: 15px;
    }
    &:last-child{
        margin-bottom: 15px;
    }
    .Newstatus{
        position: absolute;
        top: -10px;
        left: 0px;
        border-radius: 50px;
        padding: 3px 15px;
        color: #FFF; 
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: #868686;
        @include md { 
            font-size: 12px;
            padding: 3px 10px;
        }
        .round{
            display: inline-flex;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: #fff;
            margin-right: 10px;
            @include md { 
                width: 8px;
                height: 8px;
            }
        }
        &.active{
            background-color: var(--green);
        }
        
    }
    .name{
        color: #000; 
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        margin-top: 5px;
        margin-bottom: 3px;
        @include md { 
            font-size: 16px;
        }
    }
   .infoText {
        color: #000; 
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        @include md { 
            font-size: 14px;
        }
    }
    .actionBtn{
        width: max-content;
        .button{
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-weight: 600;
            width: max-content;
            margin-left: auto;
            &:first-child{
                margin-bottom: 8px;
            }
            @include md { 
                height: 25px;
                line-height: 25px;
            }
        }
    }
}

.toogleSwitch{  
    margin-top: 15px;
    .item-label{
        color: #000; 
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-left: 15px;
        @include md { 
            font-size: 14px;
        }
    }
        input[type="checkbox"] {
            position: relative;
            width: 45px;
            height: 24px;
            -webkit-appearance: none;
            appearance: none;
            background: #D1D1D6;
            outline: none;
            border-radius: 2rem;
            cursor: pointer; 
            margin: 0px;
        }
        
        input[type="checkbox"]::before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
            margin: 2px;
        }
        
        input[type="checkbox"]:checked::before {
            transform: translateX(100%);
            background: #fff;
        }
        
        input[type="checkbox"]:checked {
            background: #DEA121;
        }
        
}
.tabbingBody {
.MobileInput{
    .selectArea{
    @include lg{
        top: 25px;
    }
}
}
}
.icon-input{
    display: flex;
    width: 100%;
    .input-w90{
        width: 100%; 
        .formInput {
            padding:   3px 0px; 
        } 
    }
    .deleteIcon {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 7%;
        margin: 5px 0px;
        padding-top: 15px;
    }
}