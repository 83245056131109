
.status{
    padding: 4px 15px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    @include md{ 
        font-size: 10px;
        line-height: 20px;
        padding: 3px 10px;
    }
}