// SubPortal Banner Section Start
.subPortalBanner {
    width: 100%;
    height: 150px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: var(--bannerOpacity);

    }

    @include md {
        height: 75px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .bannerInner {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0;
        bottom: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        padding: 0px 10px;

        .mainHeading {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            margin: 0;

            span {
                display: inline-block;
                width: 100%;
                font-style: italic;
                font-weight: 400;
            }

            @include md {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

// SubPortal Banner Section End

.subPortalMain {
    .bg-white-gradient {
        &.max-height{
            @include md {
                min-height:auto;
            }
        }
    }
    .sub-padder {
        padding: 24px 15px 0px;
        overflow: inherit;
        position: relative;
        min-height: calc(100vh - 300px);
        @include md {
            padding: 24px 15px 0px;
        }
    }

    .informationDetails {
        --f7-grid-gap: 0px;

        .main-Information {
            position: relative;

            .infoText {
                font-size: 14px;
                line-height: 18px;
                color: #4A4A4A;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;

                @include md {
                    font-size: 9px;
                    line-height: 14px;
                }
            }

            .themeLink {
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                position: absolute;
                right: 0;
                bottom: 0;
                background: #fff;
                padding-left: 5px;

                @include md {
                    font-size: 9px;
                    line-height: 14px;
                }
            }

            &.inactive {
                .infoText {
                    -webkit-line-clamp: inherit;
                    line-clamp: inherit;
                }
            }
        }

        .themeLink {
            &.green {
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                color: var(--green);
                padding-right: 5px;

                @include md {
                    font-size: 9px;
                }
            }
        }

    }

    .mainSearch {
        background: rgba(243, 243, 243, 0.92);
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
        padding: 15px 0px 20px 15px;
        @include md {
            padding: 20px 0px 20px 10px;
        }

        @include md {
            --f7-grid-gap: 10px;
        }

        .formInput {
            width: 96%;
            margin-bottom: 20px;
            @include md {
                margin-bottom: 15px;
            }
            
            .themeLink {
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;

                @include md {
                    font-size: 9px;
                    line-height: 14px;
                }
            }

            .item-content {
                .item-label {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    color: #4A4A4A;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    display: block;
                    width: 100%;

                    @include md {
                        font-size: 12px;
                        line-height: 18px;
                        padding-left: 5px;
                        margin-bottom: 10px;
                    }
                }

                .item-input-wrap {
                    position: relative;

                    input {
                        background: #fff;
                        border-radius: 50px;
                        padding: 5px 8rem 5px 20px;
                        height: 50px;
                        border: solid 2px #D5D4D4;
                        transition: 0.5s;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);

                        &:focus {
                            border-color: var(--green);
                        }

                        @include md {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            height: 35px;
                            padding: 5px 6rem 5px 15px;
                        }

                    }

                    .button {
                        border-radius: 50px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        height: 40px;
                        line-height: 45px;

                        @include md {
                            font-size: 12px;
                            height: 28px;
                            line-height: 28px;
                        }

                        position: absolute;
                        top: 0;
                        right: 5px;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        padding: 0px 12px;

                        i {
                            margin-left: 5px;

                            @include md {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }


        }

        .dateInput {
            .item-content {
                padding-left: 0px;
                .item-inner{
                    flex-direction: inherit;
                    align-items: center;
                }
                .item-label {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    color: #4A4A4A;
                    margin-bottom: 0px; 
                    padding-right: 15px;

                    @include md {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .item-input-wrap { 
                    margin: 0px !important;
                    display: flex;
                    align-items: center;
                    &::before{
                        content: '';
                        position: absolute;
                        background-image: url('../../image/calendar.svg');
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                    }
                    &::after{
                        display: none;
                    }
                    input {
                        cursor: pointer;
                        font-size: 14px;
                        transition: 0.5s;
                        font-weight: 500; 
                        text-decoration: underline;
                        height: auto;
                        padding: 10px 10px 10px 25px;
                        // width: 60%;
                        &:focus {
                            border-color: var(--green);
                        }

                        @include md {
                            height: 35px;
                        }

                        @include lg {
                            font-size: 11px;
                            height: auto;
                           
                        }
                    }
                }
            }
        }
        .selectCustomSearch{
            padding: 0px;
            .item-label {
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                color: #4A4A4A;
                padding-left: 10px;
                margin-bottom: 10px;
                display: block;
                width: 100%;
                @include md {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
             .smart-select{
                background: #fff;
                border-radius: 50px;  
                border: solid 2px #D5D4D4; 
                font-weight: 400;
                font-size: 16px;
                width: 100%;
                box-shadow: 0px 2px 5px rgb(0 0 0 / 12%);
                padding: 12px 30px 10px 20px;
                height: 50px;
                line-height: 35px;
               &:hover, 
               &:focus {
                    border-color:var(--green);
                }
                @include md {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    height: 35px;
                    padding: 5px 15px;
                    width: 95%;
                }
                .item-after{
                    @include lg {
                        line-height: 19px;
                    }
                }
             }
        }
    }


    .fillterMain {
        --f7-grid-gap: 5px;
        margin-top: 20px;

        @include md {
            margin-top: 15px;
        }

        .fillterLabel { 
            color: #4A4A4A;
            text-transform: inherit;
            margin-bottom: 10px;
            display: block;
            font-size: 16px;
            line-height: 18px;
            font-weight: 600; 
            @include md {
                font-size: 12px;
                line-height: 18px;
            }
        }

        .fillter {
            overflow: hidden;
            .swiper {
                width: 112%;
            }

            .swiper-slide {
                margin: 0px;

                a {
                    border-color: var(--yellowshade);

                    @include md {
                        font-size: 10px;
                        line-height: 14px;
                        padding: 5px 7px;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        background-color: rgba(var(--yellowshadeRgba), 20%);
                        color: var(--blacktone);
                    }

                    &.popular {
                        display: flex;
                        align-items: center;
                        border-color: var(--yellowshade);

                        svg {
                            fill: var(--yellowshade);
                            margin-right: 5px;
                        }

                        &:hover,
                        &:focus,
                        &.active {
                            background-color: rgba(var(--yellowshadeRgba), 20%);
                            color: var(--blacktone);
                        }
                    }
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
    }

    .screenCenter {
        @include md {
            padding: 0px;
        }
    }

    .themeCard {
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        display: flex;
        height: 170px;
        margin-bottom: 0px;

        &:not(:last-child) {
            margin-bottom: 25px;

            @include md {
                margin-bottom: 15px;
            }
        }

        @include md {
            height: 150px;
        }

        .cardImg {
            display: inline-flex;
            width: 150px;
            height: auto;
            border-radius: 20px;
        }

        .cardInfo {
            width: 70%;
            padding: 0px;
            height: 100%;

            .cardInner {
                width: 100%;
                padding: 14px 11px;
            }

            .mainInfo {
                --f7-grid-gap: 5px;

                .Heading {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    color: #4A4A4A;
                    margin: 0px;

                    @include md {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .smallText {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    color: #515151;
                    display: block;
                    margin: 1px 0px;

                    &.italic {
                        font-style: italic;
                    }

                    @include md {
                        font-size: 10px;
                        line-height: 15px;
                        margin: 1px 0px;
                    }
                }


                .label,
                .date {
                    display: block;
                    font-size: 13px;
                    line-height: 18px;
                    color: #515151;

                    @include md {
                        font-size: 9px;
                        line-height: 15px;
                    }
                }

                .label {
                    font-weight: 400;
                    text-decoration-line: underline;
                    margin-bottom: 1px;
                }

                .date {
                    font-weight: 600;
                }
            }

            .placePrice {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: var(--green);
                display: inline-block;
                width: 100%;
                margin: 5px 0px;

                @include md {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

        .mainInformations {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .detailsTextMain {
                display: flex;
                align-items: flex-end;
                width: 60%;
                position: relative;

                .detailsText {
                    font-size: 12px;
                    line-height: 18px;
                    color: #4A4A4A;
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;

                    @include md {
                        font-size: 9px;
                        line-height: 14px;
                    }

                }

                .themeLink {
                    font-size: 11px;
                    line-height: 17px;
                    font-weight: 600;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                    padding-left: 5px;

                    @include md {
                        font-size: 9px;
                        line-height: 14px;
                    }
                }
            }

            .buyBtn {
                width: 40%;
                text-align: right;

                @include md {
                    width: 35%;
                }

                .customBtn {
                    margin: 0;
                    height: 33px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 33px;
                    width: max-content;
                    padding: 0px 20px;
                    margin-left: auto;

                    @include md {
                        font-size: 11px;
                        line-height: 15px;
                        height: 23px;
                        width: auto;
                    }
                }
            }
        }

        &.inactive {
            height: auto;

            .mainInformations {
                display: inline-block;

                .detailsTextMain {
                    width: 100%;
                    display: block;

                    .detailsText {
                        -webkit-line-clamp: inherit;
                        line-clamp: inherit;
                    }

                    .themeLink {
                        position: inherit;
                        text-align: right;
                        display: block;
                    }
                }

                .buyBtn {
                    width: 100%;
                    margin-top: 5px;

                    .customBtn {
                        width: fit-content;
                    }
                }
            }
        }
    }

    .instructionMain {
        margin: 0px;
        .accordion-item-closed>.accordion-item-content{
            height: 0px !important;
        }
        .accordion-item-content .accordionBody {
            background-color: #fff;
            border-top: solid 1px #ddd;
            min-height: 640px;
            .instructionInfo{
                overflow: inherit;
            }
        }
    }

    .paymentMain {
        .listing {
            margin-top: 10px;
            margin-bottom: 10px;
            .error-message{
                width: 100%;
            }

            .selectCustomSearch,
            .formInput {
                margin: 3px 0px;

                @include lg {
                    .smart-select {
                        height: 30px;
                    }
                }
            }
        }
    }

    .status-link {
        text-align: right;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        &.available{
            color: #4E9800;
        }
        &.limited{
            color: #ED790E;
        }
        &.soldout{
            color: #FF2D00;
        }
        &.notavailable{
            color: #868686;
        }
        @include md {
            font-size: 12px;
            line-height: 15px;
        }
    }

}

.addCartBtn {
    margin-top: 17px !important;
    align-items: center;
    .mainPriceListing{
        display: flex;
        align-items: center;
        .link{
            padding: 5px 10px 5px 0px;
            z-index: inherit;
        } 
    }
    .button {
        margin-left: auto;
        min-width: auto;
        padding: 5px 15px;
        font-size: 14px;
        line-height: inherit;
        white-space: normal;
        word-break: break-word;
        height: auto;
        min-height: 32px;
        line-height: 1.2;
        @include md {
            font-size: 12px;
            // max-width: max-content;
            // min-width: 120px;
            font-weight: 600;
        }
        @include xs{
            font-size: 11px;
            min-width: unset;
        }
    }
}

.ImageCard {
    display: flex;

    .Cardimage {
        width: 50px;
        overflow: hidden;
        border-radius: 14px 0px 0px 14px;
        display: flex;
        align-items: center;
        justify-content: center;  
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        padding: 10px 0px; 
        position: relative;
        .text{
                writing-mode: vertical-rl; 
                transform: rotate(-180deg);
                position: absolute;
                left: 0px;
                right: 0px; 
                top: 0px;
                bottom: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 0;
                text-align: center;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .cardDetails {
        width: 89%;

        @include md {
            width: 86%;
        }

        .parkName {
            strong {
                display: block;
            }
        }
    }

    .w-50 {
        width: 50% !important;
    }

    &.card {
        .card-header {
            .accordion-item-toggle {
                display: flex;
                padding: 10px 14px;

                .mainHeading {
                    &::after {
                        position: inherit;
                        line-height: 0;
                        margin-left: 10px;
                        font-size: 22px;
                    }

                    .title {
                        margin: 0;
                        font-size: 14px;
                        line-height: 20px;
                        @include md {
                        font-size: 12px;
                    }
                    }
                }

            }
        }

    }
}

.subPortalMain {
    .ImageCard {
        &.card {
            .accordion-item-content {
                .accordionBody {
                    padding: 10px 0px 12px 12px;
                }
            }
        }
    }
}

// Bottom Shopping Card Start
//   .instructionMaindisbled{ 
//     position: relative;
//     &::after{
//         content: "";
//         position: absolute;
//         left: 0px;
//         top: 0px;
//         width: 100%;
//         height: 100vh; 
//         background: rgba(0, 0, 0, 0.5); 
//         z-index: 11111;        
//     } 
//     .view{
//         position: initial;
//     }
//     .page,
//     .page-content{
//         position: inherit;
//         overflow: hidden;
//     }
//     .subPortalMain .instructionMain { 
//         z-index: 22222;
//         position: relative;
//     }
// }


.shoppigCardMain {
    .subPortalMain {
        position: relative;

        // @include md {
        //     &::after {
        //         content: '';
        //         position: absolute;
        //         top: 0px;
        //         left: 0px;
        //         background-color: rgba(0, 0, 0, 0.50);
        //         width: 100%;
        //         height: 100%;
        //         z-index: 1111;
        //     }
        // }
    }
}
.sticky {
    position: sticky;
    bottom: 0px;
    z-index: 2;
    padding: 0px !important;
    &.demo{
        position: relative;
        min-height: calc(100vh - 225px);
        .shoppingCard {
            border-radius: 0px;
        }
    }
}
.shoppingCard {
    // position: absolute;
    // left: 0px;
    // bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 5px 20px 0px;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    z-index: 1112;
    transition: transform 0.3s ease-in;
    @include md {
        padding: 5px 15px 0px;
    }
    @media only screen and (min-width: 200px) and (max-width: 375px){
        padding: 5px 5px 0px;
    }
    &.inactive {
        height: 85px;
    }

    &.active {
        height: 100%;
        min-height: calc(100vh - 225px);
        .icon {
            transform: rotate(-180deg);
        }

        .shoppingMainList {
            display: block;
            opacity: 1;
        }
    }

    .icon {
        transition: 0.3s;
    }

    .topMainButton {
        display: flex;
        justify-content: space-between; 
        align-items: center;
        cursor: pointer;
        .cartDigit {
            display: flex;
            align-items: center;
            .link{
                padding:35px 15px 25px 15px;
            }
            .icon{
                position: absolute;
                top: 0;
            }
            .counter {
                position: relative;

                .count {
                    position: absolute;
                    top: -10px;
                    right: -12px;
                    width: 16px;
                    height: 16px;
                    background-color: var(--green);
                    border-radius: 50%;
                    font-size: 12px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include md {
                        width: 14px;
                        height: 14px;
                        font-size: 10px;
                    }
                }
            }

            .smallText {
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                color: #7C7C7C;
                padding-left: 18px;

                @include md {
                    padding-left: 10px;
                    font-size: 12px;
                }
            }
        }

        .button {
            margin: 0;
            height: 33px;
            font-weight: 500;
            font-size: 14px;
            line-height: 33px;
            padding: 0px 20px;
            min-width: 150px;
            @include sm{
                white-space: normal;
                font-size: 12px;
                line-height: 12px;
                height: 30px;
                min-width: 100px;
                padding: 0px 10px;
            }
            @include bp-350{
                min-width: 93px;                
            }
            i{
                font-size: 18px;
                font-weight: 600; 
                margin-right: 5px;
                line-height: 16px;
                @include md {
                    font-size: 14px; 
                    line-height: 14px;
                }
            }
        }
    }

    .shoppingMainList {
        padding: 15px;
        background-color: #ECEBEB;
        // max-height: 350px;
        // max-height: 62.5vh;
        // height: calc(100% - 77px);
        overflow: auto;
        min-height: calc(100% - 76px);
        transition: opacity .15s linear;
        display: none;
        opacity: 0;

        .shoppingList {
            display: flex;
            width: 100%;
            background: #FFFFFF;             
            padding: 23px 15px;
            position: relative; 
            border-bottom: 2px solid rgba(215, 215, 215,0.21);
            &:last-child{
                border-bottom:0px;
            }
            .customBtn {
                margin: 0px; 
                font-size: 15px; 
                text-transform: uppercase;
                display: inline-flex; 
                font-weight: 600;
                height: 35px;
                padding: 0px 25px;
                svg {
                    margin-right: 10px;
                    @include md {
                        height: 20px !important;
                        margin-right: 8px;
                        width: 12px !important; 
                    }
                }
                @include md {
                    font-size: 12px;
                    line-height: 12px;
                    height: 32px;
                    padding: 0px 10px;
                }
            }

            .shoppingListmainInfo {
                --f7-grid-gap: 5px;
                align-items: center;
                .CardListing{
                    margin-bottom: 15px;
                }
                .Heading {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 33px;
                    color: #000;
                    margin: 0px;

                    @include md {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }

                .smallText { 
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #515151;
                    display: block;  
                    &.italic {
                        font-style: italic;
                    } 
                    @include md {  
                        font-size: 10px;
                        line-height: 15px;
                    }
                }

                .label {
                    display: block;
                    font-size: 13px;
                    line-height: 18px;
                    color: #515151;

                    @include md {
                        font-size: 9px;
                        line-height: 15px;
                    }
                }

                .quality { 
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 27px;
                    color: #000;
                    display: block;
                    width: 100%;
                    margin: 0px;

                    @include md { 
                        font-size: 11px;
                        line-height: 13px;
                    }
                }

                .placePrice {
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 39px;
                    color: var(--green);
                    display: block;
                    width: 100%;
                    margin: 0px;

                    @include md {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .license {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #515151;
                    display: block;
                    width: 100%;
                    margin: 0px;
                    font-style: italic;

                    @include md {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .dateDetails{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px; 
                    color: #000000; 
                    background: rgba(0, 129, 87, 0.36);
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                    border-radius: 13px;
                    padding: 8px 15px;
                    @include md { 
                        font-size: 9px;
                        line-height: 14px;
                        padding: 5px 15px;
                    }
                }
            }
        }
        .totalPriceAmount{
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            @include md {
                margin-top: 10px;
            }
            .Innerlisting{
                display: flex;
                justify-content: space-between;
                padding: 10px ; 
                @include md {
                    padding:8px 10px ; 
                }
                .labelText{ 
                    width: 85%;
                    text-align: right;
                    @include md {
                        width: 75%;
                    }
                }
                .labelMain{
                    width: 15%;
                    @include md {
                        width: 25%;
                    }
                }
                .labelText,
                .labelMain{  
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    @include md {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                .labelText{
                    font-weight: 500;
                    color: #000000;
                    display: flex;
                    justify-content: flex-start;
                    width: 35%;
                    margin-left: auto;

                    @include md {
                        width: 55%;
                    }
                }
                .labelMain{
                    font-weight: 400; 
                    color: #4A4A4A;
                    text-align: right;
                }
                &.totalPrice{
                    border-top: solid 3px #ddd;   
                    margin-top: 5px;
                    // border-bottom: solid 3px #ddd;   
                    background-color: #F2F2F2;

                    .labelText,
                    .labelMain{ 
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--themeBtn);
                        @include md {
                        font-size: 12px;
                        line-height: 18px;
                        }
                    } 
                    .labelMain{ 
                        color: var(--themeColor);
                    }
                }
               
            }
        }
    }

}

.commanModal.message-alert-Model {
    .card-body {
        min-height: auto;
    }
}

.commanModal.commanModal {
    .cardHeader {
        padding-top: 15px;

        .smallHeading {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            margin-top: 10px;

            @include md {
                font-size: 12px;
            }
        }
    }

    .cardfooter {
        padding: 5px 0px 50px 0px;

        .theme-outline-green {
            margin: 15px 0px 0px;
            // width: max-content;
        }
    }
    .payAccordionList{        
        width: 100%;
        margin: 0;
        max-height: 57vh;
        overflow-y: auto;
        padding: 0 0 15px;
        @include sm{
            padding: 0;
        }
        &::-webkit-scrollbar{
            display: none;
        }
        span{
            &.radioButton {
                position: relative;
                border: 1.5px solid var(--gray);
                min-width: 18px;
                height: 18px;
                border-radius: 50%;
                display: inline-block;
                @include md {
                    min-width: 15px;
                    height: 15px;
                }
            }
        }        
        .payTitle{
            display: inline-block;
            font-weight: 500;
            margin-bottom: 15px;
            font-size: 17px;
            @include sm{
                font-size: 15px;
                margin: 23px 0 9px;
            }
        }
        .accordion-item{
            .accordionTitle{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 15px;
            }
            .upperaccord{
                .accordion-item-content{
                    margin-left: 48px;
                }
                .accordion-item-inner {                    
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    .item-link{
                        &.item-content{
                            display: flex;
                            align-items: center;
                            width: 100%;
                        }
                    }
                    .item-title{
                        span{
                            display: flex;
                            vertical-align: middle;
                            margin: 0 19px 0 16px;
                            @include md{
                                margin: 0 10px;
                            }
                            img{
                                width: 32px;
                                height: auto;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
            &.ExistingPay{                
                .cvvText {
                    display: flex;
                    align-items: center;
                    margin-top: 7px;
                    input {
                        font-size: 14px;
                        border: 2px solid var(--yellow92percentshade);
                        border-radius: 6px;
                        padding: 0 9px;
                        margin-right: 12px;
                        background: var(--white);
                        width: 118px;
                        height: 35px;
                    }
                    a {
                        white-space: var(--f7-block-title-white-space);
                        text-decoration: underline;
                        color: var(--themeColor);
                    }
                    ~ span{
                        &.label {
                            font-size: 14px;
                            line-height: 21px;
                            margin: 4px 0 7px;
                            display: inline-block;
                        }
                    }
                }
                .upperaccord {
                    .accordion-item-inner{
                        border-radius: 10px 10px 0 0;     
                    }
                }                 
                .accordion-item-content{
                    margin-left: 48px;
                }
                &.accordion-item-opened{  
                    background-color: var(--yellowopaceshade); 
                    border: 1px solid var(--greyShadeE7);   
                    border-radius: 10px 10px 0 0;                
                    span{
                        &.radioButton{
                            border-color: var(--black);
                            &::before {
                                position: absolute;
                                content: "";
                                left: 2px;
                                right: 2px;
                                top: 2px;
                                bottom: 2px;
                                background: var(--black);
                                border-radius: 50%;
                            }
                        }
                    }
                    .upperaccord {
                        .accordion-item-inner{
                            border-radius: 10px 10px 0 0; 
                            border: 0;     
                        }                        
                        .accordion-item-content{
                            position: relative;
                            left: 48px;
                            .cvvText {
                                display: flex;
                                align-items: center;
                                input {
                                    font-size: 14px;
                                    border: 2px solid var(--yellow92percentshade);
                                    border-radius: 6px;
                                    padding: 0 9px;
                                    margin-right: 12px;
                                    background: var(--white);
                                    width: 118px;
                                    height: 35px;
                                }
                                a {
                                    white-space: var(--f7-block-title-white-space);
                                    text-decoration: underline;
                                    color: var(--themeColor);
                                }
                            }
                            
                            span{
                                &.label {
                                    font-size: 14px;
                                    line-height: 21px;
                                    margin: 4px 0 7px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
            &.MethodsPay{
                border: 1px solid var(--greyShadeE7);
                border-bottom-width: 0;
                &:first-child{
                    border-radius: 10px 10px 0 0;
                }
                &:last-child{
                    border-bottom-width: 1px;
                    border-radius: 0 0 10px 10px;
                }                
                .MethodsPayInner{
                    &.item-link{
                        &.item-content{
                            border-radius: 0 0 10px 10px;
                            border: 1px solid var(--greyShadeE7);
                            border-top: 0;
                        }
                    }
                }
                &.accordion-item-opened{
                    background: var(--yellowopaceshade);
                    .MethodsPayInner{
                        display: none;
                    }
                    span{
                        &.radioButton {
                            border-color: var(--black);
                            &::before{
                                position: absolute;
                                content: "";
                                left: 2px;
                                right: 2px;
                                top: 2px;
                                bottom: 2px;
                                background: var(--black);
                                border-radius: 50%;
                            }
                        }
                    }
                }
                .accordion-item-content{
                    margin-left: 48px;
                }
                &.OnePayView{
                    border-radius: 10px 10px 0 0;
                }
            }
            &.SeeAllExisting{
                border-radius: 0 0 10px 10px;
                overflow: hidden;
                .item-link{
                    &.item-content {
                        &.MethodsPayInner{                        
                            padding: 15px;
                            background: rgba(0, 0, 0, 0.0509803922);
                            text-align: center;  
                        }             
                        span{
                            &.payMethods {
                                font-size: 14px;
                                line-height: 21px;
                                margin-right: 8px;
                                color: var(--grey51);
                            }
                        }
                    }
                }
                &.accordion-item-opened{
                    .item-link{
                        &.item-content{
                            &.MethodsPayInner{
                                display: none;
                            }
                        }
                    }
                }
            }
            .item-title {
                font-size: 14px;
                line-height: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                @include sm{
                    line-height: 19px;
                }
            }
            .item-title-inner{
                display: flex;
                align-items: center;
                color: var(--black); 
                @include sm{
                    font-size: 14px;
                    line-height: 1.2;
                }
                .tilteOfCard{
                    display: inline-block;
                }
                span{
                    display:flex;
                    align-items: center;
                    margin: 0 15px;
                    @include sm{
                        margin: 0 10px;
                    }
                    &.no-translate{
                        float: left;
                    }
                }               
                svg{
                    width: 38px;
                    height: auto;
                }
            }
            .cvvText{
                display: flex;
                margin-top: 7px;
                flex-direction: column;
                iframe{
                    height: 38px;
                    width: 118px;
                    margin-right: 12px;
                }
                .cvvIframe{
                    display: flex;
                    width: 100%;
                    align-items: center;                    
                }
                input{
                    font-size: 14px;
                    border: 2px solid var(--yellow92percentshade);
                    border-radius: 6px;
                    padding: 0 9px;
                    margin-right: 12px;
                    background: var(--white);
                    width: 118px;
                    height: 35px;
                    &::placeholder{
                        color: var(--grayB9);
                    }
                }
                a{
                    white-space: var(--f7-block-title-white-space);
                    text-decoration: underline;
                    color: var(--themeColor);
                }
            }
            span{
                &.label{
                    font-size: 14px;
                    line-height: 21px;
                    margin: 4px 0 7px;
                    display: inline-block;
                }
            }
            .button{
                &.themeBtnGrey {
                    background-color: var(--themeBtnGrey);
                    color: var(--themeBtnGreyColor);
                    height: 24px;
                    font-size: 12px;
                    min-width: 60px;
                    margin-left: 10px;
                }
            }      
            .accordion-list{
                ul{
                    border: 1px solid var(--greyShadeE7);
                    border-radius: 10px;
                    overflow: hidden;
                    &:before, &::after{
                        content: none; 
                    }                
                    li{
                        &.accordion-item {
                            border-bottom: 1px solid var(--greyShadeE7);
                            display: flex;
                            align-items: baseline;
                            &:last-child{
                                border-bottom: 0;
                            }                            
                            .accordion-item-content{
                                margin-left: 48px;
                            }
                            .accordion-item-inner{
                                width: 100%;
                            }
                            .item-title-inner{
                                margin-left: 14px;
                                white-space: normal;
                                text-align: left;
                                @include sm{
                                    margin-left: 10px;
                                }
                            }
                            a{
                                &.item-link {
                                    padding: 0;
                                    min-height: unset;
                                    background: unset;
                                    width: 100%;
                                    .item-inner{
                                        padding: 0;
                                        height: unset;
                                        min-height: unset;
                                        &:before, &:after{
                                            content: none;
                                        }        
                                        span {
                                            display: flex;
                                            vertical-align: middle;
                                            margin-right: 10px;
                                            img {
                                                width: 32px;
                                                height: auto;
                                                vertical-align: middle;
                                                @include md {
                                                    width: 30px;
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                            
                            &.accordion-item-opened{
                                background-color: var(--yellowopaceshade);
                                span{
                                    &.radioButton {
                                        border-color: var(--black);
                                        &::before{
                                            position: absolute;
                                            content: "";
                                            left: 2px;
                                            right: 2px;
                                            top: 2px;
                                            bottom: 2px;
                                            background: var(--black);
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.newPayMethod{
                .upperaccord{
                    .accordion-item-inner{
                        border: 1px solid var(--greyShadeE7);
                        border-radius: 10px;
                    }
                }
                &.accordion-item-opened{                    
                    .accordion-item-content{
                        border-bottom: 0;
                        padding: 15px 37px;
                        background: var(--yellowopaceshade);
                        border-radius: 0 0 10px 10px;
                        border: 1px solid var(--greyShadeE7);
                        @include md{
                            padding: 15px;
                        }
                        @include sm{
                            padding: 10px 15px;
                        }
                        .accordion-item-inner{
                            margin-left: 0;
                        }
                        .cardPayment{
                            @include md{
                                padding: 5px 0px;
                            }
                            input{
                                border: 1.5px solid var(--grayB6);
                                height: 32px;
                                background: var(--white);
                                padding: 0 12px;
                                font-size: 14px;
                                font-weight: 400;
                                &::placeholder{
                                    text-transform: capitalize;
                                }
                            }
                            svg{
                                &.cardAll{
                                    width: 30px;
                                }
                            }
                        }
                        .zipCodeEngima{
                            width: 38%;
                            position: absolute;
                            bottom: 72px;
                            right: 37px;                            
                            font-family: sans-serif;
                            @include md{
                                width: 44%;
                                right: 15px;
                            }
                            @include sm{
                                bottom: 68px;
                                width: 40%;
                            }
                            @include xs{
                                width: 35%;
                            }
                            span{
                                &.label {
                                    position: relative;
                                    height: unset;
                                    left: 0;
                                    font-size: 14px;
                                    line-height: 21px;
                                    margin: 0 0 5px;
                                    padding: 0 8px 0 4px;
                                    width: max-content;
                                    display: inline-block;
                                    &::before{
                                        position: absolute;
                                        content: "*";
                                        right: 0;
                                    }
                                }
                            }
                            input{
                                font-size: 14px;
                                border-radius: 6px;
                                padding: 5px 30px 5px 15px;
                                height: 38px;
                                border: solid 2px var(--gray86);
                                transition: 0.5s;
                                font-weight: 500;
                                width: 100%;
                                margin: 0 0 16px;
                                background: var(--white);
                                &.disable {
                                    cursor: not-allowed;
                                    background-color: var(--greydisable);
                                    color: var(--greyDisablecolor);
                                }
                                &:focus{                    
                                    &.invalid{
                                        border-color: var(--engimaErrorborder);
                                        color: var(--engimaErrorcolor);
                                    }
                                }
                                @include xs{
                                    padding: 5px 9px 5px 15px;
                                }
                                &::placeholder{
                                    color: var(--grey83);
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
                .item-link{
                    display: inline-block;
                    width: 100%;
                }
                .item-inner {
                    span{
                        &.radioButton{
                            top: 0;
                        }
                        &.newCardTitle{
                            margin-left: 12px;
                            color: var(--black);
                        }
                    }
                    &.newCard{
                        display: flex;
                        align-items: center;
                    }
                }
                .item-checkbox{
                    padding-left: 0;
                    background-color: unset;
                    .item-inner{                        
                        padding-left: 8px;
                        &::after{
                            content: none;
                        }
                    }
                    // span{
                    //     min-width: 16px;
                    //     height: 16px;
                    //     background-color: var(--white);
                    //     border: 1px solid #868686;
                    //     border-radius: 2px;
                    //     position: relative;
                    //     overflow: visible;
                    //     &::before{
                    //         position: absolute;
                    //         content: "";
                    //         background: url(../../image/checked.svg);
                    //         width: 15px;
                    //         height: 17px;
                    //         top: -3px;
                    //         overflow: visible;
                    //         background-size: 100%;
                    //         background-repeat: no-repeat;
                    //         left: 3px;
                    //         display: none;
                    //     }
                    // }
                    // input{
                    //     &:checked{
                    //         ~span{
                    //             border-color: #008453;
                    //             &::before{
                    //                 display: block;
                    //             }
                    //         }
                    //     }
                    // }
                }
                &.accordion-item-opened{
                    .item-inner {
                        &.newCard{
                            border-radius: 10px 10px 0 0;
                        }
                        span{
                            &.radioButton{
                                top: 0;
                                border-color:var(--black);
                                &::before {
                                    position: absolute;
                                    content: "";
                                    left: 2px;
                                    right: 2px;
                                    top: 2px;
                                    bottom: 2px;
                                    background: var(--black);
                                    border-radius: 50%;
                                }
                            }
                            &.newCardTitle{
                                margin-left: 12px;
                            }
                        }
                    }
                    .upperaccord {
                        .accordion-item-inner{
                            border-radius: 10px 10px 0 0;
                        }
                    }
                }
            }          
        }
        iframe{
            overflow: hidden!important;
            width: 100%;
            border: none;
            &.addnewCardEngima{                
                height: 370px !important;
                min-height: 370px !important;
            }
        }
        .MethodsPayOuter{
            max-height: 203px;
            overflow: auto;
            &::-webkit-scrollbar{
                width: 5px;
                height: 5px;
                &-thumb{
                    background: hsla(0, 0%, 100%, .3);
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
                }
                &-track{
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                }
            }
            &.OuterwithoutPay{
                .accordion-item{
                    &.MethodsPay{
                        &:only-child{
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
    .PayCardsAccepted{
        &.CardsAccepted {
            flex-direction: column;
            margin-top: 30px;
            span{
                &.smallText{
                    margin-bottom: 11px;
                    font-size: 14px;
                    margin-right: 0;
                }
            }
        }
    }
    .WidthManagement{
        width: 450px;
        margin: 0 auto;
        border-radius: 20px;
        background-color: var(--white);
        @include md{
            width: 100%;
            max-width: 450px;
        }
    }   
    &.CvvModal{
        background: unset;
        @media screen and (min-width:837px) and (max-width:1024px) {
            margin: auto;
        }
        .cardHeader{
            padding-top: 27px;     
            h2{
                &.heading{
                    font-size: 28px;
                    font-size: 28px;
                    @include sm{
                        font-size: 22px;
                        line-height: 24px;    
                    }  
                }
            }  
        }
        .card-body{
            min-height: unset;
            margin-top: 10px;
            p{
                font-size: 19px;
                line-height: 28px;
                font-weight: 500;
                text-align: center;
                margin: 10px 0 25px;
                color: var(--darkgrey);
                @include md{
                    font-size: 17px;
                    line-height: 24px;
                }
            }
            img{
                width: 195px;
            }
        }
        .card-footer{
            padding-bottom: 32px;
            &::before{
                content: none;
            }
            .themeBtn{
                max-width: 170px;
                margin: 0 auto;
                font-size: 17px;
                min-width: 170px;
            }
        }
    }
    &.selectCart {
        @media screen and (min-width:837px) and (max-width:1024px) {
            margin: auto;
        }
        .cardHeader {
            @include lg{
                padding-top: 0;
            }  
            .heading {
                font-size: 28px;
                @include lg{
                    font-size: 22px;
                    line-height: 24px;    
                }  
                @include md{
                    font-size: 20px;
                }
                @include xs{
                    font-size: 19px;   
                }              
            }
            .smallHeading{
                @include sm{
                    font-size: 13px;
                    line-height: 15.5px;
                }                
            }
        }
        .card-body {
            @include sm{
                padding: 25px 0px 10px;
                margin: 0 auto;
            }
        }
        &.popup{
            &.popup-behind {
                z-index: 5000;
            }
        }
        .cardfooter{
            .CardsAccepted{
                ul{
                    li{
                        width: max-content;
                        svg{
                            width: 40px;
                            height: auto;
                            @include md{
                                width: 38px;
                            }
                            @include sm{
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.AreyousureModal{
        .cardHeader{
            padding-top: 15px;
        }
        ul{
            li{
                display: flex;
                align-items: center;
                @include sm{
                    font-size: 14px;
                }
                svg{
                    margin-right: 10px;
                    width: 42px;
                    height: auto;
                    @include sm{
                        width: 35px;
                    }
                }
            }
        }
        .card-body {
            margin-bottom: 35px;
        }
    }
}
.selectCart,
.addCard{
    &.commanModal.commanModal .cardfooter {
        padding-bottom:  30px ;
    }
    .themeBtn  {
    .icons{
        margin-right: 10px;
        margin-bottom: 1px;
        width: 18px;
        height: 18px;
    }
}
    .CardsAccepted{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin-top: 40px;
   @media only screen and (min-width: 200px) and (max-width: 375px){
            margin-top: 20px;
        }
        .smallText{
            font-weight: 400;
            font-size: 14px;
   
           // text-decoration-line: underline; 
            color: #000000;
            margin-right: 10px;
            @include md {
                font-size: 10px;
                line-height: 15px; 
            }
        }
        .cardList{
            display: flex;
            align-items: center;
            li{
                width: 40px; 
                margin: 0px 5px;
                display: inline-flex;
                align-items: center;
                img{
                    max-width: 100%;
                }  
                @include md {
                    width: 32px;
                }              
            }
        }
    }
}
.cardLoader{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 60px;
    @include md {
        top: 50px;
    }
}
 