 .button{
 cursor: pointer;
 display: flex;
    &.themeBtn{
        background-color: var(--themeBtn);
        color: var(--themeBtnColor);
        &:hover,&:focus{
            background-color: var(--themeBtnHover);
        }
     }
     &.themeBtnYellow{
        background-color: var(--themeBtnYellow);
        color: var(--themeBtnYellowColor);
        &:hover,&:focus{
            background-color: var(--themeBtnYellowHover);
        }
     }
     &.theme-outline-yellow{
        border-color:var(--themeBtnYellow);
        color: #000;
        &:hover,&:focus{
            background-color: rgba(var(--themeBtnYellowHover), 0.2);
        }
     }
     &.theme-outline-grey{
        border-color: #868686;
        color: #000;
        &:hover,&:focus{
            background-color:transparent;
        }
     }
     &.themeBtnGrey{
        background-color: var(--themeBtnGrey);
        color: var(--themeBtnGreyColor);
        &:hover,&:focus{
            background-color: var(--themeBtnGreyHover);
        }
     }
     &.theme-outline-green{
        border-color:var(--themeBtn);
        color: var(--themeBtn);
        &:hover,&:focus{
            background-color: rgba(var(--themeBtnHoverRgba), 0.2);
        }
     }
     &.btn-disbled{
        background-color: #D5D4D4;
        color:#868686;
        cursor: not-allowed;
        &:hover,&:focus{
            background-color: #D5D4D4;
        }
     }
     &.btn-dark{
        background-color:#4A4A4A;
        color: #fff;
        &:hover,&:focus{
            background-color:#4A4A4A;
        }
     }
     &.btn-outline-grey{
        background-color: #fff;
        color: #3C4043;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        &:hover,&:focus{
            background-color: #fff;
        }
     }
     &.btn-Pending{
        background-color: #ED790E;
        color: #fff;
        &:hover,&:focus{
            background-color: #d16704;
        }
     }
     &.btn-Resubmit{
        background-color: #B56923;
        color: #fff;
        &:hover,&:focus{
            background-color: #965010;
        }
     }
     &.btn-Rejected{
        background-color: #AD0000;
        color: #fff;
        &:hover,&:focus{
            background-color: #960000;
        }
     }
     &.btn-greySecondary{
        background: #BCBCBC;
        color: #4A4A4A;
        &:hover,&:focus{
            background: #BCBCBC;
           color: #4A4A4A;
        }
     }
     &.theme-outline-white{
      border-color:#fff;
      color: #fff;
      &:hover,&:focus{
          background-color: #fff;
          color: var(--themeBtn);
      }
   }
 }
 .grey{
    --f7-button-border-color: #999;
    color: #444 !important;
 }