.formInput {
    margin: 0;
     ul {

         &:after,
         &:before {
             display: none;
         }
         li{
            list-style: none;
         }
     }
     .item-input{
        
     .item-content {
        padding-left: 0px;

        .item-label {
            font-size: 14px;
            color: var(--black);
            font-weight: 500;
            margin-bottom: 3px;
            @include md{
               font-size: 13px;
               margin-bottom: 3px; 
               line-height: 18px;
           }
        }

        .item-input-wrap {
            input {
               font-size: 14px;
                background: #fff;
                border-radius: 6px;
                padding: 5px 15px 5px 15px;
                height: 38px;
                border: solid 2px  #868686;;
                transition: 0.5s;
                font-weight: 500;
                width: 100%;
                &.disabled{
                   background: #E5E5E5; 
                   opacity: 1;
                   border-color:#E5E5E5 ;
                }
                @include lg{
                   font-size: 12px;
                   height: 34px;
                   text-overflow: ellipsis;
               }
            } 
            &::after{ 
               opacity: 0;
            }
            .input-clear-button{
               top: 18px;
               right: 10px;
               @include lg{
                   top: 16px;
               }
            }
        }

    }
    
    &.item-input-focused {
        .item-label {
            color: #000;
        }

        .item-input-wrap {
            input {
                background: #fff;
                border-color: var(--yellow);
            }

        }
    }
    &.item-input-error {
       .item-label {
           color: var(--danger);
       }

       .item-input-wrap {
           input {
               background: #fff;
               border-color: var(--danger);
           }

       }
   }
     }
     &.withicon{
        .item-content {
        .item-input-wrap{
            .input-clear-button{
                top: 20px;
                right: 35px;
             }
            &::after{ 
                position: absolute;
                opacity: 1;
                width: 15px;
                height: 15px;
                right: 15px;
                top: 7px;
                left: inherit;
                font-family: "framework7 icons";
                font-size: calc(var(--f7-input-clear-button-size)/ (24 / 20));
                content: 'info_circle';
                background-color: transparent;
                line-height: 1.2;
                color: var(--blacktone);
                font-size: 22px;
                font-weight: 600;
                transform:inherit;
                @include md {
                    top: 5px;
                    font-size: 20px;
                }
            }
        }
        &.item-input-focused {
            .item-input-wrap{
                &::after{
                    transform: scaleY(1)!important;
                }
            }
        }
    }
     } 
     .error-message{
        display: none;
     }
     &.errorInput{
        .item-content { 
            .item-label { 
                color: #c03515; 
            }
   
            .item-input-wrap {
                input { 
                    border-color: #c03515; 
                    background-color: rgba($color: #c03515, $alpha: 0.04); 
                }  
            } 
        }
       
     }
    
&.disabled{
    opacity: 1 !important;
    .item-content { 
        .item-input-wrap {
            input {
                background: #E5E5E5; 
                opacity: 1;
                border-color:#E5E5E5 ;
                color: #515151;
            }
        }
    }
}
 }
 li{
    list-style:  none ;
}
 .ios  .formInput .item-label+.item-input-wrap {
    margin-top: 0;
    margin-bottom: 0;
}
 .error-message{
    display: inline-block;
    font-size: 16px;
    color: #c03515;  
    display: flex;
    align-items: center;
    font-weight: 500;
    i{
        font-size: 22px;
        margin-right: 5px;
    }
    @include md{
        font-size: 12px;
        line-height: 15px;
        align-items: flex-start;
        i{
            font-size: 16px;
        }
    }
}
.selectCustomSearch{
    padding: 10px 0px;
    .item-label {
        font-size: 14px;
        line-height: 18px;
        color: var(--black);
        font-weight: 500;
        margin-bottom: 3px;
        @include md{
            font-size: 13px;
    margin-bottom: 3px;
    line-height: 18px;
       }
    }
.select-search-container{
    width: 100%;
 
    .select-search-value{
        position: relative;
    &::after{
        content: "chevron_down";
        position: absolute;
        right: 0px;
        top: 0;
        z-index: 5;
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: framework7 icons;
        font-size: 14px;
        font-weight: 600;
        line-height: 40px; 
    }
}
    .select-search-input {
        font-size: 14px;
        background: #fff;
        border-radius: 6px;
        padding: 5px 30px 5px 10px;
        height: 38px;
        border: solid 2px  #868686;;
        transition: 0.5s;
        font-weight: 500;
        cursor: pointer;
        &.input-focused{
            background: #fff;
            border-color: var(--yellow);
        }
        @include md{
            font-size: 12px;
            height: 34px;
       }
    } 
    &.select-search-has-focus{
        .select-search-input {
            &.input-focused{
                background: #fff;
                border-color: var(--yellow);
            }
        }
    }
  .select-search-select { 
        max-height: 260px;
        box-shadow: 0px 5px 9px 2px rgb(0 0 0 / 15%);
        border: 0;
        .select-search-option,
        .select-search-not-found { 
            height: 25px;
            font-size: 14px;
            padding: 0px 10px; 
            @include md{
                font-size: 12px;
                padding: 0px 10px;
           }
        }

        .select-search-is-highlighted,
        .select-search-option:not(.select-search-is-selected):hover {
            background: #E9E9E9;
            color: #000;
        }

        .select-search-is-selected {
            color: #000;
        }
    }
    
}
&.errorInput{
    .item-label{
        color: #c03515; 
    }
    .smart-select{ 
        border-color:   #c03515;
    }
        .select-search-container {
            .select-search-input { 
                border-color: #c03515; 
                background-color: rgba($color: #c03515, $alpha: 0.04); 
            }  
        } 
}
.select-search-container:not(.select-search-is-disabled).select-search-has-focus 
.select-search-input, 
.select-search-container:not(.select-search-is-disabled) 
.select-search-input:hover {
    border-color: var(--yellow);
}
.select-search-container:not(.select-search-is-multiple) .select-search-select{
    top: 38px;
    border: 0;
    z-index: 55;
}
}
.smartSelectCustom{
    .smart-select{
        display: inline-block;
        width: 100%;
        font-size: 14px;
        background: #fff;
        border-radius: 6px;
        padding: 5px 30px 5px 10px;
        height: 38px;
        border: solid 2px  #868686;
        transition: 0.5s;
        font-weight: 500;
        cursor: pointer; 
        position: relative;
        @include lg{
            font-size: 12px;
            height: 34px; 
       }
     &:hover,&:focus{
        border-color: var(--yellow);
     }
       &::after{
           content: "chevron_down";
           position: absolute;
           right: 0px;
           top: 0;
           z-index: 2;
           width: 35px;
           height: 100%;
           display: flex;
           justify-content: center;
           align-items: center;
           cursor: pointer;
           font-family: framework7 icons;
           font-size: 14px;
           font-weight: 600;
           line-height: 40px; 
           color: #000;
       }
        .item-title{
            display: none;
        }
        .item-after{
            max-width: 100%;
            color: #868686;
            line-height: 23px;
            white-space: nowrap;
            @include lg{
                line-height: 18px;
           }
        }
        &.input-focused{
            background: #fff;
            border-color: var(--yellow);
        }
    }
    &.disabled{
        opacity: 1 !important;
        .smart-select {
            background: #E5E5E5;
            opacity: 1;
            border-color: #E5E5E5;
            color: #515151;
            &::after {
                color: #a8a8a8;
            }
        }
    } 
}
.cardPayment{
    padding: 8px 0px; 
    .item-label {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        color: var(--black);
        font-weight: 500;
        margin-bottom: 5px;
        padding-left: 4px;
        @include md{ 
           font-size: 14px; 
           line-height: 21px;
       
      }
    }
     input{
         font-size: 14px;
                  background: #fff;
                  border-radius: 6px;
                  padding: 5px 30px 5px 15px;
                  height: 38px;
                  border: solid 2px  #868686;;
                  transition: 0.5s;
                  font-weight: 500;
                  width: 100%;
                  &.disabled{
                     background: #E5E5E5; 
                     opacity: 1;
                     border-color:#E5E5E5 ;
                  }
                  @include lg{
                     font-size: 12px;
                     height: 34px;
                 }
             &:focus{
                 background: #fff;
                 border-color: var(--yellow);
             }
     }
     .error-message{
        padding-top: 8px;
     }
     &.errorInput{ 
            .item-label { 
                color: #c03515; 
            } 
                input { 
                    border-color: #c03515; 
                    background-color: rgba($color: #c03515, $alpha: 0.04); 
                }  
     }
 }
 .cardPayement{
    position: relative;
    .cardAll{ 
        position: absolute;
        right: 0;
        z-index: 1;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        height: 100%;
        right: 5px;
        width: 40px;
    }
 }