.mainHomePage {

    .instructionMain {
        margin: 0;
        .accordion-item-closed>.accordion-item-content{
            height: 0px !important;
        }
        .accordion-item-content {
            .accordionBody {
                background-color: #F5F4F6;
                border-top: solid 1px #ddd;
                min-height: 640px;
                .instructionInfo{
                    overflow: inherit;
                }
            }
        }
    }

    .mainSearch {
        padding: 30px 20px 10px;

        @include md {
            padding: 20px 10px 0px;
            --f7-grid-gap: 10px;
        }

        .formInput {
            @include md {
                order: 2;
                margin-top: -15px;
            }

            .themeLink {
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;

                @include md {
                    font-size: 9px;
                    line-height: 14px;
                }
            }

            .item-content {
                .item-label {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                    color: #4A4A4A;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    display: block;
                    width: 100%;

                }

                .item-input-wrap {
                    position: relative;

                    input {
                        background: #fff;
                        border-radius: 50px;
                        padding: 5px 8rem 5px 20px;
                        height: 50px;
                        border: solid 2px #D5D4D4;
                        transition: 0.5s;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);

                        &:focus {
                            border-color: var(--green);
                        }

                        @include md {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            height: 35px;
                            padding: 5px 6rem 5px 15px;
                        }

                    }

                    .button {
                        border-radius: 50px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        height: 40px;
                        line-height: 45px;
                        img{
                            height: 20px;
                            margin-left: 5px;
                            @include md {
                                height: 12px;
                            }
                        }
                        @include md {
                            font-size: 12px;
                            height: 28px;
                            line-height: 28px;
                        }

                        position: absolute;
                        top: 0;
                        right: 5px;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        padding: 0px 12px;

                        i {
                            margin-left: 5px;

                            @include md {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .theme-outline-yellow {
            display: inline-flex;
            width: auto;
            height: auto;
            padding: 10px 15px;
            line-height: 0;
            font-size: 14px;
            color: #333;
            margin-bottom: 6px;
            width: 170px;
            svg { 
              
                display: inline-block; 
            }
            .heartIcon{
                width: 16px;
                height: 16px;
                fill: var(--themeBtnYellow) !important;
                margin-right: 8px;
            }
            .cancelIcon{
                width: 20px;
                height: 20px;
                fill: var(--themeBtnYellow) !important;
                margin-left: 8px;
            }
            &.active{
                background-color: rgba(197,137,7,0.1);
                color: var(--blacktone);
              
            }
            @include md {
                padding: 7px 12px;
                font-size: 12px;

                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .Favorites {
            text-align: right;

            @include md {
                order: 1;
                display: flex;
                justify-content: flex-end;
                position: relative;
                z-index: 1;
            }
        }
    }

    .padder-15 {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

    .bg-grey {
        background-color: #F1EFEF;
    }

    .Card-Main {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .heading {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            color: var(--green);
            margin-top: 10px;
            margin-bottom: 5px;

            span {
                font-weight: 400;
                font-size: 20px;
                line-height: 33px;
                color: #515151;
            }

            &.text-black {
                color: #4A4A4A;
            }

            @include md {
                font-size: 14px;
                line-height: 21px;

                span {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        .cardRow {
            display: flex;
            flex-wrap: wrap;
            margin-right: calc(1.5rem * -.5);
            margin-left: calc(1.5rem * -.5);

            .gridCard {
                flex: 0 0 auto;
                width: 50%;
                padding-right: calc(1.5rem * .5);
                padding-left: calc(1.5rem * .5);

                @include md {
                    padding-right: calc(1rem * .75);
                    padding-left: calc(1rem * .75);
                }

                @include md {
                    width: 100%;
                }
            }

            &.oddEven {
                .gridCard {
                    flex: 0 0 auto;
                    width: 25%;

                    @include lg {
                        width: 33.33%;
                    }
                    
                    @include sm {
                        width: 50%;
                    }

                    // @media only screen and (min-width: 1080px) {

                    //     &:nth-child(5n + 4),
                    //     &:nth-child(5n + 5) {
                    //         flex: 0 0 auto;
                    //         width: 50%;
                    //     }
                    // }

                }
            }
        }

        .Homecard {
            display: flex;
            flex-direction: column;
            width: 100%;
            background: #FFFFFF;
            border-radius: 13px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            margin: 15px 0px;
            height: calc(100% - 30px);
            cursor: pointer;
            @include md {
                margin: 7px 0px;
                height: calc(100% - 15px);
            }

            .card-Img {
                display: block;
                width: 100%;
                height: 131px;
                min-height: 131px;
                border-radius: 13px 13px 0px 0px;
                background-size: cover;
                // background-position: center;
                cursor: pointer;
                position: relative;

                @include md {
                    height: 98px;
                    min-height: 98px;
                }

                .status {
                    position: absolute;
                    display: inline-block;
                    top: 13px;
                    right: 14px;
                    font-weight: 600;
                    padding: 2px 16px;
                    line-height: 22px;
                    text-transform: uppercase;
                    border-radius: 2px;
                    font-size: 14px;    
                    @include md {
                        font-size: 11px;
                        padding: 2px 15px;
                        line-height: 16px;
                    }
                }
            }

            .cardFooter {
                width: 100%;
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include md {
                    padding: 10px;
                }

                .parkName {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--dark23);
                    margin: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    min-height: 40px;
                    @include md {
                        font-size: 13px;
                        line-height: 16px;
                        min-height: 30px;
                    }
                }

                .actions {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2px;
                    width: 100%;
                    .smallLoader.smallTheme {
                        @include md {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    i {
                        color: var(--themeBtnYellow);
                    }

                    .kmName {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--gray86);
                        margin-top: 5px;
                        display: block;

                        @include md {
                            font-size: 12px;
                        }
                    }
                }
                .milesActions{
                    display: flex;
                    justify-content: space-between;
                    .label{
                        color: var(--gray86);
                        display: flex;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}