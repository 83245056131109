.bg-yellow-gradient{
 background: rgba(var(--yellowshadeRgba), 0.65);
}
.bg-green-gradient{
    background: rgba(var(--greenRgba),0.65); 
}
.cardOverlay{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
    height: 100%; 
    padding: 15px 20px;
    h4{
        font-size: 22px; 
        line-height: 28px; 
        font-weight: 500;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        margin: 0px 0px 15px 0px;
        max-width: 70%;
        @include lg{
            max-width: 100%;
        }
        @include md{
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 15px 0px;
        } 
        @include xs{
            font-size: 14px;
            line-height: 18px;
        }
    }
    .button{  
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        --f7-button-border-radius: 35px;
        height: 35px; 
        line-height: 35px;
        padding: 0px 20px;
        @include md{
            font-weight: 600;
            font-size: 10px; 
            height: 25px;
            line-height: 25px;
            padding: 0px 18px;
        }
    }
    .close{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        i{
            background: #fff;
            border-radius: 50px;
            font-size: 14px;
            padding: 1px 1px 1px 2px;
            font-weight: 600;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--blacktone);
            @include md{
                width: 14px;
                height: 14px; 
                font-size: 10px; 
            } 
        }
        span{
            font-size: 16px;
            color: #fff;
            font-weight: 600;
            margin-right: 5px;
            display: inline-block;
            text-transform: uppercase;
            @include md{
                font-size: 11px; 
            } 
        }
    }
}

.fullCard{
    .themeCard{
        margin-bottom: 40px;
        @include md{
            margin-bottom: 0px;
        }
        .cardImg {
            height: 165px;
            @include md{
                height: 110px;
            }
            .cardOverlay {
                h4{ 
                    max-width: 50%;
                    @include lg{
                        max-width: 100%;
                    }
                    @include md{
                        font-size: 11px;
                        line-height: 16px;
                        max-width: 80%;
                        margin-bottom: 5px;
                    }
                }
                .button{
                    font-size: 17px;
                    font-weight: 500;
                    padding: 0px 22px;
                    height: 40px;
                    line-height: 40px;
                    @include md{
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 25px;
                        height: 25px;
                    }
                }
            }
        }
}
}