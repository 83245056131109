.themeHeader{
    display: inline-block;
    background-color: var(--headerbg);
    height: auto;
    padding: 0px 0px;
    height: 90px;
    @include xl{
        padding: 0px 50px;
    } 
    @include md {
        padding: 0px 10px 0px 20px;
        --f7-grid-gap: 0px; 
        
     }
     @include xs {
        padding: 0px 5px 0px 10px;
     }
    .navbar-bg{ 
        display: none;  
    }
    .navbar-inner { 
        justify-content: space-between;
        overflow: inherit; 
        position: inherit;
        padding: 0;
      
        .logo{ 
            display: flex;
            width: 200px;
            height: 70px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center left;
            @include md{
                width: 150px;
                height: 50px;
            } 
           
        }
        .headerMenu{
            display: flex;
            align-items: center;
            li{
                &:not(:last-child){  
                    a{
                        &::after{ 
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            bottom: 0px;
                            height: 28px;
                            background-color: var(--headerBorder);
                            width: 2px;
                            margin: auto;  
                            @include md { 
                                width: 1px;
                                height: 19px;
                            } 
                        }
                    }
                }
                a{
                    display: flex; 
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    font-size: var(--headerFont);
                    line-height: 21px;
                    color: var(--headerlinkColor); 
                    min-width: inherit;
                    padding: 0px;
                    min-width: inherit; 
                    height: inherit;
                    padding: 10px 25px;
                    position: relative; 
                    @include sm {
                        padding: 20px 13px;
                    } 
                    &.last-child{ 
                        padding-left: 5px;
                        padding-right: 0px; 
                    }                  
                    
                    @include xsm{
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    @include sm{
                        font-size: 11px;
                        line-height: 16px; 
                    }
                    @include xs{
                        font-size: 10px; 
                    }
                    .icons{
                        width: 30px;
                        height: 30px; 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto 2px auto;
                        svg{
                            fill: var(--headerlinkColor); 
                            width: 30px;
                            height: 30px;
                            @include md{
                                width: 24px;
                                height: 24px;
                            }
                            @include xs{
                                width: 20px;
                                height: 20px;
                            }
                        }
                        @include md{
                            width: 24px;
                            height: 24px;
                            margin-bottom:0px; 
                        } 
                        @include xs{
                            width: 20px;
                            height: 20px;
                            margin-bottom:0px; 
                        }
                   
                    }
                    .label{
                        display: inline-block;
                        width: 100%;  
                        font-weight: 400;
                        line-height: inherit;
                        margin: 0;
                        text-align: center;
                        &.profile{
                            width: 80px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden; 
                            text-align: center; 
                            @include md {
                                width: 60px;
                            }
                            @include xs{
                                width: 40px;
                            }
                        }
                    }
                    &:hover,&:focus,&.active{
                        color: var(--headerlinkHoverColor);
                        .icons{
                            svg{ 
                                fill:var(--headerlinkHoverColor);
                              }
                        }
                    }
                    .badge{ 
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        padding: 0px;
                        min-width: 8px;
                        left: 10px;
                        top: 0px;
                        @include md{
                            left: 0px;
                        }
                    }
                }
                .dropdownLink{
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                }
                .menu-item {
                    background-color: inherit;
                    color: inherit;
                    height: inherit;
                    text-align: center;
                    display: inline-flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    font-size: inherit;
                    font-weight: inherit; 
                    .menu-dropdown{
                        height: inherit;                        
                        &::before, &::after{
                            display: none;
                        }
                        .menu-dropdown-content{
                            border-radius: 0px;
                            background-color: #fff;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.20);
                            width: 130px;
                            right: -50px;
                             min-width: inherit;
                            @include md{
                                right: -35px;
                            }
                            @include lg{
                                right: -30px;
                            }
                            .menu-dropdown-link{
                                font-weight: 500;
                                font-size: 16px;
                                padding: 10px 5px;
                                margin: 0;
                                @include md{
                                    font-size: 12px; 
                                    line-height: 12px; 
                                    
                                }
                                &:hover,&:focus,.active-state{
                                    color: var(--headerlinkHoverColor);
                                    background-color: #fff;
                                }
                            }
                            .menu-button-link{
                                font-weight: 500; 
                                padding: 10px 5px;
                                margin: 0;
                                font-size: var(--headerFont);
                                line-height: 21px;
                                color: var(--headerlinkColor); 
                                border: 0px;
                                background-color: transparent;
                                cursor: pointer;
                                width: 100%;
                                letter-spacing: inherit;
                                @include md{
                                    font-size: 12px; 
                                    line-height: 12px; 
                                    
                                }
                                &:hover,&:focus,.active-state{
                                    color: var(--headerlinkHoverColor);
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                }
           
                .customDropDown{
                     display: none;
                    position: absolute;
                    right: -151px;
                    background: #FFF;
                    box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.14);
                    top: 90px;
                    width: 420px;
                    text-align: left;
                    @media (max-width: 1850px){
                        right: 0;
                    }
                    @include xl{
                        right: -50px;
                    }  
                     @include xs {
                        right: -50px;
                     }
                    @include md{ 
                        left: 0px;
                        width: 100%;
                        top: 0px;
                        right: 0px;
                        z-index: 5;
                        height: 100vh;
                    }
                    .dropDownPass{
                        // margin-bottom: 50px;
                        &.yodelId{
                            .intallInfo{
                                border-radius: 0px; 
                                background: #F3F3F3; 
                                padding: 30px 20px 50px;
                                margin-bottom: 0px;
                                .innerSection{
                                .heading { 
                                    font-size: 20px; 
                                    line-height: 20px;
                                    margin: 0px 0px 15px 0px;
                                }
                               .smallerHeading { 
                                    font-size: 13px; 
                                }
                                .smallText { 
                                    font-size: 13px; 
                                    margin-bottom: 0px;
                                }
                            }
                            .qrCard {
                              
                                    width: 200px;
                                .qrHeader { 
                                    .heading {
                                        font-size: 16px; 
                                        line-height: 24px; 
                                    }
                                }
                                .userInfo {
                                 
                                    .smallText {
                                        margin: 5px 0px;
                                        font-size: 14px;
                                        line-height: 18px; 
                                        display: flex;
                                        width: 100%;
                                    }
                                    .smallTextInner{
                                        display: inline-block;
                                        width: 100%;
                                        word-wrap: break-word;
                                    }
                                }
                            }  
                        }  
                        .actionBtn {
                            .button{
                                color: #fff;
                                margin: 0px;
                                min-width: 180px;
                                padding: 10px 25px;
                            }
                        }
                            .BackIdBtn{
                                position: sticky;
                                bottom: 0px;
                                z-index: 2;  
                                padding: 15px;
                                text-align: center;
                                background: #FFF;
                                box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.15);
                                .button {  
                                    padding: 10px 20px;
                                    font-size: 16px; 
                                    font-weight: 600; 
                                    color: #000;
                                    min-width: 180px;
                                    display: inline-flex;
                                    @include md { 
                                        font-size: 14px;
                                    }
                                }
                            }            
                           
                            .yodelIdBtn { 
                                display: none;
                                .theme-outline-white {
                                    background: linear-gradient(180deg, #DEA121 0%, rgba(0, 129, 87, 0.69) 273.36%); 
                                    border-radius: 50px;
                                    padding: 7px 20px;
                                    color: #fff;
                                    font-size: 16px;
                                    @include md {
                                        padding: 5px 15px;
                                        font-size: 14px;
                                    }
                                }
                            }
                            &.closeYodel {
                                padding-bottom: 35px;
                                overflow: hidden;
                                .yodelIdBtn {
                                    bottom: 20px;
                                }
                                .intallInfo {   
                                    border-radius: 0px 0px 30px 30px;  
                                    padding: 25px 5px 40px 20px;
                                 
                                    @include md {
                                        padding: 15px 15px 40px 15px;
                                    }
                                    .innerSection,
                                    .actionBtn {
                                        display: none;
                                    }
                    
                                    .qrCard {
                                        display: inline-flex;
                                        margin: 0;
                                        width: 100%;
                                        @include md {
                                            padding: 0px;
                                        }
                                        .qrBox {
                                            width: 135px; 
                                            @include md {
                                                width: 110px; 
                                            } 
                                            .qrHeader { 
                                                padding: 10px 0px;
                    
                                                @include md {
                                                    padding: 5px 0px;
                                                }
                    
                                                .heading {
                                                    font-size: 12px;
                                                    line-height: 12px;
                    
                                                    @include md {
                                                        font-size: 11px;
                                                        line-height: 22px;
                                                    }
                    
                                                    span {
                                                        display: none;
                                                    }
                                                }
                                            }
                    
                                            .qrBody {
                                                padding: 10px;
                    
                                                @include md {
                                                    padding: 8px;
                                                }
                    
                                                canvas {
                                                    width: 70% !important;
                                                }
                                            }
                    
                    
                                        }
                    
                                        .userInfo {  
                                            padding-left: 15px;  
                                               width: 65%;
                                            .smallText {
                                                margin: 5px 0px 0;
                                                font-size: 13px;
                                                line-height: 16px;
                                                display: inline-flex;
                                                width: 100%;
                                                flex-direction: column;
                                                @include md {
                                                    font-size: 12px;
                                                    line-height: 18px; 
                                                    width: 100%;
                                                    flex-wrap: wrap;
                                                }
                                                strong{
                                                    padding-right: 10px;
                                                    margin: 0;
                                                    word-break: break-all;
                                                    display: inline-block;
                                                    min-width: 57px;
                                                    width: max-content;
                                                    white-space: normal;
                                                    font-weight: 800;
                                                    @include md {
                                                        max-width: 100%;
                                                    }
                                                }
                                                .smallTextInner{
                                                    display: inline-block;
                                                    word-break: break-all;
                                                    @include md {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        } 
                                    }
                                 
                                } 
                                .BackIdBtn{
                                    display: none;
                                }
                                .yodelIdBtn { 
                                    display: flex;
                                }
                                
                                @include md {
                                    height: auto;
                                  
                                
                                    .intallInfo{
                                        height: auto;
                                    }
                                }
                            }
                            @include md {
                                height: 100%;
                                .intallInfo{
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .userList{
                        padding:15px;
                        overflow: auto;
                        height: calc(100vh - 294px);
                        max-height: 500px;
                        // @include md {
                        // height: calc(100% - 300px); 
                        // }
                        li{
                            margin-bottom: 15px;
                            .menu-button-link{
                                border-radius: 12px;
                                background: #FFF;
                                box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.12);
                                padding: 8px 15px;
                                height: auto;
                                justify-content: flex-start;
                                &:focus-visible {
                                    outline: 2px solid #000 !important;
                                    outline-offset: 2px;
                                }
                                .icon{
                                    display: flex; 
                                    padding: 8px 20px 8px 15px;
                                    justify-content: center;
                                    border-right: solid 1px #B8B8B8;
                                    margin-right: 12px;
                                    
                                }
                          
                            .text{
                                color: #333; 
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                line-height: 22px;
                            }
                            .righticon{
                                position: absolute;
                                right: 15px;
                            }
                            .cancelicon{
                                position: absolute;
                                left: 10px;
                                top: 11px;
                            }
                            .scopeList {
                                padding-top: 10px;
                                li{
                                    list-style: disc;
                                    margin-left: 23px;
                                    font-size: 16px;
                                }
                            }
                        }
                        }
                    }
                    .CloseIdBtn{ 
                        padding: 15px;
                        text-align: center;
                        background: #FFF;
                        box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.15);
                        position: relative; 
                        width: 100%;
                        bottom: inherit;
                        display: flex;
                        .closeLogout {
                            width: 50%;                          
                            &:first-child{
                                margin-right: 17px;
                            }
                        }
                        .button {  
                            padding: 8px 20px;
                            font-size: 18px; 
                            font-weight: 500; 
                            color: var(--black);
                            width: 100%;
                            @include md { 
                                font-size: 14px;
                            }
                            &.themeBtn {
                                color: var(--themeBtnColor);
                            }
                            &.theme-outline-yellow{
                                margin-right:17px ;
                            }
                        }
                    }     
                }
                .dropdown-opened .customDropDown {
                    display: block;
                }
            }
        }
    }
    &.openDropDown{
        @include md{
        position: absolute;
    }
    }
}
.headerDropdownOpen{
    @include md{
        overflow: hidden;
    } 
}