$breakpoints: (
 xxs: 300px,
  bp-350: 350px,
  xs: 380px,
  xsm: 479px,
  sm-x: 567px,
  sm: 576px,
  smd: 630px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  x-4xl: 1800px,
  x-5xl: 2000px,
);
@mixin xxs {
    @media (max-width: map-get($breakpoints, 'xxs')){
        @content;
    }
}
@mixin xs {
        @media (max-width: map-get($breakpoints, 'xs')){
            @content;
        }
}
@mixin sm {
    @media (max-width: map-get($breakpoints, 'sm')){
        @content;
    }
}
@mixin xsm {
    @media (max-width: map-get($breakpoints, 'xsm')){
        @content;
    }
}
@mixin smd {
    @media (max-width: map-get($breakpoints, 'smd')){
        @content;
    }
}
@mixin md {
    @media (max-width: map-get($breakpoints, 'md')){
        @content;
    }
}
@mixin lg {
    @media (max-width: map-get($breakpoints, 'lg')){
        @content;
    }
}
@mixin xl {
    @media (max-width: map-get($breakpoints, 'xl')){
        @content;
    }
}
@mixin xxl {
    @media (max-width: map-get($breakpoints, 'xxl')){
        @content;
    }
}
@mixin xxxl {
    @media (max-width: map-get($breakpoints, 'xxxl')){
        @content;
    }
}
@mixin x-4xl {
    @media (max-width: map-get($breakpoints, 'x-4xl')){
        @content;
    }
}
@mixin x-5xl {
    @media (max-width: map-get($breakpoints, 'x-5xl')){
        @content;
    }
}
@mixin bp-350 {
    @media (max-width: map-get($breakpoints, 'bp-350')){
        @content;
    }
}
@mixin sm-x {
    @media (max-width: map-get($breakpoints, 'sm-x')){
        @content;
    }
}
@mixin breakpoints($bp: 0) {
    @media (max-width: $bp){
        @content;
    }
}

 