// Wallet Page Css Start

.bg-white {
    .padder-15 {
        padding: 1.5rem 0rem 3rem 0rem;

        @include md {
            padding: 1rem 0rem 3rem 0rem;
        }
    }
}

.walletMain {
    align-items: center;

    .passeNumber {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;

        @include md {
            margin-bottom: 15px;
        }

        ul {
            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                .listing {
                    display: inline-block;
                    padding: 5px 12px;
                    border-radius: 22px;
                    border: solid 2px var(--green);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: var(--blacktone);
                    height: auto;

                    @include lg {
                        padding: 5px 12px;
                        font-size: 10px;
                        line-height: 14px;
                        border-width: 2px;
                    }

                    &.active {
                        background-color: rgba(var(--greenRgba), 0.2);
                        color: var(--green);
                    }
                }


            }
        }

        .infoText {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0px;
            font-weight: 400;
            color: var(--blacktone);

            @include lg {
                font-size: 11px;
                line-height: 16px;
            }
        }
    }

    .themeCard {
        height: 100%;
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;

        @include sm {
            margin-bottom: 15px;
        }

        .walletCard {
            &:not(:first-child) {
                margin-top: -160px;

                @media only screen and (min-width: 550px) and (max-width: 800px) {
                    margin-top: -115px;
                }

                @include sm {
                    margin-top: -115px;
                }
            }
        }
    }

    .themeInformation {
        display: inline-block;
        background: #F5F4F7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        margin: 5px 0px;
        padding: 25px 5px 15px 25px;
        width: 100%;

        @include md {
            padding: 15px 5px 15px 15px;
        }

        .card-body {
            display: inline-block;
            width: 100%;
            height: 195px;
            overflow: auto;
            padding-right: 20px;

            strong,
            p {
                display: block;
                font-size: 14px;
                line-height: 22px;
                color: #231F20;

                @include md {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            strong {
                font-weight: 600;
            }

            p {
                font-weight: 500;
                margin: 10px 0px 0px;
            }
        }
    }

    .walletCard {
        border-radius: 15px;
        width: 100%;
        position: relative;
        transition: 0.5s;
        border: solid 4px rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        display: flex;
        align-items: flex-start;
        background-size: cover;
        background-position: center;

        @include md {
            border-width: 2px;
        }

        .walletHeader {
            padding: 20px 10px 10px 10px;
            border-radius: 10px 10px 0px 0px;

            @include sm {
                padding: 15px 10px 10px;
            }

            @include md {
                padding: 10px 8px 10px 7px;
            }
        }

        .walletbody {
            padding: 0px 10px 25px 10px;

            @include sm {
                padding: 0px 15px 10px;
            }

            @include md {
                padding: 0px 8px 20px 7px;
            }
        }


        .grid-15 {
            --f7-grid-gap: 5px;

            .custom-grid-1 {
                width: 15%;

                @include xxs {
                    width: 100%;
                    --f7-cols-per-row: 1;
                }
            }

            .custom-grid-2 {
                width: 83%;
                &.PassesLeftCol{
                    @include sm{
                        width: 100%;
                        padding: 0 15px;
                    }
                    @include xs{
                        padding: 0 6px;
                    }
                }

                @include xxs {
                    --f7-cols-per-row: 1;
                    width: 100%;
                }
            }

            @media only screen and (min-device-width: 360px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
                --f7-grid-gap: 5px;

                .custom-grid-1 {
                    width: 14%;
                }

                .custom-grid-2 {
                    width: 84%;
                }
            }
        }

        .mt-10 {
            margin-top: 10px;
        }

        .mt-15 {
            margin-top: 15px;

            @include md {
                margin-top: 10px;
            }

            @include xxs {
                margin-top: 5px;
            }
        }

        .mt-20 {
            margin-top: 10px;
        }

        .mainHeading {
            align-items: center;
            margin-bottom: 10px;

            @include md {
                margin-bottom: 5px;
            }
        }

        .walletPark {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            margin: 0 auto;
            background-color: #fff;
            padding: 5px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 2px 2px 4px #114232;
            -webkit-box-shadow: 2px 2px 4px #114232;
            -moz-box-shadow: 2px 2px 4px #114232;

            @include md {
                width: 42px;
                height: 42px;
                padding: 2px;
            }

            @include xs {
                margin: 0px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }

        .parkName {
            width: 100%;
            margin: 0px;
            font-size: 18px;
            color: #fff;
            font-weight: 700;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;

            @include md {
                font-size: 14px;
                line-height: 17px;
            }

            @include xs {
                font-size: 13px;
                line-height: 17px;
            }
        }

        .walletStatus {
            display: inline-block;
            padding: 4px 9px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            ;
            -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            ;
            -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            ;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            border-radius: 4px;
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            @include md {
                font-size: 11px;
                line-height: 13px;
                padding: 6px 2px;
                width: 100%;
            }

            @include xxs {
                font-size: 9px;
                line-height: 12px;
                padding: 3px 3px;
            }
        }

        .subparkName {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            border-radius: 5px;
            padding: 6px 8px;
            color: #000;
            background: #fff;
            display: inline-block;
            max-width: 100%;
            position: relative;
            top: -8px;

            @include md {
                font-size: 10px;
                line-height: 16px;
                font-weight: 400;
                padding: 3px 8px;
                top: -5px;
            }

            @include xxs {
                font-size: 8px;
            }
        }

        .walletDate {
            padding: 6px 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px 0px 0px 8px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            display: inline-block;
            width: auto;
            position: relative;
            right: -12px;

            @include md {
                font-size: 10px;
                line-height: 13px;
                padding: 5px 8px;
                right: -10px;
                border-radius: 4px 0px 0px 4px;
            }

            @include xxs {
                font-size: 8px;
                line-height: 10px;
            }
        }

        .label {
            @include sm {
                margin-bottom: 2px;
            }
        }

        .label,
        .value {
            font-size: 16px;
            line-height: 26px;
            color: #FFFFFF;

            @include md {
                font-size: 12px;
                line-height: 15px;
                word-break: break-word;
                padding-right: 5px;
            }

            @include xxs {
                font-size: 10px;
                line-height: 15px;
            }
        }

        .label {
            font-weight: 400;

            @include md {
                margin-bottom: 5px;
            }
        }

        .value {
            font-weight: 700;
        }

        .digit {
            aspect-ratio: 1;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0 14px;
            max-width: 100px;
            margin: 0 auto;
            min-width: 65px;
            &::before{
                position: absolute;
                content: "";
                background: url(../../image/LeftPassesRectangle.svg);
                left: 0;
                right: 0;
                top: 55%;
                background-size: 100%;
                transform: translateY(-50%);
                height: 100%;
            }
            @include md {
                // width: 45px;
                // height: 45px;
                border-radius: 5px;
                margin-left: -5px;
            }

            @include sm {
                padding: 0 12px;
            }

            // @include xxs {
            //     width: 38px;
            //     height: 38px;
            // }

            &::after {
                content: none;
                position: absolute;
                left: 0px;
                right: 0px;
                width: 90%;
                height: 90%;
                border-radius: 6px;
                background-color: #fff;
                transform: rotate(45deg);
                margin: 0 auto;

                @include md {
                    width: 84%;
                    height: 84%;
                }

            }

            .number {
                font-weight: 700;
                font-size: 17px;
                line-height: 18px;
                color: var(--green);
                z-index: 1;
                padding: 0 15px;
                @include md {
                    font-size: 17px;
                    line-height: 18px;
                    padding: 3px 8px;
                }
            }

            .labeldigit {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #231F20;
                z-index: 1;
                white-space: normal;
                word-break: break-word;
                @include md {
                    font-size: 12px;
                    line-height: 10px;
                }
            }
        }

        .button {
            display: flex;
            align-items: center;
            height: auto;
            min-height: 42px;
            border-radius: 42px;
            padding: 10px;
            font-size: 15px;
            color: #000;
            font-weight: 700;
            white-space: inherit;
            overflow: inherit;
            line-height: 18px;
            text-transform: uppercase;
            &.walletActionBtn{
                width: max-content;
                min-width: 126px;
                max-width: 126px;
                margin-left: 15px;
                @include xsm {
                    min-width: 100px;
                    max-width: 100px;
                }
                @include xs {
                    margin-left: 8px;
                }
                &.CheckInSection{
                    margin-left: 0;
                    @include xsm {
                        min-width: 120px;
                    }
                    @include xs {
                        min-width: 106px;
                    }
                }
            }

            @include md {
                font-size: 13px;
                padding: 6px 5px;
                min-height: 38px;
                border-radius: 40px;
            }

            @include xs {
                font-size: 12px;
                line-height: 16px;
                padding: 6px 10px;
                min-height: 32px;
                margin-left: 8px;
            }

            @include xxs {
                font-size: 13px;
                min-height: 36px;
            }

        }
        .fullfillment{
            span{
                text-transform: uppercase;
                background: #ffff00;
                color: #ff4d4d;
                font-weight: 800;
                border: 4px solid;
                text-align: center;
                font-size: 16.8px;
                line-height: 22px;
                padding: 0 5px 0 4px;
                border-radius: 5px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                word-break: break-word;
                max-width: 183px;
                min-width: 100%;
                min-height: 51px;
                @include md{
                    font-size: 15px;
                }
                @include xsm{
                    border: 3px solid;
                    font-size: 15px;
                    line-height: 20px;
                    padding: 3px 5px 0 4px;
                }
                @include xs{
                    font-size: 12px;
                    line-height: 16px;
                    min-height: 44px;
                }
            }
        }
    }

    .walletLink {
        width: 100%;
        margin-bottom: 10px;
        flex-wrap: wrap;
        .customBtn {
            margin-left: 10px;          
        }
        .theme-outline-green {
            border-radius: 50px;
            font-weight: 600;
            height: auto;
            line-height: inherit;
            padding: 5px 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            width: auto;
            min-width: 31.33%;
            @include sm { 
                margin-left: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 13px;
                min-width: 32.33%;
                padding: 4px 5px; 
            }
            &:first-child {
                margin-left: 0px;
            }
            svg {
                fill: var(--green);
                height: 22px !important;
                width: 22px !important;
                fill: var(--green);
                margin-right: 4px;
                @include sm {
                    height: 20px !important;
                    width: 20px !important;  
                    margin-right: 3px;
                }
            }

        }

        li {
            &:not(:last-child) {
                margin-right: 25px;

            }

            .link {
                transition: 0.5s;

                svg {
                    height: 25px !important;
                    width: 25px !important;
                    fill: var(--green);
                }

                .label {
                    color: var(--blacktone);
                    font-size: 16px;
                    font-weight: 500;
                    display: inline-block;
                    margin-left: 4px;

                    @include md {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                &:hover,
                &:focus {
                    .label {
                        color: var(--green);
                    }
                }
            }
        }
    }

    .walletSlider {
        width: 100%;
        margin-bottom: 20px;
        padding: 0px 12px;

        @include sm {
            padding: 0px 15px;
        }

        &.walletCenter {
            .swiper-wrapper {
                justify-content: center;
            }
        }

        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }

            .passDetails {
                display: inline-block;
                text-align: center;
                width: 90%;
                margin: auto;

                @include sm {
                    width: 100%;
                }

                &.expiredPass {
                    .passHeader {
                        background-color: #868686;
                        color: #fff;
                    }

                    .passBody {
                        border-color: #868686;

                        p,
                        h4 {
                            color: #868686;
                        }
                    }
                }

                .passHeader {
                    background-color: var(--yellow);
                    font-size: 14px;
                    color: #000;
                    font-weight: 700;
                    border-radius: 8px;
                    padding: 8px 20px;
                    line-height: 16px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

                    @include md {
                        font-size: 12px;
                        line-height: 14px;
                        border-radius: 6px;

                    }
                }

                .passBody {
                    display: inline-block;
                    margin: 0px 15px;
                    border-radius: 0px 0px 15px 15px;
                    padding: 5px;
                    border: solid 2px var(--green);
                    border-top: 0px;

                    @include sm {
                        margin: 0px 5px;
                        width: 80%;
                        padding: 10px 5px;
                    }

                    p {
                        font-size: 14px;
                        color: #000;
                        line-height: 16px;
                        margin: 0px 0px 5px 0px;
                        font-weight: 600;

                        @include md {
                            font-size: 10px;
                            line-height: 12px;
                            margin: 0px;
                        }
                    }

                    h4 {
                        font-size: 22px;
                        color: var(--green);
                        font-weight: 700;
                        margin: 0px;
                        line-height: 26px;

                        @include md {
                            font-size: 18px;
                            line-height: 15px;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            &::after {
                font-size: 20px;
                color: #000;
                font-weight: 900;

                @include sm {
                    font-size: 16px;
                }

                @media only screen and (min-device-width: 360px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
                    font-size: 16px;
                }
            }

        }

        .swiper-button-prev {
            left: -7px;
            right: auto;

            @include sm {
                left: -8px;
            }
        }

        .swiper-button-next {
            right: -7px;
            left: auto;

            @include sm {
                right: -8px;
            }
        }
    }

    .BlockTitle {
        display: inline-block;
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 35px;
        position: inherit;
        overflow: inherit;

        @include md {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 5px;
        }
    }

    .walletList {
        display: inline-block;
        width: 100%;
        margin: 0px;
        z-index: inherit;

        ul {

            &::after,
            &::before {
                display: none;
            }

            border-radius: 10px;
            background-color: #E5E5E5;

            .item-content {
                padding: 0px;

                .item-inner {
                    padding: 18px 20px;

                    @include md {
                        padding: 14px 18px;
                    }

                    .item-title {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 24px;
                        color: #4A4A4A;

                        @include md {
                            font-size: 14px;
                        }
                    }

                    .qtyInput {
                        width: auto;
                    }

                    .priceMain {
                        display: flex;
                        overflow: inherit;
                    }

                    .item-after {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        color: #868686;

                        @include md {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &.activityList {
            ul {
                .item-content {
                    .item-inner {
                        display: inline-block;
                        padding: 15px 20px;

                        @include xs {
                            padding: 10px 15px;
                        }

                        .item-after {
                            padding-left: 0px;
                            font-size: 15px;
                            margin-top: 3px;

                            @include md {
                                margin-top: 2px;
                                font-size: 10px;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Wallet Page Css Start 
.pageModal {
    display: inline-block;
    width: 100%;
    padding: 0px 0px 25px 0px;

    .cardHeader {
        padding: 0px 25px;

        @include sm {
            padding: 0px 10px;
        }

        .heading {
            font-weight: 600;
            font-size: 28px;
            line-height: 38px;
            color: var(--themeColor);
            margin: 0px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;

            @include md {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }

    .card-body {
        max-width: 80%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 20px 0px;

        @include md {
            padding: 20px 0px;
            max-width: 90%;
        }

        .SmallText {
            text-align: center;
            margin-top: 0px;
            color: var(--black);
            font-size: 15px;
            line-height: 136%;
            font-weight: 500;
            margin: 0px;

            @media only screen and (min-width: 200px) and (max-width: 375px) {
                font-size: 12px;
                line-height: 16px;
            }
        }

    }

    .cardfooter {
        max-width: 80%;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        @include md {
            max-width: 90%;
            padding: 0;
        }

        .button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 50px;
            font-size: 16px;
            height: 40px;
            line-height: 40px;

            @media only screen and (min-width: 1300px) and (max-width: 1500px) {
                margin: 5px 0px;
            }

            @include md {
                font-size: 14px;
                height: 36px;
                line-height: 36px;
                width: 180px;
            }

        }

        .col-50 {
            .button {
                @include md {
                    width: 100%;
                }
            }
        }
    }

}

.expireCard {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(217, 217, 217, 0.3);
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px;
    @include sm{
        padding: 18px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    span{
        font-weight: 700;
        text-shadow: 4px 4px hsla(0deg, 0%, 0%, 0.25);
        font-size: 58px;
        opacity: 0.6;
        position: relative;
        padding: 14px 30px;
        transform: rotate(-15deg);
        text-transform: uppercase;
        height: max-content;
        width: 100%;
        text-align: center;
        @include md{
            font-size: 42px;
            padding: 14px 28px;
            width: 80%;
        }
        @include sm{
            font-size: 35px;
            padding: 14px 20px;
            width: 90%;
        }        
        @include xs{
            width: 100%;
            font-size: 33px;
        }
        &::before{
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 12px solid;
            border-radius: 30px;
            @include sm{
                border-width: 9px;
                border-radius: 25px;
            }
        }
        &.expireCardSpan{
            color: var(--expirePass);
            &::before{
                border-color: var(--expirePass);
            }
        }
        &.cancelPass{
            color: var(--cancelPass);
            &::before{
                border-color: var(--cancelPass);
            }
        }
    }
}

.walletApp,
.dropDownPass {
    .installApp {
        display: flex;
        align-items: center;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 129, 87, 1) 0%, rgba(27, 142, 105, 1) 100%);
        padding: 25px 40px;
        border-radius: 0px 0px 18px 18px;
        position: relative;
        z-index: 1;

        @include md {
            padding: 15px 25px;
        }

        .addPortal {
            display: inline-flex;
            width: 80%;
            align-items: center;

            @include md {
                width: 70%;
            }

            .appImage {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 90px;
                background: var(--yellow);
                border-radius: 10px;
                padding: 5px;

                @include md {
                    width: 50px;
                    height: 50px;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .Heading {
                display: inline-block;
                width: 70%;
                margin: 0px 0px 0px 25px;
                color: #FFF;
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @include md {
                    font-size: 12px;
                    margin: 0px 0px 0px 12px;
                }
            }
        }

        .rightBtn {
            display: inline-block;
            width: 20%;

            @include md {
                width: 30%;
            }

            .button {
                font-size: 18px;
                font-weight: 600;
                height: 45px;

                @include md {
                    height: 35px;
                    font-size: 14px;
                }
            }
        }
    }

    .yodelId, 
    &.dropDownPass {
        position: relative;

        .intallInfo {
            text-align: center;
            padding: 55px 0px;
            background: #EAEAEA;
            border-radius: 0px 0px 70px 70px;
            margin-bottom: 25px;
            // margin-top: -10px;
            margin-top: 0px;

            @include md {
                padding: 40px 0px 55px 0px;
                border-radius: 0px 0px 30px 30px;
            }

            .heading {
                color: var(--green);
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                margin: 0px 0px 20px 0px;

                @include md {
                    font-size: 20px;
                    line-height: 20px;
                    margin: 0px 0px 10px 0px;
                }
            }
            .informationText{
                    padding: 0px 120px;
                    text-align: left;
                    @include lg {
                        padding: 0px 40px;
                    }
                    @include md {
                        padding: 0px 15px;
                    }
                 
            }   
            .smallerHeading {
                color: #000; 
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @include md {
                    font-size: 14px;
                }
            }

            .smallText {
                color: #000;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 5px 0px 15px 0px;

                @include md {
                    font-size: 13px;
                }
            }

            .qrCard {
                display: inline-block;
                width: 220px;
                margin: 25px auto 0 auto;
                @include md {
                    width: 250px;
                }
                .qrBox {
                    border-radius: 15px;
                    background-color: #fff;
                }

                .qrHeader {
                    display: inline-block;
                    width: 100%;
                    border-radius: 12px 12px 0px 0px;
                    padding: 5px 0px;
                    background: linear-gradient(180deg, #DEA121 0%, rgba(0, 129, 87, 0.69) 273.36%);

                    .heading {
                        font-size: 18px;
                        color: #fff;
                        font-style: normal;
                        line-height: 26px;
                        font-weight: 400;
                        margin: 0px;

                        @include md {
                            font-size: 16px;
                        }

                        strong {
                            display: inline-block;
                            width: 100%;
                            font-weight: 600;
                        }
                    }
                }

                .qrBody {
                    padding: 15px;

                    canvas {
                        width: 90% !important;
                        height: 90% !important;
                        @include md {
                            width: 75% !important;
                            height: 75% !important;
                        }
                    }

                    @include md {
                        padding: 10px;
                    }
                }
            }

            .userInfo {
                display: inline-block;
                width: 100%;
                margin-top: 15px;
                text-align: left;

                .smallText {
                    margin: 2px 0px;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    @include md {
                       font-size: 13px;
                    }
                    strong {
                        font-weight: 800;
                        margin-right: 10px;
                    }

                }
            }
        }

        .yodelIdBtn {
            position: absolute;
            bottom: -22px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            @include md {
                bottom: -20px;
            }

            .theme-outline-white {

                background: linear-gradient(180deg, #DEA121 0%, rgba(0, 129, 87, 0.69) 273.36%);

                border-radius: 18px;
                padding: 10px 20px;
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                height: auto;

                @include md {
                    border-radius: 22px;
                    padding: 8px 25px;
                }

                .icon {
                    margin-left: 10px;
                    transform: 0.5;
                    width: 25px;
                }
            }
        }

        &.closeYodel {
            .intallInfo {
                padding: 25px 0px 45px 0px;

                .innerSection,
                .actionBtn {
                    display: none;
                }

                .qrCard {
                    display: inline-flex;
                    margin: 0;
                    width: auto;
                    @include md {
                        padding: 0px 20px;
                        align-items: center;
                    }
                    .qrBox {
                        width: 250px;

                        @include md {
                            width: 200px;
                        }

                        .qrHeader {
                            background: linear-gradient(180deg, #4A4A4A 0%, #8D8B8B 100%);
                            padding: 10px 0px;

                            @include md {
                                padding: 5px 0px;
                            }

                            .heading {
                                font-size: 16px;
                                line-height: 20px;

                                @include md {
                                    font-size: 12px;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }

                        .qrBody {
                            padding: 10px;

                            @include md {
                                padding: 8px;
                            }

                            canvas {
                                width: 70% !important;
                            }
                        }


                    }

                    .userInfo {
                        padding-left: 30px;
                        margin: 0px;
                        display: flex;
                        flex-flow: column;
                        align-items: flex-start;
                        justify-content: center;

                        @include md {
                            padding-left: 15px;
                        }

                        .smallText {
                            margin: 5px 0px;
                            font-size: 18px;
                            line-height: 18px;

                            @include md {
                                font-size: 14px;
                                line-height: 24px;
                                word-break: break-all;
                                display: block;
                                margin: 0;                        
                            }
                            strong{
                                @include sm {
                                    display: block;
                                }   
                            }
                        }
                    }
                }
            }

            .yodelIdBtn {
                .theme-outline-white {
                    background: linear-gradient(180deg, #4A4A4A 0%, #8D8B8B 100%);

                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }


    }
    .yodelId{
        .intallInfo{
            .qrCard{
                width: max-content;
                .qrBox{
                    width: 220px;
                }
            }
            .userInfo{
                .smallText{
                    strong{
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    .actionBtn {
        display: flex;
        align-items: center;
        margin-top: 25px;
        justify-content: center;

        @include md {
            margin-top: 15px;
        }
        .actionprintButton{
            .button{
                &.customBtn {
                    margin-right: 20px;    
                    @include md {
                        margin-right: 10px;
                    }
                }
            }
        }

        .button {
            font-size: 16px;
            font-weight: 400;
            height: 40px;
            padding: 10px 25px;

            @include md {
                padding: 10px;
                font-size: 14px;
                font-weight: 600;
                height: 35px;
            }            
        }
    }
}

.walletAccordingMain {
    .accordion-item {
        width: 100%;
    }

    .accordion-item-toggle {
        display: flex;
        padding: 7px 15px;
        background: linear-gradient(180deg, #DEA121 0%, rgba(0, 129, 87, 0.69) 273.36%);

        .mainHeading {
            display: inline-flex;
            align-items: center;
            width: 100%;
            position: relative;

            &::after {
                content: var(--f7-accordion-chevron-icon-down);
                font-family: framework7-core-icons;
                font-weight: 400;
                font-style: normal;
                position: absolute;
                right: 0px;
                font-size: 26px;
                color: #ffff;
                transition: 0.5s;
            }
        }

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #fff;
            margin: 0;
        }

    }

    &.accordion-item-opened {
        .mainHeading {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }
}