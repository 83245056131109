@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './comman/color';
@import './comman/breakpoints';
@import './components/fillterControl';
@import './components/cardControl';
@import './components/statusControl';
@import './components/overlayControl';
@import './components/inputControl';
@import './components/modelControl';
@import './components/buttonControl';

// Components
@import './components/headerControl';
@import './components/footerControl';
@import './components/bannerControl';
@import './components/walletControl';
@import './components/QrCodeControl';
@import './components/profileControl';
@import './components/paymentControl';
@import './components/subPortalControl';
@import './components/homeMain';

.bg-green {
    background-color: var(--green);
}

.bg-greentone {
    background-color: var(--greentone);
}

.bg-greenshade {
    background-color: var(--greenshade);
}

.text-green {
    color: var(--green);
}

.text-greentone {
    color: var(--greentone);
}

.text-greenshade {
    color: var(--greenshade);
}

.br-green {
    border-color: var(--green);
}

.br-greentone {
    border-color: var(--greentone);
}

.br-greenshade {
    border-color: var(--greenshade);
}

.text-white {
    color: #fff;
}

.bg-available {
    background-color: var(--available);
}

.bg-limited {
    background-color: var(--limited);
}

.bg-soldout {
    background-color: var(--soldout);
}

// Yellow Color Shade
.bg-yellow {
    background-color: var(--yellow);
}

.bg-yellowtone {
    background-color: var(--yellowtone);
}

.bg-yellowshade {
    background-color: var(--yellowshade);
}

.text-yellow {
    color: var(--yellow);
}

.text-yellowtone {
    color: var(--yellowtone);
}

.text-yellowshade {
    color: var(--yellowshade);
}

.br-yellow {
    border-color: var(--yellow);
}

.br-yellowtone {
    border-color: var(--yellowtone);
}

.br-yellowshade {
    border-color: var(--yellowshade);
}

// Normal Color Shade
.bg-black {
    background-color: var(--black);
}

.bg-blacktone {
    background-color: var(--blacktone);
}

.bg-gray {
    background-color: var(--gray);
}

.bg-graylight {
    background-color: var(--graylight);
}

.bg-danger {
    background-color: var(--danger);
}

.text-black {
    color: var(--black);
}

.text-blacktone {
    color: var(--blacktone);
}

.text-gray {
    color: var(--gray);
}

.text-graylight {
    color: var(--graylight);
}

.text-danger {
    color: var(--danger);
}

.br-black {
    border-color: var(--black);
}

.br-blacktone {
    border-color: var(--blacktone);
}

.br-gray {
    border-color: var(--gray);
}

.br-graylight {
    border-color: var(--graylight);
}

.br-danger {
    border-color: var(--danger);
}

.bg-orange {
    background-color: var(--orange);
}

.bg-light-red {
    background-color: #B31959;
}

:root {
    --f7-grid-gap: 20px;
    --f7-page-bg-color: #fff;
    --f7-navbar-height: 80px;
    --f7-navbar-shadow-image: none;
    --f7-popup-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0);
    --f7-popup-tablet-width: 550px;
    --f7-popup-tablet-height: auto;
    --f7-grid-gap: 30px;

    @include lg {
        --f7-grid-gap: 20px;
    }
}

.color-white {
    --f7-theme-color: #fff;
    --f7-theme-color-rgb: 0, 0, 0;
    --f7-theme-color-shade: #fff;
    --f7-theme-color-tint: #fff;
    color: #000;

    &.active-state {
        color: #000;
    }
}

.color-yellow {
    --f7-theme-color: #fcb71b;
    --f7-theme-color-rgb: 0, 0, 0;
    --f7-theme-color-shade: #fcb71b;
    --f7-theme-color-tint: #fcb71b;
}

html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
}

body {
    overflow: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--black);
    text-align: left;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
}
body .mr-1{
    margin-right: 1px !important;
}
body .mr-2{
    margin-right: 2px !important;
}
body .mr-3{
    margin-right: 3px !important;
}
body .mr-4{
    margin-right: 4px !important;
}
body .mr-5{
    margin-right: 5px !important;
}
.ml-0{
    margin-left: 0 !important;
}
.mr-0{
    margin-right: 0 !important;
}
.m-0{
    margin: 0 !important;
}
.margin-left-10{
    margin-left: 10px !important;
}
.margin-top-15{
    margin-top: 15px !important;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.page-content {
    overflow-x: hidden;
}

.md .ripple-wave,
.md .navbar a.link:before {
    display: none !important;
}

.ios .link.active-state {
    opacity: 1;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}

.container-fluid,
.themeHeader .navbar-inner {
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1000px) {
        max-width: 1100px;
    }

    @media (min-width: 1280px) {
        max-width: 1280px;
    }

    @media (min-width: 1500px) {
        max-width: 1400px;
    }

    @media (min-width: 1600px) {
        max-width: 1600px;
    }
}

a {
    transition: 0.5s;
}

a:focus-visible {
    outline: 2px solid #000 !important;
    outline-offset: 2px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.button {
    text-transform: inherit;
}

.popup-backdrop {
    background: rgba(0, 0, 0, .6);
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.themeLink {
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;

    &.black {
        color: var(--black);

        &:hover,
        &:focus {
            color: var(--greenshade);
        }
    }

    &.green {
        color: var(--green);

        &:hover,
        &:focus {
            color: var(--greenshade);
        }
    }

    @include md {
        font-size: 14px;
    }
}

.bg-active {
    background-color: #4E9800;
    color: #fff;
}

.bg-offline {
    background-color: #868686;
    color: #fff;
}

.bg-ready {
    background-color: #622394;
    color: #fff;
}

.bg-red {
    background-color: #FF0000;
}

.h-100 {
    height: 100%;
}

.bg-light {
    background: #F4F4F4;
}

.bg-white {
    background-color: #fff;
}

.bg-white-gradient {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.02) 100%, rgba(255, 255, 255, 0) 100%);
}

.textLimit {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.textTwoLine {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.max-height {
    min-height: var(--min-height);

    @include md {
        min-height: var(--min-height-mobile);
    }
}

.screenCenter {
    display: flex;
    flex-flow: column;
    width: 500px;
    margin: 0 auto;
    &.walletMain {
        @media only screen and (min-width: 550px) and (max-width: 800px) {
            width: 470px;
            padding: 0px;
        }
    }
    @include md {
        padding: 0px 25px 0px 20px;
        width: 100%;
        max-width: 500px;
    }

    @include xs {
        width: 100%;
        padding: 0px 10px;
    }

}

.error {
    padding: 8px 10px;
    text-align: center;
    color: #fff;
    font-size: 16px;

    .container-fluid {
        position: relative;
        align-items: center;
        display: flex; 
    }

    @include md {
        padding: 5px;
    }

    .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        width: 85%;

        @include md {
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
            
        }
          @include sm {
            width: 70%;
        }
    }
    .display-flex{
        width: 15%;
        justify-content: flex-end; 
        @include sm {
            width: 35%;
        }
    }
    .extend,
    .close {
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        @include md {
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
        }
    }
    .close {
        display: flex;
        align-items: center;
        margin-left: 5px;
        // position: absolute;
        // right: 15px;
        // top: 0px;
        // bottom: 0; 

        // @include md {
        //     right: 10px;
        // }

        // @include xs {
        //     right: 0px;
        // }

        &:hover,
        &:focus {
            color: #fff;
        }

        i {
            font-size: 16px;
            margin-left: 2px;
            font-weight: 600;
        }

        @include md {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            i {
                font-size: 12px;
            }
        }

        @include xs {
            font-size: 10px;
            line-height: 16px;

            i {
                font-size: 10px;
            }
        }
    }
}
.new-error{
    background-color: #B81515;
    padding: 20px 10px;
    box-shadow: inset 0px 9px 10px rgba(255, 255, 255, 0.25);
    @include md {
        padding: 10px;
    }
    .label{
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        @include md {
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
            
        }
          @include sm {
            width: 70%;
        }
    }
    .extend,
    .close {
        font-size: 18px;
        @include md {
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
        }
    }
    .close { 
        margin-left: 5px; 
        i {
            font-size: 20px; 
            font-weight: 800;
            margin-left: 5px; 
        }
        @include md {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            i {
                font-size: 12px;
            }
        }

        @include xs {
            font-size: 10px;
            line-height: 16px;

            i {
                font-size: 10px;
            }
        }
    }
}
.main-notification{
    position: absolute;
    top: 110px;
    right: 30px;
    z-index: 2;
.notification-msg{ 
    border-radius: 8px;
    background: #fff;
    padding: 12px 18px;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transform: translateX(calc(100% + 30px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
    z-index: 500;
    max-width: 380px;
    margin-bottom: 10px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include md {
        top: 100px;
        right: 10px;
        max-width: 95%;
    }
    &.active {
        transform: translateX(0%);
    }
    .strip{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 5px;
        height: 100%;
        background-color: var(--themeColor);
    }
    .notification-message {
        display: inline-block;
        width: 100%;
      }
      
      .notification-message .text {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        color: #666666;
      }
      
      .notification-message .text.text-1 {
        font-weight: 600;
        color: #333;
        font-size: 18px;
        line-height: 20px; 
      }
      
  .close {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 5px;
        cursor: pointer;
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
        i{
            font-size: 18px;
            color: #000;
        }
      } 
      
}
}
.padder-15 {
    padding: 3rem 0rem;

    @include md {
        padding: 2rem 0rem;
    }
}

.autocomplete-dropdown {
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
    overflow: hidden;

    .autocomplete-dropdown-selected {
        background-color: rgb(0 0 0 / 10%);
    }

    .item-content {
        padding: 0px;

        .item-inner {
            transition: 0.5s;
            padding: 10px 15px;

            @include md {
                padding: 5px 15px;
            }

            .item-title {
                font-size: 18px;
                font-weight: 500;

                @include md {
                    font-size: 12px;
                }

                b {
                    font-weight: 600;
                }
            }

            &:hover {
                background-color: rgb(0 0 0 / 10%);
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// DesignGuideLines Css Start
.block-title span {
    font-size: 22px;
    color: #000000;
    font-weight: 400;
    border-bottom: solid 2px #000;
    display: inline-block;
    padding-bottom: 2px;
    line-height: 30px;
}

.colorShade [class*="col"] span {
    color: var(--black);
    font-weight: 700;
}

.colorShade [class*="col"] div[class*="bg"] {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    height: 70px;
}

.colorShade [class*="col"] div[class*="br"] {

    border-radius: 20px;
    height: 70px;
}

.b-right {
    border-left: solid 15px #22B573;
}

.errorCard {
    display: flex;
    justify-content: center;
    width: 100%;
    border: solid 1px var(--yellow);
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;

    .errorIcon {
        width: 70px;
        display: inline-flex;
        justify-content: center;
        color: #fff;
        padding: 15px 5px;

        @include md {
            width: 55px;
            padding: 10px 5px;
        }

        i {
            font-weight: 500;
            font-size: 30px;

            @include md {
                font-size: 24px;
            }
        }
    }

    .errorText {
        width: 94%;
        display: inline-flex;
        padding: 5px 15px;
        align-items: center;

        @include sm {
            padding: 5px 10px;
        }

        span {
            font-size: 13px;
            line-height: 17px;
            font-weight: 400;
            color: #000;

            @include md {
                font-size: 10px;
                line-height: 14px;
            }

            b {
                font-weight: 500;
            }
        }

        @include md {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &.errorSec {
        .errorIcon {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            @include md {
                i {
                    font-size: 35px;
                }
            }
        }

        .errorText {
            width: auto;
            display: inline-flex;
            padding: 0px 0px 0px 22px;
            align-items: center;

            @include md {
                padding: 0px 0px 0px 8px;
            }

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #4A4A4A;
            }
        }

    }
}

.profileErrorCard {
    width: 100%;
    margin: 80px auto 25px;

    .errorSec {
        border: 0px;
        margin: 0px 0px 40px 0px;
    }
}

.walletErrorCard {
    width: 70%;
    margin: 30px auto 10px;

    .errorCard {
        margin: 0px;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 9px 15px;
        border-radius: 13px;

        .errorText {
            // width: 100%;
            padding: 10px 0px 0px 0px;
        }
    }
}

// DesignGuideLines Css End
// Home Page Css Start
.CardMain {
    display: flex;
    flex-flow: column;

    @include xl {
        padding: 0px 2rem;
    }

    @include lg {
        padding: 0px 20px;
    }

    @include xs {
        padding: 0px 10px;
    }

    .order-1 {
        order: 1;
        padding: 0px;

        @include md {
            order: 2;
        }
    }

    .order-2 {
        order: 2;

        @include md {
            order: 1;
        }
    }

}

.themeLink {
    display: inline-block;
    font-size: 15px;
    color: var(--black);

    @include md {
        font-size: 13px;
    }

    &:hover,
    &:focus {
        color: var(--green);
    }
}

.MobileInput {
    position: relative;

    .formInput {
        .item-content {
            .item-input-wrap {
                input {
                    padding-left: 80px;
                }
            }
        }
    }

    &.digit-1 {
        .formInput {
            .item-content {
                .item-input-wrap {
                    input {
                        padding-left: 80px;
                    }
                }
            }
        }
    }

    &.digit-2 {
        .formInput {
            .item-content {
                .item-input-wrap {
                    input {
                        padding-left: 90px;
                    }
                }
            }
        }
    }

    &.digit-3 {
        .formInput {
            .item-content {
                .item-input-wrap {
                    input {
                        padding-left: 100px;
                    }
                }
            }
        }
    }

    &.digit-4 {
        .formInput {
            .item-content {
                .item-input-wrap {
                    input {
                        padding-left: 110px;
                    }
                }
            }
        }
    }

    &.digit-5 {
        .formInput {
            .item-content {
                .item-input-wrap {
                    input {
                        padding-left: 120px;
                    }
                }
            }
        }
    }

    .selectArea {
        position: absolute;
        top: 32px;
        z-index: 5;
        padding: 0px 10px;
        height: 33px;
        display: flex;
        align-items: center; 

        .value {
            font-size: 14px;
            margin-left: 5px;
            font-weight: 600;
        }

        .item-title {
            display: none;
        }

        .item-after {
            display: flex;
            max-width: 100%;
            align-items: center;
            cursor: pointer;

            .flag {
                display: flex;
                align-items: center;
                width: 35px;
                height: 25px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include md {
                    width: 25px;
                    height: 20px;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0px;
            bottom: 0px;
            margin: auto 0;
            right: 0px;
            width: 2px;
            height: 60%;
            background-color: rgb(0 0 0 / 25%);
        }
    }

    .select-search-container {
        position: absolute;
        top: 35px;
        z-index: 5;
        padding-right: 3px;
        left: 3px;
        width: 65px;

        @include md {
            top: 33px;

        }

        &::after {
            content: '';
            position: absolute;
            top: 0px;
            bottom: 0px;
            margin: auto 0;
            right: 5px;
            width: 2px;
            height: 80%;
            background-color: rgb(0 0 0 / 25%);
        }

        .select-search-input {
            height: 24px;
            padding: 5px;
            background: transparent;
            border: 0px;
            text-align: center;
            font-size: 14px;

            @include md {
                height: 24px;
                font-size: 12px;
            }
        }

        .select-search-select {
            min-width: 440px;
            max-height: 260px;
            box-shadow: 0px 5px 9px 2px rgb(0 0 0 / 15%);
            border: 0;
            top: 38px !important;

            @include md {
                min-width: 310px;
            }

            .select-search-option,
            .select-search-not-found {
                height: 35px;

                @include md {
                    font-size: 14px;
                }
            }

            .select-search-is-highlighted,
            .select-search-option:not(.select-search-is-selected):hover {
                background: #E9E9E9;
                color: #000;
            }

            .select-search-is-selected {
                color: #000;
            }
        }
    }


}


// Home Page Css End
// loginScreenView Start
.loginScreenView {
    border-radius: 22px;
    width: 600px;
    margin: 0 auto;
    background: #fff;
    overflow: hidden;

    @include md {
        width: 100%;
    }

    .linkMain {
        &.popup-close {
            opacity: 0;
        }
    }

    .cardfooter {
        padding: 10px 0px 30px 0px;
    }

    .MobileInput {
        .select-search-container {
            .select-search-select {
                min-width: 470px;
            }
        }
    }

}

.mainModelLogin {
    display: flex;
    position: relative;
}

.modelLogin {
    width: 100%;
    transition: all 0.5s linear;
    transform: translateX(100%);
    position: absolute;
    visibility: hidden;
}

.back_login {
    transform: translateX(-100%);
    position: absolute;
    visibility: hidden;
}

.open_login {
    transform: translateX(0%);
    position: inherit;
    visibility: visible;
}

.information {
    max-width: 70%;

    .info {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: var(--black);
    }

    ol {
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;

        li {
            font-weight: 500;
            font-size: 12px;
            line-height: 168%;
            color: var(--black);
            list-style: devanagari;
        }
    }
}

// loginScreenView End
// Back
.backBtn {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;

    @include md {
        margin-bottom: 15px;
    }

    .btn-green {
        display: inline-flex;
        align-items: center;
        color: #000;
        border-color: var(--green);
        padding: 0px;
        height: auto;

        i {
            font-size: 20px;
            font-weight: 800;

            @include md {
                font-size: 18px;
            }
        }

        .label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-transform: uppercase;

            @include md {
                font-size: 12px;
            }
        }
    }
}

.customBtn {
    font-size: 16px;
    height: 45px;
    line-height: 42px;
    margin: 10px 0px;

    @include md {
        font-size: 14px;
        height: 35px;
        line-height: 37px;
    }
}

// Main Loader Css Start
//   Button Loader 

.smallLoader {
    width: 30px;
    height: 30px;
    border: 4px solid rgba(255, 255, 255, .3);
    border-bottom-color: #fff;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;

    &.smallTheme {
        width: 24px;
        height: 24px;
        border-width: 4px;
        border-color: rgba(var(--greenRgba), 0.4);
        border-bottom-color: var(--green);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(-360deg)
    }
}

.formInput {
    .smallLoader {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 38px;
        right: -23px;
        border: solid 3px rgba(var(--greenRgba), 0.4);
        border-bottom-color: var(--green);

        @include lg {
            top: 34px;
        }
    }
}

//   Input Loader 
.inputLoader {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    border: 0.2em dotted var(--green);
    border-radius: 50%;
    animation: 1s inputLoader linear infinite;
    font-size: 23px;
    position: absolute;
    top: 38px;
    right: -10px;

    @include md {
        top: 36px;
    }
}

@keyframes inputLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

//   Main Page  Loader
.main-Loader {
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        animation: rotate 1s linear infinite;
        background: var(--Mainloader);
        border-radius: 50%;
        height: 138px;
        width: 138px;
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
        }

        &::before {
            border-radius: 50%;
            background:
                linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0% 0%,
                linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0%,
                linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
                linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
            background-repeat: no-repeat;
            background-size: 50% 50%;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
        }

        &::after {
            background: #fff;
            border-radius: 50%;
            top: 8%;
            bottom: 8%;
            left: 8%;
            right: 8%;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

//   Smart Select Custom Css Start
@media (min-width: 630px) and (min-height: 630px) {
    .popup:not(.popup-tablet-fullscreen) {
        &.smart-select-popup {
            width: var(--f7-popup-tablet-width);
            left: 50%;
            top: 18%;
            margin-left: calc(-1 * var(--f7-popup-tablet-width)/ 2);
            margin-top: calc(-1 * var(--f7-popup-tablet-height)/ 2);
            box-shadow: var(--f7-popup-box-shadow);
            border-radius: 22px;
            height: 600px;
            overflow: auto;


        }
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1500px) {
    .popup:not(.popup-tablet-fullscreen) {
        &.smart-select-popup {
            top: 50px;
            height: 550px;
        }
    }
}

.md {
    .searchbar {

        input[type=search],
        input[type=text] {
            padding-left: 55px;
        }
    }
}

.popup {
    &.smart-select-popup {
        .navbar~* {
            --f7-page-navbar-offset: calc(70px + var(--f7-safe-area-top));
        }

        .navbar {
            height: 60px;

            .navbar-bg {
                background-color: #fff;
                border-bottom: solid 1px rgba(0, 0, 0, .2);
            }

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 30px;
                color: var(--themeColor);
                text-transform: uppercase;
            }

            .right {
                height: 50%;

                .popup-close {
                    font-size: 14px;
                    color: var(--blacktone);
                    text-transform: uppercase;
                    font-weight: 500;
                    height: 100%;

                    &:hover,
                    &:focus {
                        color: var(--green);
                    }

                    i {
                        font-size: 18px;
                        margin-left: 2px;
                        font-weight: 600;
                    }

                    @include md {
                        font-size: 12px;

                        i {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .searchbar {

            input[type=search],
            input[type=text] {
                font-size: 14px;
                font-weight: 500;
            }

            .input-clear-button {
                z-index: 55;

                &::before {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0%;
                    top: 0%;
                }

                &:after {
                    font-size: 20px;
                }
            }
        }
        .page-content{
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
        .list {
            margin: 0px;
            margin: 0px;
            display: flex;
            flex: 1;
            overflow: auto;
            &::-webkit-scrollbar-thumb{
                background-color: #c0c0c0;
            }
            &::-webkit-scrollbar-track{
                background-color: #fff;
            }
            ul{
               &::after{
                content: none;
               }
                width: 100%;
            // @include smd{
            //         max-height: calc(100vh - 118px);
            //         overflow-y: auto;
            //     }
            }

            .disabled {
                opacity: 1 !important;
                pointer-events: none !important;
                background-color: #D5D4D4;
                color: #868686;
                cursor: not-allowed;

                label.item-radio,
                label.item-radio.disabled {
                    opacity: 1 !important;
                    pointer-events: none !important;
                    cursor: not-allowed;
                }
            }

            .item-divider,
            li.item-divider {
                height: 30px;
                font-size: 16px;
                font-weight: 600;

            }

            .item-content {
                padding: 0px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);                
                .icon {
                    display: none;
                }
                .item-media {
                    width: auto;
                    height: 48px;
                    padding-left: 18px;
                    img {
                        width: 40px;
                        height: auto;
                    }
                }

                .item-inner {
                    margin-left: 0px;
                    padding: 0 20px;
                    font-size: 14px;
                    &::after{
                        content: none;
                    }
                }
            }

            label.item-radio input[type=radio]{
                &:checked~{
                    .item-inner, .item-media {
                        background: #E9E9E9;
                        transition: 0.5s;
                    }
                }

                .item-title {
                    color: #000;
                    font-weight: 600;
                }
            }

            //  doing for set font size bold in new_vehicle or new_address or new_card in passlist :: 31-10-2023
            label.item-radio input[type=radio][value="new_vehicle"]~ .item-inner {
                .item-title {
                    color: #000;
                    font-weight: 600;
                }
            }
            
            label.item-radio input[type=radio][value="new_card"]~ .item-inner {
                .item-title {
                    color: #000;
                    font-weight: 600;
                }
            }

            label.item-radio input[type=radio][value="new_address"]~ .item-inner {
                .item-title {
                    color: #000;
                    font-weight: 600;
                }
            }

        }
    }

}

.ios {
    .popup {
        &.smart-select-popup {
            .searchbar {
                .input-clear-button:after {
                    font-size: 10px;
                }

                .searchbar-disable-button {
                    color: var(--blacktone);
                    font-size: 14px;
                }
            }
        }
    }
}

//   Smart Select Custom Css End
.notFound {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
    }
}

// Datepicker Start
.calendar-sheet,
.calendar-popover {
    .toolbar {
        a {
            color: var(--themeColor);
            font-size: 14px;
            font-weight: 500;
        }
    }

    .calendar-day.calendar-day-today.calendar-day-selected .calendar-day-number {
        background-color: #fff;
        color: var(--themeColor);
        font-weight: 700;
    }

    .calendar-day.calendar-day-selected {
        align-items: center;
    }

    .calendar-day.calendar-day-selected-left,
    .calendar-day.calendar-day-selected-right {
        position: relative;
    }

    .calendar-day.calendar-day-selected-left {
        &::after {
            content: '';
            position: absolute;
            right: 0px;
            width: 50%;
            background-color: rgba(var(--yellowshadeRgba), 0.2);
            height: var(--f7-calendar-day-size);
            z-index: -1;
        }
    }

    .calendar-day.calendar-day-selected-right {
        &::after {
            content: '';
            position: absolute;
            left: 0px;
            width: 50%;
            background-color: rgba(var(--yellowshadeRgba), 0.2);
            height: var(--f7-calendar-day-size);
            z-index: -1;
        }
    }

    .calendar-day.calendar-day-selected-left .calendar-day-number,
    .calendar-day.calendar-day-selected-right .calendar-day-number,
    .calendar-day.calendar-day-selected .calendar-day-number {
        display: inline-block;
        border-radius: 50%;
        position: relative;
        width: var(--f7-calendar-day-size);
        height: var(--f7-calendar-day-size);
        line-height: var(--f7-calendar-day-size);
    }

    .calendar-day.calendar-day-selected-left.calendar-day-selected .calendar-day-number,
    .calendar-day.calendar-day-selected-right.calendar-day-selected .calendar-day-number {
        font-weight: 700;
    }

    .calendar-day.calendar-day-selected-left.calendar-day-selected .calendar-day-number,
    .calendar-day.calendar-day-selected-right.calendar-day-selected .calendar-day-number,
    .calendar-day.calendar-day-selected .calendar-day-number {
        background-color: var(--yellow);
        color: #fff;
    }

    .calendar-day.calendar-day-selected.calendar-day-selected-range .calendar-day-number {
        background-color: rgba(var(--yellowshadeRgba), 0.2);
        color: #000;
        width: 100%;
        height: auto;
        border-radius: 0px;
    }
}
.calendar-body{
    .popup-backdrop{
        opacity: 1;
        visibility: inherit;
    }
}
// Datepicker end

// Enterprice Start

.enterPrice {
    padding: 50px 20px;
    @media (min-width: 1200px){ 
        &.enterPrice {
            max-width: 900px;
        }
    }
    &.max-height {
        min-height: calc(80vh - 120px);
    }

    .smallHeading {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: #515151;
        margin: 15px 0px 0px 0px;

        &.text-green {
            color: var(--green);
        }
    }

    .enterPriceCard {
        display: flex;
        justify-content: inherit;
        flex-wrap: wrap;
        align-items: flex-start;
        --f7-cols-per-row: 1;
        margin: 0px -10px;
        .grid{
            width: 25%;
            padding:0px 10px;
            @include md { 
                width: 50%;
            }
        }
    }

    a {
        &.card { 
            &:hover,
            &.active {
                box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, .1);
                border: 2px solid transparent;
                background: linear-gradient(to right, white, white), var(--paymentCard);
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                transition: 0.5s;
            }
        }
    }

    .card {
        background: #F2F2F2;
        border-radius: 15px;
        box-shadow: inherit;
        display: inline-block;
        width: 100%;
        padding: 15px 10px 10px 10px;
        margin: 0px;
        transition: 0.5s;
        border: 2px solid transparent; 
        margin: 15px 0px;
      
        .parkImg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 90px;
            background-color: #fff;
            padding: 5px;
            border-radius: 50%;
            margin: 0 auto;
            overflow: hidden;
            box-shadow: 2px 2px 4px #1E2926;
            -webkit-box-shadow: 2px 2px 4px #1E2926;
            -moz-box-shadow: 2px 2px 4px #1E2926;
            @include md { 
                width: 70px;
                height: 70px;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }

        .card-info {
            text-align: center;
            padding-top: 15px;

            .mainTitle {
                font-weight: 600;
                font-size: 14px;
                color: #515151;
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include md { 
                    font-size: 12px;
                }
            }

            .placeName {
                font-size: 14px;
                line-height: 18px;
                color: #515151;

                @include md { 
                    font-size: 12px;
                }
            }
        }
    }
}


// Enterprice End
.pt-0 {
    padding-top: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.zoom-in-out-box {
    animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0.8, 0.8);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(0.8, 0.8);
    }
}

.calendar-year-picker {
    overflow: unset;
}

.menu-dropdown-link {
    &::before {
        background-color: #fff;
    }
}

//   quantity plus minus Value Start
.qtyInput {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .btn {
        display: flex;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        padding: 0px;
        color: #fff;
        text-align: center;
        border: 0px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        min-width: 22px;
        min-height: 22px;
        &.button-minus {
            font-size: 39px;
            font-weight: 200;
            line-height: 16px;
            text-align: center;
            background-color: #868686;
        }

        &.button-plus {
            font-size: 23px;
            font-weight: 500;
            line-height: unset;
            text-align: center;
            background-color: #000000;
            align-items: center;
        }

        &.disabled {
            background-color: #868686;
            opacity: 1 !important;
        }

        &.enabled {
            background-color: #000000;
        }

        @include md {
            width: 20px;
            height: 20px;

       
            &.button-plus {
                font-size: 23px;
                line-height: 20px;
            }
        }
    }

    .count,
    input[type=number].count {
        color: #000;
        font-size: 19px;
        font-weight: 700;
        line-height: 23px;
        padding: 0;
        text-align: center;
        width: 45px;
        display: inline-block;
        margin: 0px 5px;
        border-radius: 0px;
        border: solid 1px rgba(0,0,0,0.2);

        @include md {
            font-size: 16px;
        }
    }
}

//   quantity plus minus Value End
.Custom-breadcrumb {
    padding: 0px 20px;

    @include md {
        padding: 0px 15px;
    }

    .Custombreadcrumb {
        display: flex;
        justify-content: space-between;

        .breadcrumb {
            display: flex;
            align-items: center;
        }

        .backBtn {
            display: inline-flex;
            width: auto;
            margin: 0px;

            a {
                padding: 8px 10px 8px 0px;
            }

            .btn-green {
                color: #fff;
                svg{
                    margin-right: 10px;
                    width: 10px;
                    height: 15px;
                }
                i {
                    font-size: 18px;
                }
            }
        }

        .breadcrumb {
            ol {
                padding: 0px;
                margin: 0px;
                display: flex;

                .breadcrumb-item+.breadcrumb-item::before {
                    color: #fff;
                    content: "/";
                    display: inline-block;
                    font-size: 13px;
                    font-weight: 300;
                    padding-right: 5px;
                    line-height: 20px;
                    position: absolute;
                    left: 0px;
                }

                .breadcrumb-item {
                    display: flex;
                    position: relative;
                    align-items: center;

                    .text {
                        display: inline-block;
                        width: auto;
                        max-width: 80px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        color: #fff;
                        padding: 6px 5px 6px 10px;

                        // text-transform: capitalize; 
                        @include md {
                            font-size: 12px;
                        }
                    }

                    span.text {
                        font-weight: 600;
                    }

                    a {
                        font-weight: 400;
                    }

                }
            }
        }
    }
}

// Price popup
.priceInfo {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .InfoIcon {
        margin-left: 5px;
        display: flex;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .priceListing {
        position: absolute;
        top: 30px;
        min-width: 300px;
        background: #FFFFFF;
        border: 1px solid #D7D7D7;
        border-radius: 5px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        z-index: 1;
        left: -70px;

        @media only screen and (min-width: 200px) and (max-width: 375px) {
            left: -100px;
        }
        @include sm{
            min-width:269px;
        }

        .tooltip__arrow {
            width: 50px;
            height: 25px;
            position: absolute;
            top: -25px;
            left: 58px;
            transform: rotate(180deg);
            overflow: hidden;

            @media only screen and (min-width: 200px) and (max-width: 375px) {
                left: 88px;
            }

            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background: white;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                top: 0;
                left: 50%;
            }
        }

        .innerListing {
            padding: 10px 0px 0px 0px;
            display: table;
        }

        .Innerlisting {
            display: table-row;
            justify-content: space-between;
            .labelText,
            .labelMain {
                display: table-cell;
                font-size: 14px;
                line-height: 20px;
                padding: 3px 15px;
                @include md {
                    font-size: 10px;
                    line-height: 15px;
                }
            }

            .labelText {
                font-weight: 500;
                color: #000000;
                width: 100%;
            }

            .labelMain {
                font-weight: 400;
                color: #4A4A4A;
                // width: 20%;
            }

            &.totalPrice {
                border-top: solid 2px #E2E2E2;
                padding: 10px 15px;
                margin-top: 5px;
                // border-bottom: solid 2px #E2E2E2;
                background-color:rgba(241, 241, 241, 0.92);
                .labelText,
                .labelMain {
                    font-size: 16px;
                    font-weight: 600;
                    color: #008157;
                    width: max-content;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-top: 2px solid rgb(226, 226, 226);
                    @include md {
                        font-size: 14px;
                        line-height: 15px;
                    }
                    @include sm {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }

                .labelMain {
                    color: var(--themeColor);
                }
            }
        }

        .taxIncludedtotalprice{
            display: table-row;
            justify-content: space-between;
            padding: 10px 15px 0px 15px;
            background-color:rgba(241, 241, 241, 0.92);
            &:last-child{
                padding: 10px 15px 15px 15px;
            }

            .labelText{
                width: 80%;
                font-size: 14px;
                line-height: 20px;
                color: #000;
                font-weight: 500;
                @include md {
                    font-size: 10px;
                    line-height: 15px;
                }
            }
            
            .labelMain {
                font-weight: 400;
                color: #4A4A4A;
                width: 20%;
                font-size: 14px;
                line-height: 20px;
                @include md {
                    font-size: 10px;
                    line-height: 15px;
                }
            }
            .labelMain, .labelText{                
                display: table-cell;
                padding: 10px 15px;
                border-top: 2px solid rgb(226, 226, 226);
            }            
        }
    }
}

// Price popup End
// Not Found Page Start
.notFoundPage {
    padding: 100px 15px 50px 15px;
    text-align: center;

    @include lg {
        padding: 60px 15px 50px 15px;
    }

    .tittle {
        font-size: 200px;
        font-weight: 700;
        line-height: 180px;
        background: linear-gradient(180deg, #FDB71A -59.09%, #799B3A 140.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin: 0px 0px 20px;

        @include lg {
            font-size: 100px;
            line-height: 80px;
        }
    }

    .smallText {
        font-weight: 600;
        font-size: 60px;
        line-height: 70px;
        color: #868686;
        margin: 0px 0px 20px;

        @include lg {
            font-size: 35px;
            line-height: 40px;
        }
    }

    .infoText {
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: #868686;
        margin: 0px auto;
        width: 50%;

        @include lg {
            font-size: 20px;
            line-height: 25px;
            width: 80%;
        }
    }

    .actionButton {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        @include lg {
            margin-top: 30px;
        }

        .button {
            min-width: 200px;
            margin: 0px 15px;
            height: 40px;
            line-height: 37px;

            @include md {
                min-width: 45%;
                margin: 0px 10px;
                font-size: 14px;
                height: 35px;
                line-height: 37px;
            }

        }
    }
}

.cancelPass {
    padding: 20px 15px 70px;
    text-align: center;

    @include lg {
        padding: 20px 15px 30px;
    }

    .smallText {
        font-weight: 900;
        font-size: 34px;
        line-height: 159.5%;
        color: #666666;
        margin: 0px 0px 28px;

        @include lg {
            font-size: 25px;
            line-height: 159.5%;
            margin: 0px 0px 5px;
        }
    }

    .infoText {
        font-weight: 400;
        font-size: 18px;
        line-height: 159.5%;
        color: #000000;
        margin: 0px auto;
        width: 45%;

        @include lg {
            font-size: 15px;
            line-height: 159.5%;
            width: 80%;
        }
    }

    .actionButton {
        display: flex;
        justify-content: center;
        margin-top: 28px;

        @include lg {
            margin-top: 20px;
        }

        .button {
            min-width: 250px;
            margin: 0px 15px;
            height: 48px;
            line-height: 37px;
            font-size: 18px;

            @include md {
                min-width: 45%;
                margin: 0px 5px;
                font-size: 14px;
                height: 35px;
                line-height: 37px;
            }

        }
    }
}

// Not Found Page End
// Page Note Screen Start
.mb-0 {
    margin-bottom: 0px !important;
}

.noteScreen {
    padding: 70px 15px 30px 15px;
    text-align: center;

    @include lg {
        padding: 40px 15px 30px 15px;
    }

    &.max-height {
        min-height: calc(93vh - 248px);

        @include md {
            min-height: calc(97vh - 260px);
        }
    }

    .tittle {
        font-size: 55px;
        line-height: 159.5%;
        font-weight: 900;
        background: var(--bannerShadow);
        //background: linear-gradient(180deg, #FDB71A -59.09%, #799B3A 140.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin: 0px 0px 10px;

        @include lg {
            font-size: 25px;
            line-height: 159.5%;
        }

    }

    .smallText {
        font-weight: 400;
        font-size: 25px;
        line-height: 159.5%;
        color: #000;
        width: 51%;
        margin: 0 auto;

        strong {
            color: var(--themeColor);
            font-weight: 700;
        }

        @include lg {
            font-size: 15px;
            line-height: 159.5%;
            width: 80%;
        }

        @include sm {
            width: 90%;
        }
    }
}

// Page Note Screen End
/* scrollbar */
.walletMain .themeInformation .card-body::-webkit-scrollbar,
.instructionInfo::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.walletMain .themeInformation .card-body::-webkit-scrollbar-track,
.instructionInfo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.walletMain .themeInformation .card-body::-webkit-scrollbar-thumb,
.instructionInfo::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.walletMain .themeInformation .card-body:window-inactive,
.instructionInfo:-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

// language Css Start
.themeHeader{
    .navbar-inner{
        .headerMenu{
            .wg-drop.country-selector{
                width: max-content !important;
            }
            .language {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg{
                    position: absolute;
                    top: 21px;
                    z-index: 1;
                    @include md{
                        top: 23px;
                        width: 22px;
                        height: auto;
                    }
                    @include sm{
                        top: 28px;
                        width: 21px;
                    }
                }
                .weglot-container{
                    z-index: 0;
                }
                .wg-drop.country-selector {
                    &.closed .wgcurrent:after {
                        transform: rotate(0deg);
                    }
                    .wgcurrent {
                        border: 0px;
                        &::after {                
                            transform: rotate(-180deg);
                            cursor: pointer;
                            content: none !important;
                        }
                        a{
                            padding: 38px 25px 0;
                            color: var(--headerlinkColor);
                            @include md{
                                padding: 28px 25px 0;
                            }
                            &::before{
                                position: absolute;
                                content: "";
                                background: url(../image/language.svg);
                                width: 26px;
                                height: 25px;
                                top: 7px;
                                left: 50%;
                                transform: translateX(-50%);
                                background-size: 100%;
                                @include md{
                                    width: 19px;
                                    height: 18px;
                                    top: 6px;
                                }
                            }
                            @include sm{
                                padding: 26px 25px 0;                                
                            }                         
                        }

                    }

                    ul {
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, .2);
                        min-width: 70px;
                        top: inherit;
                        margin: 17px 0;
                        width: max-content;
                        left: 60%;
                        right: unset;
                        transform: translateX(-50%);
                        &::-webkit-scrollbar{
                            width: 10px;
                            height: 10px;
                            border-radius: 10px;
                            &-thumb{
                                background-color: #c1c1c1;
                            }
                            &-track{
                                background-color: #f1f1f1;
                            }
                        }
                        li {
                            a {
                                margin: 0;
                                padding: 10px 15px;
                                width: 100%;
                                justify-content: flex-start;
                                align-items: flex-start;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                
                &:hover, &:focus{
                    svg{
                        path{
                            stroke: var(--headerlinkHoverColor);
                        }
                    }
                }
                
                .wg-drop.country-selector .wgcurrent {
                    a{
                        &:hover,&:focus, &.active{
                            color: var(--headerlinkHoverColor);
                            &::before{
                                filter: brightness(0) saturate(100%) invert(24%) sepia(42%) saturate(4758%) hue-rotate(151deg) brightness(94%) contrast(101%);
                            }
                        }
                    } 
                
                }
            }
        }
    }
}

// language Css End
// New Date Picker
.newDatePicker{
    display: flex;
    align-items: center;
    position: relative;
    .item-label {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: #4A4A4A;
        margin-bottom: 0px; 
        padding-right: 15px;

        @include md {
            font-size: 12px;
            line-height: 14px;
        }
    }
    .react-datepicker-wrapper{
       
        .react-datepicker__input-container {
            position: relative;
            display: flex;
            align-items: center;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../image/calendar.svg');
                width: 16px;
                height: 16px;
                background-size: cover;
            }
    }
   .datePickerInner {
        cursor: pointer;
        font-size: 14px;
        transition: 0.5s;
        font-weight: 500; 
        text-decoration: underline;
        height: auto;
        padding: 10px 10px 10px 25px;
        border: 0px;
        color: #000;
        // width: 60%;
        background-color: transparent;
        &:focus {
            border-color: var(--green);
        }

        @include md {
            height: 35px;
        }

        @include lg {
            font-size: 11px;
            height: auto;
           
        }
    }
} 
.react-datepicker-popper{
    padding: 0px !important;
    z-index: 3;
    .react-datepicker__triangle{
        display: none;
    }
    .react-datepicker{
        transition-duration: .3s;
        background-color:#fff;
        border-radius: var(--f7-popover-border-radius);
        box-shadow: var(--f7-popover-box-shadow);
        will-change: transform,opacity;
        border: 0;
        .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
            border-color: var(--green);
        }
        .react-datepicker__current-month, 
        .react-datepicker-time__header, 
        .react-datepicker-year-header {
            margin-top: 0;
            color: #000; 
            font-size: 14px;
            font-weight: 500;
            font-family: 'Poppins', sans-serif;
        }
        .react-datepicker__day--disabled,
         .react-datepicker__month-text--disabled, 
         .react-datepicker__quarter-text--disabled,
          .react-datepicker__year-text--disabled {
            cursor: default;
            color: #d4d4d4 !important;
        }
      
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            width: 40px; 
        }
       .react-datepicker__day{ 
            height: 40px;
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name { 
            color: #000;
            line-height: 36px;
            font-size: 14px;
        }
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
            border-radius: 50px;
            background-color: var(--yellow);
            color: #fff;
        }
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
        .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
            border-radius: 50px; 
        }
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
                background-color: rgba(var(--greenRgba), 0.5);
        }
        .react-datepicker__header{
            background: #f7f7f8;
            border: 0px;
            border-radius: 13px 13px 0px 0px;
            .react-datepicker__current-month{
                color: var(--themeColor);
            }
        }
    }
   
}
}
.datefooter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
}
.calender-body{
   
    .page{
        position: relative;
        .page-content{
            position: initial;
        }
        .calender-backdrop{ 
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);
            z-index: 111111;
        }
        .newDatePicker .react-datepicker-popper{
            z-index: 1111111;
            @include md {
                transform: inherit !important;
                inset: 30px 0px 0px 0px !important;
                margin: 0 auto;
                display: grid;
                justify-content: center;
            }
        }
    }
}
.fullfillment{
    display: flex;
    align-items: center;
    img{
        max-width: 95%;
    }
}
.ulList {
    list-style: disc;
    padding-left: 40px;
    text-align: left;
}

.ulList li {
    list-style: disc;
}
.form-check{
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.form-check .form-check-input {
    width: 16px;
    height: 16px; 
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #868686;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    margin: 0px 8px 0px 0px;
    border-radius: 3px;
}
.form-check .form-check-input:checked{  
    background: rgba(74, 74, 74, 0.13);
}
.form-check .form-check-input:checked[type=checkbox] {
    background-image: url('../image/check-icon.svg');
}
.form-check .form-check-label{
    color: #000; 
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.form-check .form-check-input:disabled{
    pointer-events: none;
    filter: none;
    opacity: .5;
}
.form-check .form-check-input:disabled+.form-check-label {
    opacity: .5;
}
// New Datepicker Daily-Passes Start
.dateMain{
    width: 100%;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    margin-top: 10px;
    @include md {
        border-radius: 5px;
    }
    &.backDateMain {
        position: absolute;
        visibility: hidden;
    }
    .dateHeader{
        border-radius: 8px 8px 0px 0px;
        background-color: rgba(241, 241, 241, 0.92);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @include md {
            border-radius: 5px 5px 0px 0px;
        } 
        .month{
            color: #000; 
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 100%;
            text-align: center;
            padding: 0 40px;
            @include md {
                font-size: 12px;
            }
        } 
        .btnDate{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border: 0px;
            background-color: #fff;
            border-radius: 50px; 
            box-shadow: 0px 0px 4.7px 0px rgba(0, 0, 0, 0.17);
            cursor: pointer;
            position: absolute;
            &.prev{
                left: 12px;
            }
            &.next{
                right: 12px;
            }
            svg{
                height: 16px;
                @include md {
                    height: 12px;
                }
            }
            @include md {
                font-size: 12px;
                width: 20px;
               height: 20px;
               min-width: 20px;
                padding: 0px;
            }
        }  
    }
    .dateMainOuter{
        display: flex;
        position: relative; 
    }
    .soldOut{
        display: inline-block;
        width: 100%;
        text-align: center;
        top: -8px;
        position: relative;
        z-index: 1;
    .btn-outline-danger{ 
        color:  #AD0000; 
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: solid 2px #AD0000; 
        border-radius: 12px;
        padding: 3px 30px;
        background-color: #fff; 
    }
}
    .dateBody{
        padding:  5px 0px;
        display: flex; 
        flex-flow: row wrap; 
        .datelist{
            justify-content: center;
            display: flex;
            flex-flow: column;
            align-items: center; 
            width: 16.5%;
            margin: 5px 0px;
            .day{
                display: inline-block;
                width: 100%;
                color:#868686; 
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                @include md {
                    font-size: 10px;
                }
            }
            .date{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                background-color: #E7E7E7;
                color: #000; 
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                border-radius: 50px;
                outline: 0;
                border: 0;
                padding: 0px;
                cursor: pointer;
                transition: 0.5s;
                &:hover,
                &:focus,
                &.active{
                    background-color: var(--yellow);
                }
                @include md {
                    width: 24px;
                    height: 24px;
                    font-size: 10px;
                }
            }
        } 
            width: 100%;
            transition: all 0.5s linear;
            transform: translateX(100%);
            position: absolute;
            visibility: hidden; 
            &.openDate{
                transform: translateX(0%);
                position: inherit;
                visibility: visible;
            }
            &.backDate {
                transform: translateX(-100%);
                position: absolute;
                visibility: hidden;
            }
    }
}  
.dateTitle{
    margin-bottom: 10px;
    .dateHeading{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .icons{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background: #FFF;
            margin-right: 15px;
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, .08);
        }
        .title{
            color: #000; 
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0px;
        }
    }
    .infoText{
        color: #000; 
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
    }
}
.newDateRangePicker {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;

    @include md {
        width: 100%;
        padding-right: 10px;
    }

    .newDateInner {
        width: 500px;
        position: relative;

        @include md {
            width: 100%;
        }
    }

    .dateHeading {
        margin-bottom: 5px;
        display: flex;
        text-align: left;
        justify-content: start;
        width: 100%;

        .label {
            color: var(--Yodel-General-Styles-Text-Color---Field-Filled, #515151);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .toLabel {
            font-size: 0px;
            height: auto;
        }
    }

    .label {
        display: flex;
        align-items: center;
    }

    .toLabel {
        width: 15%;
        height: 33px;
        display: inline-flex;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--Yodel-Brand-Style-Guide-Yodel-Black, #231F20);
        justify-content: center;
        align-items: center;
    }

    .datePickerOutput {
        border: 0px;
        outline: 0px;
        color: var(--Yodel-Brand-Style-Guide-Yodel-Black, #231F20);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        text-align: center;
        background: transparent;
        border: 0px;
        outline: 0;
        font-family: "Poppins", sans-serif;
        padding: 0px;

        // display: flex;
        .date {
            display: inline-block;
            width: 100%;
            text-align: left;
            padding: 8px 15px;
            background-color: #fff;
        }
    }

    .datePickerOutput:focus-visible {
        outline: 1px solid #000;
    }

    .datePickerposition {
        position: absolute;
        z-index: 6;
        background: #FFF;
        border-radius: 10px;
        margin-top: 5px;
        top: 100px;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        width: 100%;  
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);      
        @include md{
            width: 100%;
            padding: 0 15px;
            max-width: 500px;
        }
        @media screen and (max-height:725px) {
            top: 20px;
        }
        @media screen and (max-height:625px) {
            top: -30px;
        }
        @media screen and (max-height:570px) {
            top: unset;
            bottom: -100px;
        }
        @media screen and (max-height:525px) {
            bottom: -70px;
        }

        // @include md {
        //     top: 0px;
        // }

        .date-footer {
            padding: 0px 15px 21px 15px;
            .dateRangeRow{
                justify-content: center;
                flex-wrap: nowrap;
                .button{
                    width: 100%;
                    max-width: 150px;
                    @include xsm{
                        margin: 0 8px;
                    }
                }
            }
            .customBtn {
                margin: 0;
                height: 35px;
                font-size: 14px;
                width: 100%;
                max-width: 150px;
                margin: 0 15px;
            }
        }
    }

    .react-datepicker {
        width: 100%;


        border: 0px;
        margin-top: 5px;

        .react-datepicker__navigation {
            border-radius: 30px;
            background: #FFF;
            box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.12);
            top: 10px;

            &.react-datepicker__navigation--previous {
                left: 10px;
            }

            &.react-datepicker__navigation--next {
                right: 10px;
            }

            .react-datepicker__navigation-icon--next,
            .react-datepicker__navigation-icon--previous {
                left: 0px;
                top: 0px;
            }

            .react-datepicker__navigation-icon--previous,
            .react-datepicker__navigation-icon--next {
                &::before {
                    border-color: #4A4A4A;
                    width: 10px;
                    height: 10px;
                    border-width: 2px 2px 0px 0px;
                }
            }
        }

        .react-datepicker__header {
            background-color: transparent;
            padding: 15px 0px 10px 0px;
            font-family: "Poppins", sans-serif;
            border-color: #ccc;

            @include sm {
                padding: 10px 0px 5px 0px;
            }

            .react-datepicker__current-month {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10px;

                @include sm {
                    margin-bottom: 5px;
                }

                @media (max-width: 350px) {
                    margin-bottom: 5px;
                }
            }

            .react-datepicker__day-name {
                color: #515151;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 30px;
                @include bp-350{
                    font-size: 13px;
                    line-height: 17px;
                }
            }

        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            display: inline-block;
            width: 30.7px;
            text-align: center;
            margin: 5px 10px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-weight: 500;
            font-family: "Poppins", sans-serif;
            @include md{
                width: 24.7px;
                height: 24.7px;
                line-height: 24px;
            }
            @include sm {
                margin: 2px 10px;
            }

            @include bp-350{
                width: 18.7px;
                height: 18.7px;
                line-height: 20px;
                font-size: 13px;
            }
        }

        .react-datepicker__month-container {
            width: 100%;
        }

        .react-datepicker__month {
            margin: 0.4rem 0rem;
            max-height: unset;
            overflow: auto;
            @media screen and (max-height :825px){
                max-height: calc(100vh - 640px);
            }
            @media screen and (max-height :725px){
                max-height: calc(100vh - 560px);
            }
            @media screen and (max-height:625px) {
                max-height: calc(100vh - 507px);
            }
            @media screen and (max-height:570px) {
                max-height: calc(100vh - 427px);
            }
            @media screen and (max-height:525px) {
                max-height: calc(100vh - 397px);
            }
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range {
            background-color: var(--yellow);
            border-radius: 50px;
            color: #000;
        }

        .react-datepicker__day:hover {
            border-radius: 50px;
        }

        .react-datepicker__day--in-range {

            &.react-datepicker__day--range-start,
            &.react-datepicker__day--range-end {
                background-color: var(--yellow);
                border-radius: 50px;
                color: #000;

                &:hover {
                    border-radius: 50px;
                }

            }

            &.react-datepicker__day--range-start {
                &::before {
                    height: 100%;
                    margin: 0 auto;
                    left: 20px;
                    width: 11px;
                    @include sm{
                        left: 14px;
                    }
                    @include bp-350{
                        width: 7px;
                        left: 12px;
                    }
                }
            }

            &.react-datepicker__day--range-end {
                &::after {
                    height: 100%;
                    margin: 0 auto;
                    right: 20px;
                    width: 11px;

                    @media (max-width: 350px) {
                        width: 7px;
                    }
                }
            }
        }

        .react-datepicker__day--in-range {
            color: #000;
            border-radius: 0px;
            background: rgba(222, 161, 33, 0.20);
            position: relative;

            &:hover {
                border-radius: 0px;
            }

            &::before {
                left: -10px;
            }

            &::after {
                right: -10px;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0px;
                width: 10px;
                height: 100%;
                background: rgba(222, 161, 33, 0.2);
            }
        }
    }

}

//newDatepickerbackdrop
.newDatepickerBackdrop {
    .page-content {
        overflow: hidden;
    }

    .sub-padder {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.38);
            z-index: 5;
        }
    }
}
// New Datepicker Daily-Passes End
// New Page Loader Start
 .pageReloadModalOpen{
    .popup-backdrop {
        background: rgba(0, 0, 0, 0.8);
    }
 }
 .lv-circles {
    height: 100px;
    width: 100px;
    position: relative;
    &[data-label]:after {
      color: red;
    }   
    div {
        position: absolute;
        width: 100%;
         height: 100%;
      &:before {
        width: 16px;
        height: 16px;
        content: "";
        display: block;
        margin: 0 auto;
        border-radius: 50%;
        background: var(--gradient3);
      }
      &:nth-child(1) {
        animation: lv-circles_move_1  1.2s infinite linear;
      }
      &:nth-child(2) {
        transform: rotate(30deg);
        opacity: 0.08;
        animation: lv-circles_move_2  1.2s infinite linear;
      }
      &:nth-child(3) {
        transform: rotate(60deg);
        opacity: 0.16;
        animation: lv-circles_move_3  1.2s infinite linear;
      }
      &:nth-child(4) {
        transform: rotate(90deg);
        opacity: 0.24;
        animation: lv-circles_move_4  1.2s infinite linear;
      }
      &:nth-child(5) {
        transform: rotate(120deg);
        opacity: 0.32;
        animation: lv-circles_move_5  1.2s infinite linear;
      }
      &:nth-child(6) {
        transform: rotate(150deg);
        opacity: 0.4;
        animation: lv-circles_move_6  1.2s infinite linear;
      }
      &:nth-child(7) {
        transform: rotate(180deg);
        opacity: 0.48;
        animation: lv-circles_move_7  1.2s infinite linear;
      }
      &:nth-child(8) {
        transform: rotate(210deg);
        opacity: 0.56;
        animation: lv-circles_move_8  1.2s infinite linear;
      }
      &:nth-child(9) {
        transform: rotate(240deg);
        opacity: 0.64;
        animation: lv-circles_move_9  1.2s infinite linear;
      }
      &:nth-child(10) {
        transform: rotate(270deg);
        opacity: 0.72;
        animation: lv-circles_move_10  1.2s infinite linear;
      }
      &:nth-child(11) {
        transform: rotate(300deg);
        opacity: 0.8;
        animation: lv-circles_move_11  1.2s infinite linear;
      }
      &:nth-child(12) {
        transform: rotate(330deg);
        opacity: 0.88;
        animation: lv-circles_move_12  1.2s infinite linear;
      }
  
    }
  }
  .engimaPay{
    position: relative;
    .zipCodeEngima{
        &.profilezipCodeEngima{
            width: 44%;
            position: absolute;
            bottom: 124px;
            right: 0;                           
            font-family: sans-serif;
            @include md{
                width: 44%;
                right: 15px;
                bottom: 113px;
            }
            @include sm{
                width: 40%;
            }
            @include xs{
                width: 35%;
            }
            span{
                &.label {
                    position: relative;
                    height: unset;
                    left: 0;
                    font-size: 14px;
                    line-height: 21px;
                    margin: 0 0 5px;
                    padding: 0 8px 0 4px;
                    width: max-content;
                    display: inline-block;
                    &::before{
                        position: absolute;
                        content: "*";
                        right: 0;
                    }
                }
            }
            input{
                font-size: 14px;
                border-radius: 6px;
                padding: 5px 30px 5px 15px;
                height: 38px;
                border: solid 2px var(--gray86);
                transition: 0.5s;
                font-weight: 500;
                width: 100%;
                margin: 0 0 16px;
                background: var(--white);
                &.disable {
                    cursor: not-allowed;
                    background-color: var(--greydisable);
                    color: var(--greyDisablecolor);
                }
                &:focus{                    
                    &.invalid{
                        border-color: var(--engimaErrorborder);
                        color: var(--engimaErrorcolor);
                    }
                }
                @include xs{
                    padding: 5px 9px 5px 15px;
                }
                &::placeholder{
                    color: var(--grey83);
                    font-weight: normal;
                }
            }
        }
    }
  }

  
/* animations */
@keyframes lv-spinner {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lv-circles_move_1 {
    0% {
      opacity: 0;
    }
    0% {
      opacity: 0;
    }
    8.3333333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lv-circles_move_2 {
    0% {
      opacity: 0.0833333333;
    }
    8.3333333333% {
      opacity: 0;
    }
    16.6666666667% {
      opacity: 1;
    }
    100% {
      opacity: 0.0833333333;
    }
  }
  @keyframes lv-circles_move_3 {
    0% {
      opacity: 0.1666666667;
    }
    16.6666666667% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 0.1666666667;
    }
  }
  @keyframes lv-circles_move_4 {
    0% {
      opacity: 0.25;
    }
    25% {
      opacity: 0;
    }
    33.3333333333% {
      opacity: 1;
    }
    100% {
      opacity: 0.25;
    }
  }
  @keyframes lv-circles_move_5 {
    0% {
      opacity: 0.3333333333;
    }
    33.3333333333% {
      opacity: 0;
    }
    41.6666666667% {
      opacity: 1;
    }
    100% {
      opacity: 0.3333333333;
    }
  }
  @keyframes lv-circles_move_6 {
    0% {
      opacity: 0.4166666667;
    }
    41.6666666667% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4166666667;
    }
  }
  @keyframes lv-circles_move_7 {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    58.3333333333% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  @keyframes lv-circles_move_8 {
    0% {
      opacity: 0.5833333333;
    }
    58.3333333333% {
      opacity: 0;
    }
    66.6666666667% {
      opacity: 1;
    }
    100% {
      opacity: 0.5833333333;
    }
  }
  @keyframes lv-circles_move_9 {
    0% {
      opacity: 0.6666666667;
    }
    66.6666666667% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0.6666666667;
    }
  }
  @keyframes lv-circles_move_10 {
    0% {
      opacity: 0.75;
    }
    75% {
      opacity: 0;
    }
    83.3333333333% {
      opacity: 1;
    }
    100% {
      opacity: 0.75;
    }
  }
  @keyframes lv-circles_move_11 {
    0% {
      opacity: 0.8333333333;
    }
    83.3333333333% {
      opacity: 0;
    }
    91.6666666667% {
      opacity: 1;
    }
    100% {
      opacity: 0.8333333333;
    }
  }
  @keyframes lv-circles_move_12 {
    0% {
      opacity: 0.9166666667;
    }
    91.6666666667% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
    100% {
      opacity: 0.9166666667;
    }
  }
  // New Page Loader Start