.walletQrCode { 
    height: 100%;

    .screenCenter { 
        
         @include md {
            padding: 0px 0px;
            width: 100%;
        }
        &.height-100 { 
            min-height: calc(100% - 40px);
            @media only screen and (min-width: 375px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) { 
                width: 100%;
                height: auto !important;
            }
            @include sm { 
                padding: 0px;
            }
            @media only screen and (min-height: 400px) and (max-height: 680px) { 
                height: auto !important;
            }
        }
    }

    --f7-grid-gap: 10px;

    .walletHeader {
        padding: 15px 20px;
        @include sm { 
            padding:13px 15px;
        }
        .linkMain {
            display: inline-flex;
            align-items: center; 
            font-weight: 700;
            font-size: 13px;
            line-height: 22px;
            color: #000000;
            text-transform: uppercase;  
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 32px;
                background-color: #fff;
                padding: 3px 12px;
            &:hover,
            &:focus {
                color: #000000;
            }

            i {
                font-size: 16px;
                margin-left: 3px;
                font-weight: 800; 
            }

        }

        .walletQrInfo { 
            align-items: center;   
            .walletPark {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                background-color: #fff;
                border-radius: 50%;
                padding: 5px; 
                overflow: hidden;
                margin: 0 auto; 
                @include xs {
                    width: 80px;
                    height: 80px;
                    padding: 5px;
                    margin: 0 auto;
                }
               
                img {
                    max-width: 100%;
                    max-height: 100%; 
                }
            }

            .walletName {
                padding-left: 10px;
                @include md {
                    padding-left: 15px;
                }
                @include xs {
                    padding-left: 0px;
                }
                .parkName {
                    width: 100%;
                    margin: 0px;
                    font-size: 21px;
                    line-height: 26px;
                    color: #fff; 
                    font-weight: 700;  
                }

                .parkArea {
                    width: 100%; 
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 400;
                    color: #fff; 
                }
            }
        }
    }

    .walletBody {
        display: flex;
        align-items: center; 
        flex-flow: column; 
        padding: 50px 15px 20px;  
        @include xs {  
            padding: 20px 15px 20px; 
        }
        .passName {
            display: inline-block;
            width: 100%;
            font-weight: 700;
            font-size: 23px;
            line-height: 26px;
            margin-bottom: 10px;
            text-transform: uppercase;  
        }

        .passCode {
            display: inline-block;
            width: 100%;
            font-weight: 400;
            font-size: 24px;
            line-height: 26px;
        }

        .totalcount { 
            padding: 7px 15px;
            border-radius: 15px;
            display: flex;
            .count {
                margin: -15px 0px;
                background: #000000; 
                width: 60px;
                height: 48px;
                display: inline-flex;
                border-radius: 70px;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 24px;
                line-height: 15px;
                color: #fff;
            }
            .smalltext { 
                width: 40%; 

                &:last-child {
                    padding-left: 7px;
                }
            }
        }
        .timecount{
            display: flex;
            align-items: center;
            border-radius: 12px;
            .count {  
                display: inline-flex;
                border-radius: 12px;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 22px;
                line-height: 15px;
                color: #fff;
                padding: 5px 20px;
                margin: -5px;
                height: 42px;
                @include md {    
                }
            }
            .smalltext{
                padding: 5px 15px; 
            }
        }
        .totalcount,
        .timecount{
            background-color: #fff; 
          
            position: relative;
            margin: 20px 0px 25px 0px; 
            .smalltext {
                display: inline-block; 
                font-size: 15px;
                line-height: 18px;
                color: #000;
                font-weight: 500; 
            }

        }


        .date {
            display: inline-block; 
            font-weight: 400;
            font-size: 23px;
            line-height: 26px;
            margin-bottom: 10px;  
        }

        .QrCard {
            width: 270px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            @include xs {  
                width: 250px;
            }
            .header {
                display: inline-block;
                width: 100%;
                padding: 18px 10px; 
                font-weight: 600;
                font-size: 25px;
                line-height: 26px;
                color: #FFFFFF;
            }

            .progressbar {
                height: 10px;
                background: var(--f7-progressbar-bg-color, rgba(var(--f7-theme-color-rgb), .2));
            }
            .progressbar-container{
                background-color:  rgba(0,0,0, .2);
                .progressbar-progress { 
                    background: #000 !important;
              }
            } 

            .QrCardBody {
                padding: 20px 30px;
                height: 255px;
                position: relative;
                @include xs {  
                    padding: 15px;
                    height: 230px;
                }
                img,
                canvas{
                    width: auto !important;
                    height: 100% !important;
                }
                .QrCodeDisbled{
                    border-radius: 10px;
                    position: absolute;
                    top: 25%;
                    width: 93%;
                    height: max-content;
                    background: rgba(0, 0, 0, 1);
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 0;
                    margin: auto;
                    .infoText{
                        font-size: 19px;
                        line-height: 24px;
                        color: #fff;
                        margin: 10px 5px;
                    }
                }
            }
        }
    }

    .startDate {
        display: inline-block;
        background-color: #fff;
        padding: 5px 16px;
        border-radius: 12px; 
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        color: #000; 
        margin-top: 23px;
    }

    .CardQrFooter {
        background-color: #fff;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 25px;
            margin-left: 10px;
            @include md { 
                height: 20px;
            }
        }
        .label { 
            font-weight: 500;
            font-size: 14px;
         
            @include md {  
                font-size: 12px; 
            }
        }
    }
}