.themeFooter{
    background-color: var(--footerbg);
    padding: 20px 50px;     
    @include lg{
        padding:  15px;
    }
    @include md{
        --f7-grid-gap: 0px;
        padding:  12px 20px;
    }
    @include xs{ 
        padding:  10px 10px;
        .row{
            align-items: center;
        }
    }
        .smallText{
            display: flex;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            color: var(--footelinkColor); 
            @include md{
                font-size: 7px;
                line-height: 10px;
            } 
            @media only screen
            and (min-device-width: 350px)
            and (max-device-width: 390px)
            and (orientation: portrait)
            and (-webkit-min-device-pixel-ratio: 2)
            {
                font-size: 7px;
                line-height: 10px;
            }
        } 
        .Footerlink{ 
            display: flex; 
            justify-content: flex-end;
             align-items: center; 
             @include xxs{ 
                display: flex;
                justify-content: flex-end;
                flex-flow: column; 
                align-items: flex-start;
                padding-left: 25px;
            }
            li{
                margin:0px 10px;
                display: flex;
                @include md{ 
                    margin:0px 4px;
                }
                @media only screen
                and (min-device-width: 350px)
                and (max-device-width: 380px)
                and (orientation: portrait)
                and (-webkit-min-device-pixel-ratio: 2)
                {
                    margin:0px 3px;
                }
                a{
                    display: inline-block;
                    width: 100%;
                    font-weight: 600;
                    font-size: 17px;
                    color: var(--footelinkColor);
                    @include md{ 
                        font-size: 8px;
                    }  
                    &:hover,&:focus{
                        text-decoration: underline;
                    }
                } 
            }
        }
}
.themeFooterSticky{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}