  
 :root{
  // Green Colore Shade
  --green:#008157;
  --greenRgba:0, 129, 87;
  --greentone:#80C1A9;
  --greenshade:#005435;
  --gradient1:linear-gradient(180deg, #098E55 0%, #00B768 100%);
  --gradient2:linear-gradient(180deg, #FDB71A -40%, #008157 317.69%);
  --gradient3:linear-gradient(179.25deg, #008157 -252.14%, #FDB71A 158.89%);
  // Yellow Colore Shade
  --yellow:#FDB71A;
  --yellowtone:#FEDB8C;
  --yellowshade:#DEA121;
  --yellowshadeRgba:197, 137, 7;
  --yellowopaceshade:rgb(222 161 33 / 10%);
  --yellowopaceshadetwentypercent:rgb(222 161 33 / 20%);
  --yellow92percentshade: rgb(222 161 33 / 92%);
  --orange:#fb642d;
  // Normal Colore Shade
  --black:#000000;
  --blacktone:#4A4A4A;
  --grey51:#515151;
  --dark23:#231F20;
  --gray:#B2B5B6;
  --graylight:#EEEEEE;
  --danger:#AD0000;
  --white:#fff;
  --gray86: #868686;
  --grayB9:#B9B9B9;
  --grayB6:#868686;
  --darkgrey:#231F20;
  --grey83:#838383;
  --greydisable:#eaeded;
  --greyDisablecolor:#cad2d3;
  // Button 
  --themeBtn:var(--green);
  --themeBtnHover:var(--greenshade);
  --themeBtnHoverRgba:0, 129, 87;
  --themeBtnColor:#fff;
  // Button 
  --themeBtnYellow:#DEA121;
  --themeBtnYellowHover:#DEA121;
  --themeBtnYellowColor:#000;
  // Button 
  --themeBtnGrey:#D9D9D9;
  --themeBtnGreyHover:#cac8c8;
  --themeBtnGreyColor:#000;
  // Header Section
  --headerbg:#fff;
  --headerlogo:auto;
  --headerFont:16px;
  --headerlinkColor:#4A4A4A;
  --headerlinkHoverColor:var(--green);
  --headerBorder:#BCBCBC;
  // BannerSection
  --bannerShadow:linear-gradient(180deg, #DEA121 0%, rgba(0, 129, 87, 0.69) 273.36%);
  --bannerSecShadow:linear-gradient(180deg, #DEA121 0%, rgba(0, 129, 87, 0.69) 273.36%);
  --bannerShadowColor:#fff;
  --bannerOpacity:linear-gradient(180deg, rgba(0, 129, 87, 0.9) 68.29%, rgba(0, 129, 87, 0) 266.26%);
  // Footer Section
  --footerbg:var(--green);
  --footelogo:200px; 
  --footelinkColor:#fff;
  --footelinkHoverColor:var(--green);
  // Green Color Shade
  --themeColor:#008157;
  --min-height:calc(93vh - 107px);
  --min-height-mobile:calc(85.5vh - 0px);
  // Green Color Shade 
  // Loader Color
    --loaderBg:#fff;
    --loaderColor:var(--green);
    --paymentCard:linear-gradient(180deg, #FDB71A -59.09%, #799B3A 140.4%);
    //  New Status Color
    --available:#4E9800;
    --limited:#C56207;
    --soldout:#AD0000;
    --engimaErrorborder:#e9322d;
    --engimaErrorcolor:#b94a48;
    --expirePass: #FF2D00;
    --cancelPass: #c74244;

    // Loader Color
    --Mainloader:linear-gradient(90deg, rgba(253, 183, 26, 1) 0%, rgba(0, 129, 87, 0.40) 100%);
    // gray shade 
    --greyShadeE7:#E7E7E7;
 }
