.fillterLabel {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400; 
    color: var(--black);
    text-transform: uppercase;
    @include md{ 
        font-size: 8px;
        line-height: 12px;
    }
}
.fillter { 
    .swiper-slide,
    li {
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 8px;
        @include md{
            margin-top: 0px;
            margin-bottom: 5px;
            margin-right: 5px;
        }
        @include xs{  
            margin-bottom: 2px;
            margin-right: 4px;
        }
        a {
            border: 3px solid var(--green);
            border-radius: 50px;
            font-weight: 500; 
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--blacktone);
            padding: 8px 15px;
            @include md{ 
                font-size: 9px;
                line-height: 14px;
                padding: 3px 7px;
                border-width: 2px;
            } 
            &:hover,
            &:focus,
            &.active{
                background-color: var(--green);
                color: white;
            }
        }
    }
}